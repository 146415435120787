import { Formik, Form } from 'formik';
import { type FC, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import http from '../../../Services/http/http';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import { toast } from 'react-toastify';
import InputText from '../../../Component/FormControl/InputText';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import { RxCross2 } from 'react-icons/rx';
import ImageUploadInput from '../../../Component/FormControl/ImageUploadInput';
import noImage from '../../../Assets/noimageavailable.jpg';
import FileUpload from '../../../Services/file_upload'
import { DataContext } from '../../../Context/DataProvider';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import PlantsForm from './BasicDetails/PlantsForm';

interface CompanyFormProps {
    setIsLoading?: any
    setCompletedTabs?: any
    completedTabs?: string[]
    setIsActive?: any
    completdTab?: any
    isActive?: any
}
interface Icompany {
    name: string
    code: string
    _id?: any
    logo?: any
    gstCertificate?: any
    nameOfOrganization: string
    gstNumber?: any
    plants: any[]
}

interface editCompany {
    company: {
        name: String
        _id?: string
        code: string
        gstNumber: string
        nameOfOrganization: string
    },
    plants: any[]
}

const CompanyForm: FC<CompanyFormProps> = ({ setIsLoading, setCompletedTabs, setIsActive, completdTab, isActive }) => {
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const [editCompany, setEditCompany] = useState<editCompany | null>(null)
    const companyOnbord = sessionStorage.getItem('companyOnbord');
    const navigate = useNavigate();
    const [uploadedImage, setUploadedImage] = useState<{ _id: string, url: string, mimetype: string } | null>(null)
    const [uploadedFile, setUploadedFile] = useState<{ _id: string, url: string, mimetype: string } | null>(null)
    const [initialValues, setInitialValues] = useState<Icompany>({
        name: '',
        code: '',
        gstNumber: '',
        nameOfOrganization: '',
        plants: [],
    })
    const fetchCompany = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getCompanyForEdit`,
                method: 'get',
                data: { '_id': companyOnbord }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditCompany(response?.data?.data);
                setIsLoading(false)
                setUploadedImage(response?.data?.data?.company?.logo)
                setUploadedFile(response?.data?.data?.company?.gstCertificate)
                setInitialValues({
                    name: response?.data?.data?.company?.name,
                    code: response?.data?.data?.company?.code,
                    gstNumber: response?.data?.data?.company?.gstNumber,
                    nameOfOrganization: response?.data?.data?.company?.nameOfOrganization,
                    plants: response?.data?.data?.plants,
                })
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company.');
            }
        }
    };
    useEffect(() => {
        setEditCompany(null)
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (completdTab?.includes('basic_details')) {
            fetchCompany()
        }
        // eslint-disable-next-line 
    }, []);
    const validationSchema = Yup.object().shape({
        // code: Yup.string().required('Code is required'),
        name: Yup.string().required('Name is required').min(3, 'Too short name').max(50, 'Too long name'),
        nameOfOrganization: Yup.string().required('Name Of Organization is required').min(3, 'Too short Name Of Organization').max(50, 'Too long Name Of Organization'),
        gstNumber: Yup.string().test({
            name: 'conditionalGstNumber',
            message: 'GST number is required',
            test: function (value) {
                const { plants } = this.parent;
                return plants.length === 0 ? !!value : true;
            },
        }).matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/g, 'Invalid GST number'),
        plants: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('plant is required').min(3, 'Too short plant').max(50, 'Too long plant'),
                location: Yup.string().required('location is required').min(3, 'Too short location').max(50, 'Too long location'),
                gstNumber: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number').required('GST number is required'),
                segments: Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required('segment is required').min(3, 'Too short segment').max(50, 'Too long segment'),
                    })
                ),
            })
        ),
    });
    const handleImageUpload = async (file: File) => {
        try {
            let data: any = await FileUpload(file);
            setUploadedImage(data);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error uploading image.');
            }
        }
    };

    const handlePdfUpload = async (file: File, values?: any, plantIndex?: number) => {
        try {
            let data: any = await FileUpload(file);
            if (values && values?.plants?.length > 0 && plantIndex !== undefined) {
                setUploadedFile(null)
                const newPlants = [...values.plants]; // Create a copy of the array
                newPlants[plantIndex].gstCertificate = data; // Change the value of the 3rd index
                setInitialValues({ ...values, plants: newPlants });
            } else {
                setUploadedFile(data);
            }
        } catch (error: any) {
            if (error.message) {
                toast.error(error?.message);
            } else {
                toast.error('Error uploading document.');
            }
        }
    };

    const handleDeleteImage = async () => {
        try {
            const response: responseType = await http({
                url: `/media/deleteMediaPermanent`,
                method: 'delete',
                data: { _id: uploadedImage?._id },
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setUploadedImage(null)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error Deleting image');
            }
        }
    }
    const handleDeleteFile = async (_id: string, values?: any, plantIndex?: number) => {
        try {
            const response: responseType = await http({
                url: `/media/deleteMediaPermanent`,
                method: 'delete',
                data: { _id },
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                if (values && values?.plants?.length > 0 && plantIndex !== undefined) {
                    setUploadedFile(null)
                    const newPlants = [...values.plants];
                    newPlants[plantIndex].gstCertificate = undefined;
                    setInitialValues({ ...values, plants: newPlants });
                } else {
                    setUploadedFile(null);
                }
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error Deleting document');
            }
        }
    }

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        values['logo'] = uploadedImage?._id ?? null;
        if (values.plants.length === 0) {
            values['gstCertificate'] = uploadedFile?._id ?? null;
        } else {
            values['gstCertificate'] = null
            values['gstNumber'] = ''
            values['plants'] = values.plants.map((plant: any) => {
                return { ...plant, gstCertificate: plant.gstCertificate ? plant.gstCertificate._id : null }
            })
        }
        // let gstLastFourDigit = `${values.gstNumber.substring(values.gstNumber.length - 3)}`;
        // let nameLastFourDigit = `${values.name.substring(0, 3)}`;
        values['code'] = values.name
        if (editCompany !== null) {
            values['_id'] = editCompany?.company?._id;
            try {
                const response: responseType = await http({
                    url: `/company/editCompany`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        setCompletedTabs('basic_details')
                        setIsActive('commercial_details')
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error updating company.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/company/addCompany`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        sessionStorage.setItem('companyOnbord', response?.data?.data?._id);
                        setCompletedTabs('basic_details')
                        setIsActive('commercial_details')
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding company.');
                }
            }
        }
    };

    return (
        <div className='w-full relative'>
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}>
                    {({ values }) => (
                        <Form className='flex flex-col gap-4 p-3'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                <InputText name='name' id='name' label='Name of company' />
                                <InputText name='nameOfOrganization' id='nameOfOrganization' label='Name of organization' />
                                <InputText name='code' id='code' disabled={true} label='Code' required={false} />
                                {values?.plants?.length === 0 ?
                                    <InputText name='gstNumber' id='gstNumber' label='GST number' />
                                    :
                                    <p></p>
                                }
                                <PlantsForm values={values} handleDeleteFile={handleDeleteFile} handlePdfUpload={handlePdfUpload} />
                            </div>
                            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-2">
                                {values.plants.length === 0 &&
                                    <div className='w-full relative'>
                                        {uploadedFile != null ?
                                            <div className="w-full flex items-center justify-start gap-5">
                                                <p className='truncate w-40'>{uploadedFile?.url}</p>
                                                <p className={uploadedFile?.url ? 'text-green-500' : 'text-gray-700'}>{uploadedFile?.url ? 'File Uploaded' : 'Uploade File'}</p>
                                                <span onClick={() => handleDeleteFile(uploadedFile._id)} className='w-5 h-5 flex justify-center items-center cursor-pointer rounded-full bg-gray-200 text-gray-700 text-sm border border-black'><RxCross2 /></span>
                                            </div>
                                            :
                                            <ImageUploadInput disabled={uploadedFile != null ? true : false} onImageUpload={(file) => handlePdfUpload(file)} lable='GST Certificate' />
                                        }
                                    </div>
                                }
                                <div className='w-32 h-32 relative'>
                                    {uploadedImage != null ?
                                        <div className="w-32 h-32 rounded-md border border-dotted group">
                                            <span onClick={handleDeleteImage} className='hidden w-5 h-5 group-hover:flex items-center justify-center absolute cursor-pointer -top-2 -right-2 rounded-full bg-gray-200 text-gray-700 text-sm border border-black'><RxCross2 /></span>
                                            <img src={uploadedImage?.url ?? noImage} alt={uploadedImage?.mimetype ?? 'img'} className='w-full h-full' />
                                        </div>
                                        :
                                        <ImageUploadInput disabled={uploadedImage != null ? true : false} onImageUpload={handleImageUpload} lable='logo' />
                                    }
                                </div>
                            </div>
                            <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                                <CancelButton onClick={() => navigate('/home/configuration/company')} label='Cancel' />
                                {buttonLoader ? <ButtonLoader /> : <SubmitButton label='Save & Next' />}
                            </div>
                        </Form>
                    )
                    }
                </Formik >
            </div >
        </div >
    );
}

export default CompanyForm;