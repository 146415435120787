import { useState, type FC, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { DataContext } from '../../../Context/DataProvider';
import { toast } from 'react-toastify';
import { CgDanger } from 'react-icons/cg';
import { GrStatusCriticalSmall } from 'react-icons/gr';
import { IoCheckmarkSharp, IoWarningOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import nontfc from '../../../Assets/no-notifications.png'


interface LogAlertsProps {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const LogAlerts: FC<LogAlertsProps> = ({ setLoading }) => {
    const [logAlerts, setLogAlerts] = useState<any[]>([]);
    const navigate = useNavigate();
    const { notificationUpdate } = useContext(DataContext);
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const fetchLogAlerts = async () => {
        try {
            const response: responseType = await http({
                url: `/notification/getLogAlerts`,
                method: 'get',
                data: { company: userDetails?.company?._id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setLogAlerts(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching log alerts');
            }
        }
    }
    useEffect(() => {
        if (userDetails?.role?.code !== 'superadmin') {
            fetchLogAlerts()
        }
        // eslint-disable-next-line 
    }, [notificationUpdate])
    return (
        <div className='w-full shadow-lg h-[440px] overflow-y-scroll scrollbar-thin flex flex-col bg-white rounded-lg px-2 pb-1 font-sans'>
            <h1 className='w-full self-start text-lg font-medium border-b sticky top-0 bg-white pt-2'>Log alerts</h1>
            {(logAlerts.length > 0) ?
                <>
                    {logAlerts?.map((ntfc: any, index: number) => (
                        <div key={index} onClick={() => navigate(`/home/notificationDetail/${ntfc._id}`)} className="w-full h-auto flex items-start justify-center py-1 cursor-pointer">
                            <div className={`w-full flex items-center ${ntfc.fallen_range === 'r1' ? ' border-red-500' : ntfc.fallen_range === 'r2' ? ' border-orange-600' : ntfc.fallen_range === 'r3' ? 'border-yellow-400' : ntfc.fallen_range === 'r4' && 'border-green-600'} border-l-4 rounded-md border p-1`}>
                                <div className='w-[10%] flex items-center justify-center'>
                                    {ntfc.fallen_range === 'r1' ?
                                        <CgDanger className={`text-red-500 rounded-full text-[35px] p-1`} />
                                        : ntfc.fallen_range === 'r2' ?
                                            <GrStatusCriticalSmall className={`text-orange-600 rounded-full text-[35px] p-1`} />
                                            : ntfc.fallen_range === 'r3' ?
                                                <IoWarningOutline className={`text-yellow-400 rounded-full text-[35px] p-1`} />
                                                : ntfc.fallen_range === 'r4' &&
                                                <IoCheckmarkSharp className={`text-green-600 rounded-full text-[35px] p-1`} />
                                    }
                                </div>
                                <div className='w-[90%] px-1'>
                                    <p className='font-medium text-sm text-gray-900'>{ntfc?.log?.companyAsset?.name}</p>
                                    <p className='text-sm text-gray-700'>{ntfc?.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
                :
                <div className="w-full h-full flex justify-center items-center">
                    <img src={nontfc} alt="no notifications" className='w-[250px]' />
                </div>
            }
        </div>);
}

export default LogAlerts;
