import type { FC } from 'react';
import React, { Fragment, useContext, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { BsFilter } from 'react-icons/bs'
import { FiMoreVertical } from "react-icons/fi"
import { TbStatusChange } from "react-icons/tb"
import classNames from 'classnames';
import { DataContext } from '../../../Context/DataProvider';
import { LuFilterX } from 'react-icons/lu';
import { GrMoreVertical } from 'react-icons/gr';
interface Action {
    id: number;
    title?: string;
    name?: string;
    click?: any;
}
interface ActionPopProps {
    action: Action[]
    icon: 'BsFilter' | 'FiMoreVertical' | 'TbStatusChange' | 'GrMoreVertical';
}


const ActionPop: FC<ActionPopProps> = ({ action, icon }) => {
    const { themes, dateFilter, setDateFilter } = useContext(DataContext);
    const [whereClick, setWhereClick] = useState<any>(10)
    const clearFilters = () => {
        setDateFilter(null)
    }
    const handleClick = (event: any) => {
        const screenHeight = window.innerHeight;
        const clickY = event.clientY;
        const heightClickedPercentage = Math.ceil(((screenHeight - clickY) / screenHeight) * 100);
        setWhereClick(heightClickedPercentage)
    };
    return (
        <>
            <Menu as="div" className={` ${themes} relative inline-block text-left`}>
                <div>
                    <Menu.Button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClick(e)} className={`${icon === 'BsFilter' || icon === 'TbStatusChange' ? 'flex text-xl bg-skin-fill-iconBg border border-skin-color p-2 rounded-full' : ''}`}>
                        {icon === 'BsFilter' ? <BsFilter className='text-skin-base' /> : ''}
                        {icon === 'FiMoreVertical' && <FiMoreVertical className='text-skin-base-action' />}
                        {icon === 'TbStatusChange' && <TbStatusChange className='text-skin-base-action' />}
                        {icon === 'GrMoreVertical' && <GrMoreVertical className='text-skin-base-action-card' />}
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className={`absolute ${whereClick >= 88 ? 'top-0' : '-bottom-2'} right-4 z-50 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                        <div className="py-1">
                            {action?.length > 0 && action?.map(e => (
                                <React.Fragment key={e?.id}>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <>
                                                <p onClick={e?.click} className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'px-4 h-10 text-sm cursor-pointer flex items-center relative',
                                                    e?.title && 'border-b bg-white font-medium')} >
                                                    {e?.name || e?.title}
                                                </p>
                                                <p onClick={clearFilters} className={`${dateFilter !== null && e?.title === 'Filter By' ? 'block cursor-pointer' : 'hidden'} absolute right-1 top-2 p-1 flex items-center gap-1 text-sm text-gray-800`}>Clear<LuFilterX /></p>
                                            </>
                                        )}
                                    </Menu.Item>
                                </React.Fragment>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </>
    )
}

export default ActionPop;
