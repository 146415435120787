import { FieldArray } from 'formik';
import type { FC } from 'react';
import InputText from '../../../../Component/FormControl/InputText';
import { RiDeleteBin4Line } from 'react-icons/ri';
import { FaPlusCircle } from 'react-icons/fa';

interface SegmentsFormProps {
    plantIndex: number
    plant: any
}

const SegmentsForm: FC<SegmentsFormProps> = ({ plantIndex, plant }) => {
    return (
        <FieldArray FieldArray name={`plants.${plantIndex}.segments`}>
            {({ push: pushSegment, remove: removeSegment }: { remove: (index: number) => void, push: (value: any) => void, form: any }) => (
                <>
                    {plant.segments.length > 0 ? plant.segments.map((segment: any, segmentIndex: number) => (
                        <div key={segmentIndex} className='w-full flex items-center gap-8'>
                            <div className="relative group mb-2 w-[80%]">
                                <div className='relative w-full ml-4'>
                                    <InputText name={`plants.${plantIndex}.segments.${segmentIndex}.name`} id={`plants.${plantIndex}.segments.${segmentIndex}.name`} label={`Segment ${segmentIndex + 1}`} autoFocus={true} />
                                </div>
                                <div onClick={() => removeSegment(segmentIndex)} className='absolute right-5 top-2.5 group-hover:block text-xl hidden text-red-600 cursor-pointer'><RiDeleteBin4Line />
                                </div>
                            </div>
                            {segmentIndex === plant.segments.length - 1 && (
                                <div title='Add Segment' onClick={() => pushSegment({ name: '' })} className={`text-blue-600 text-2xl cursor-pointer`}><FaPlusCircle /></div>
                            )}
                        </div>
                    ))
                        :
                        <p onClick={() => pushSegment({ name: '' })} className='text-white text-sm flex flex-col justify-center items-center w-28 py-2 cursor-pointer border rounded-md bg-skin-button-bgcolor'>Add Segment</p>
                    }
                </>
            )}
        </FieldArray>
    );
}

export default SegmentsForm;
