import { useState, type FC, Dispatch, SetStateAction, useEffect } from 'react';
import { RiArrowRightDownLine, RiArrowRightUpLine } from 'react-icons/ri';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';

interface AssetsCountProps {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const AssetsCount: FC<AssetsCountProps> = ({ setLoading }) => {
    const [assetsCount, setAssetsCount] = useState<any>()
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')

    const fetchAssetsCount = async () => {
        try {
            const response: responseType = await http({
                url: `/asset/getAssetCount`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAssetsCount(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset count');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code === 'superadmin') {
            fetchAssetsCount();
        }
        // eslint-disable-next-line 
    }, [])

    return (
        <div className="w-full h-auto cursor-default bg-white text-gray-700 hover:bg-blue-950 hover:text-white flex flex-col justify-between rounded-xl p-2 px-3 shadow-lg">
            <p className="text-2xl font-semibold">Master assets</p>
            <div className="pt-2 pb-2.5 flex items-baseline gap-3">
                <div className="text-6xl font-bold flex items-center">
                    {assetsCount?.count}
                </div>
                <div className={`${assetsCount?.percentageChange < 0 ? 'text-red-500' : assetsCount?.percentageChange >= 0 && 'text-green-500'} flex items-center`}>
                    {assetsCount?.percentageChange !== null && assetsCount?.percentageChange < 0 ? assetsCount?.percentageChange?.toString()?.slice(1) : assetsCount?.percentageChange >= 0 ? assetsCount?.percentageChange : '0'}%
                    {assetsCount?.percentageChange !== null && assetsCount?.percentageChange < 0 ? <RiArrowRightDownLine className="text-[20px]" /> : <RiArrowRightUpLine className="text-[20px]" />}
                </div>
            </div>
        </div>
    );
}

export default AssetsCount;
