import { useState, useEffect, useContext, } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router'
import LeftNav from '../Component/Common/LeftNav/LeftNav';
import BottomNav from '../Component/Common/BottomNav/BottomNav';
import io from 'socket.io-client';
import { DataContext } from '../Context/DataProvider';
import { useTranslation } from 'react-i18next';
import NotificationPopUp from '../Pages/Notification/NotificationPopUp';
const Home = () => {
  const { setSocket, themes, notificationUpdate, setNotificationUpdate } = useContext(DataContext);
  const token: string | null = sessionStorage.getItem('token');
  const { language, setLanguage, setThemes } = useContext(DataContext);
  const { pathname } = useLocation();
  const [sidebarWidth, setsidebarWidth] = useState(false);
  const Navigate = useNavigate();
  let login = sessionStorage.getItem('token');
  const { i18n } = useTranslation('title');
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<any>({})

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API_BASE_URL}`, {
      extraHeaders: {
        token: token || ''
      }
    });
    socket.on('notification', (data: any) => {
      // console.log("notification", data);
      setNotificationData(data)
      setShowNotification(true)
      setNotificationUpdate(!notificationUpdate);
      setTimeout(() => {
        setShowNotification(false)
      }, 5000);
    })
    socket.on('call_notification', (data: any) => {
      console.log("call_notification", data);
      setNotificationUpdate(!notificationUpdate);
    })
    setSocket(socket);
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '{}');
    if (userDetails) {
      setLanguage(userDetails?.language)
      setThemes(userDetails?.theme)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const changeLanguage = () => {
      i18n.changeLanguage(language);
    };
    changeLanguage();
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (!login) {
      Navigate('/')
    } else if (pathname === '/home') {
      Navigate('/home/dashboard')
    }
    // eslint-disable-next-line
  }, [login, pathname]);

  return (
    <div className={`${themes} w-screen h-screen bg-skin-fill-outlet relative`}>
      <LeftNav {...{ sidebarWidth, setsidebarWidth }} />
      <div className={`${sidebarWidth === true ? 'sm:ml-14 ' : 'sm:ml-[240px]'}  pb-16 sm:pb-0 h-full transition-all duration-1000 px-3 sm:pt-20 pt-[70px] shadow-inner relative`}>
        <Outlet />
      </div>
      <div className="w-full z-50 fixed bottom-0 block  sm:hidden">
        <BottomNav />
      </div>
      {showNotification && <NotificationPopUp data={notificationData} open={notificationData} setOpen={setShowNotification} />}
    </div>
  )
}

export default Home;
