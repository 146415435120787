import { useState, type FC, useEffect, useContext } from 'react';
import Loader from '../../Component/Common/Loader/Loader';
import { useParams } from 'react-router-dom';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import { DataContext } from '../../Context/DataProvider';
import DataNotFoundPage from '../../Component/Common/DataNotFoundPage';

interface MonitoringLogProps { }

const MonitoringLog: FC<MonitoringLogProps> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [logData, setLogData] = useState<any[] | null>(null)
    const { isRender, dateFilter } = useContext(DataContext);
    const { companyAsset } = useParams();

    const fetchLogs = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringLog/getMonitoringLogs`,
                method: 'get',
                data: { companyAsset: companyAsset, dateFilter }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setLogData(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching logs.');
            }
        }
    };

    useEffect(() => {
        fetchLogs();
        // eslint-disable-next-line 
    }, [isRender, dateFilter])

    return (
        <div className='h-full w-full relative overflow-auto'>
            {logData?.length === 0
                ?
                <DataNotFoundPage />
                :
                <>
                    <Loader loader={isLoading} />
                    <div className="flex flex-col w-full h-full pb-3">
                        <div className="w-full h-full">
                            <table className="w-full mb-2 text-left">
                                <thead className="  border-l-white px-6 bg-skin-fill-table-head text-skin-base-table-head-text z-10 sticky top-0">
                                    <tr>
                                        <th scope="col" className="px-6 py-4 font-medium truncate">
                                            S.No.
                                        </th>
                                        <th scope="col" className="sticky left-0 bg-skin-fill-table-head font-medium truncate">
                                            Asset
                                        </th>
                                        <th scope="col" className="bg-skin-fill-table-head font-medium truncate">
                                            Log Type
                                        </th>
                                        <th scope="col" className="px-4 py-4 font-medium truncate">
                                            Reading
                                        </th>
                                        <th scope="col" className="px-4 py-4 font-medium truncate">
                                            Unit
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logData?.map((log: any, index: number) => (
                                        <tr key={index} className="group bg-skin-fill-table text-skin-base-table-body-text   hover:bg-opacity-10 hover:bg-skin-fill-hover hover:text-skin-base-table-hover border-l-4 border-transparent hover:border-l-skin-table-color group">
                                            <td className="py-[14px] px-6 truncate">
                                                {index + 1}
                                            </td>
                                            <td className={`items-center sticky  left-0 truncate`}>
                                                {log?.companyAsset?.name}
                                            </td>
                                            <td className="px-4 py-[14px] truncate">
                                                {log?.monitoringType?.name}
                                            </td>
                                            <td className="px-4 py-[14px] truncate">
                                                {log?.count}
                                            </td>
                                            <td className="px-4 py-[14px] truncate">
                                                {log?.monitoringType?.unit?.name}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default MonitoringLog;
