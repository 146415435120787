import type { FC } from 'react';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { CgProfile } from 'react-icons/cg';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { IoMdLogOut } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import noImage from '../../../Assets/noimageavailable.jpg'


interface BottomNavPopUpProps { }

const BottomNavPopUp: FC<BottomNavPopUpProps> = () => {
    const navigate = useNavigate()
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    const { t } = useTranslation('title');

    const handleLogout = async () => {
        try {
            toast.success('User Logged Out')
            setTimeout(() => {
                sessionStorage.clear();
                navigate('/');
            }, 2000);
        } catch (error: any | unknown) {
            toast.error((error as any)?.response?.data?.message);
        }
    }
    return (
        <Menu as="div" className=" inline-block text-skin-base text-left">
            <div>
                <Menu.Button className={`font-medium rounded-sm px-1`}>
                    <div className="relative">
                        {userDetails?.profileImage === null
                            ?
                            <div className={`cursor-pointer w-9 h-9 min-w-[36px] min-h-[36px] rounded-full font-semibold text-xl flex items-center justify-center bg-gray-200 border text-[#6D6DA4]`}>{userDetails?.name.charAt(0).toUpperCase()}</div>
                            :
                            <img src={userDetails?.profileImage?.url ?? noImage} alt="mImg" className={`cursor-pointer w-9 h-9 min-w-[36px] min-h-[36px] rounded-full`} />}
                    </div>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute bottom-14 w-[250px] py-2 px-2 z-10 mt-2 right-6 rounded-md cursor-default bg-skin-fill-popup shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className={` w-full transition-all duration-1000 gap-2 flex flex-col items-center`}>
                        <Menu.Button onClick={() => navigate('editProfile')} className='w-full h-8 cursor-pointer bg-skin-fill-profile drop-shadow-md border flex items-center pl-2 justify-between rounded-sm'>
                            <div className="flex items-center gap-1 text-sm">
                                <CgProfile />
                                <span>{t('Edit Profile')}</span>
                            </div>
                            <RiArrowRightSLine className='text-[20px] cursor-pointer' />
                        </Menu.Button>
                        <Menu.Button onClick={handleLogout} className='w-full h-8 cursor-pointer bg-skin-fill-profile drop-shadow-md border flex items-center px-2 rounded-sm'>
                            <div className="flex items-center gap-1 text-sm">
                                <IoMdLogOut />
                                {t('Log Out')}
                            </div>
                        </Menu.Button>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

export default BottomNavPopUp;