import React, { useEffect, useRef, useState } from 'react';
import go from 'gojs';
import { responseType } from '../TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';

interface GoJsDiagramProps { }

const GojsDiagram: React.FC<GoJsDiagramProps> = () => {
    const [hierarchyData, setHierarchyData] = useState<any>(null)
    const diagramDivRef = useRef<HTMLDivElement | null>(null);
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const fetchhierarchyData = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getHierarchyTree`,
                method: 'get',
                data: { company: userDetails?.company?._id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setHierarchyData(response?.data?.data);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error editing Company Asset');
            }
        }
    }
    useEffect(() => {
        let myDiagram: any | null = null;
        function init() {
            const $ = go.GraphObject.make;
            if (diagramDivRef.current) {
                myDiagram = $(go.Diagram, diagramDivRef.current, {
                    allowCopy: false,
                    allowDelete: false,
                    layout:
                        $(go.TreeLayout,
                            {
                                treeStyle: go.TreeLayout.StyleLastParents,
                                arrangement: go.TreeLayout.ArrangementHorizontal,
                                angle: 90,
                                layerSpacing: 35,
                                alternateAngle: 90,
                                alternateLayerSpacing: 35,
                                alternateAlignment: go.TreeLayout.AlignmentBus,
                                alternateNodeSpacing: 20
                            }),
                    "undoManager.isEnabled": true
                });
            }
            myDiagram.nodeTemplate = $(
                go.Node,
                "Auto",
                { name: "BODY" },
                {
                    selectionObjectName: "BODY",
                    mouseEnter: (e: any, node: any) => {
                        const buttonX = node.findObject("BUTTONX");
                        if (buttonX) {
                            buttonX.opacity = 1;
                        }
                    },
                    mouseLeave: (e: any, node: any) => {
                        const buttonX = node.findObject("BUTTONX");
                        if (buttonX) {
                            buttonX.opacity = 0;
                        }
                    },
                },
                $(go.Shape,
                    {
                        figure: "RoundedRectangle",
                        fill: "#141133",
                        stroke: "#2196F3",
                        strokeWidth: 3,
                    }),
                $(go.Panel, "Vertical",
                    { margin: 10 },
                    $(go.TextBlock, new go.Binding("text", "name"), { font: "12pt Segoe UI,sans-serif", stroke: "white" }),
                    $(go.TextBlock, new go.Binding("text", "count"), { font: "10pt Segoe UI,sans-serif", stroke: "white" })
                ),
                new go.Binding("isTreeExpanded").makeTwoWay(),
                $("TreeExpanderButton",
                    {
                        name: "BUTTONX", alignment: go.Spot.Bottom, opacity: 0,
                        "_treeExpandedFigure": "TriangleUp",
                        "_treeCollapsedFigure": "TriangleDown"
                    },
                ),
            );
            myDiagram.linkTemplate = $(
                go.Link,
                { routing: go.Link.Orthogonal, corner: 7 },
                $(go.Shape, { strokeWidth: 2, stroke: "blue" }),
                $(go.Shape, { toArrow: "Standard", stroke: "blue" })
            );
            if (hierarchyData) {
                myDiagram.model = new go.TreeModel(hierarchyData);
            }
        }
        init();

        return () => {
            // Cleanup on unmount
            if (myDiagram) {
                myDiagram.div = null;
                myDiagram = null;
            }
        };
    }, [hierarchyData]);
    useEffect(() => {
        if (userDetails.role.code !== 'superadmin') {
            fetchhierarchyData()
        }
        // eslint-disable-next-line
    }, [])

    return <div ref={diagramDivRef} style={{ width: '100%', height: '100%', backgroundColor: "white" }} />;
};

export default GojsDiagram;
