import { useEffect, type FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { Link } from 'react-router-dom';
import logo from '../../../Assets/logoText.jpeg';
import moment from 'moment';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { IoIosArrowForward } from 'react-icons/io';

interface OnbordingDetailsProps { }

const OnbordingDetails: FC<OnbordingDetailsProps> = () => {
    const { Id } = useParams()
    const [plant, setPlant] = useState<any>(null)
    const [segment, setSegment] = useState<any>(null)
    const [basicDetails, setBasicDetails] = useState<any>(null)
    const [billingAddress, setBillingAddress] = useState<any>(null)
    const [commercialDetails, setCommercialDetails] = useState<any>(null)
    const [rangeOpen, setRangeOpen] = useState<number[]>([0]);

    const fetchPlant = async () => {
        try {
            const response: responseType = await http({
                url: `/plant/getPlantForEdit`,
                method: 'get',
                data: { 'company_id': Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setPlant(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'Error fetching plant.');
        }
    };

    const fetchSegment = async () => {
        try {
            const response: responseType = await http({
                url: `/segment/getSegmentForEdit`,
                method: 'get',
                data: { 'company_id': Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setSegment(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'Error fetching segment.');
        }
    };

    const fetchBasicDetails = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getCompanyForEdit`,
                method: 'get',
                data: { _id: Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setBasicDetails(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'Error fetching basic details.');
        }
    };

    const fetchBillingAddress = async () => {
        try {
            const response: responseType = await http({
                url: `/billingAddress/getBillingAddressForEdit`,
                method: 'get',
                data: { company_id: Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setBillingAddress(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'Error fetching billing address.');
        }
    };
    const fetchCommercialDetails = async () => {
        try {
            const response: responseType = await http({
                url: `/commercialDetails/getCommercialDetailsForEdit`,
                method: 'get',
                data: { company_id: Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCommercialDetails(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'Error fetching commercial details.');
        }
    };

    const handleOpen = (index: number) => {
        if (rangeOpen.includes(index)) {
            setRangeOpen([])
        } else {
            setRangeOpen([index])
        }
    }

    useEffect(() => {
        fetchPlant();
        fetchSegment();
        fetchBasicDetails();
        fetchBillingAddress();
        fetchCommercialDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='w-full h-full grid lg:grid-cols-2 grid-cols-1 gap-3 pb-2.5 overflow-y-auto'>
            <div className='border min-h-64 max-h-auto rounded-md px-2 py-1 shadow-md relative'>
                <h4 className='text-blue-800 font-medium text-xl '>Basic Details</h4>
                <div className='absolute top-5 right-2 w-20 h-20 rounded'>
                    <img src={basicDetails?.logo ? basicDetails?.logo?.url : logo} className='w-full h-full rounded-full' alt="" />
                </div>
                {basicDetails && (
                    <div className='pl-3 flex flex-col gap-3 mt-3'>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Name of Company:-</p>
                            <p className='text-gray-600 font-medium'>{basicDetails?.company?.name}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Name of Organization:-</p>
                            <p className='text-gray-600 font-medium'>{basicDetails?.company?.nameOfOrganization}</p>
                        </div>
                        {basicDetails && basicDetails?.company?.gstNumber && (
                            <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                                <p className='text-gray-800 font-medium'>GST Number:-</p>
                                <p className='text-gray-600 font-medium'>{basicDetails?.company?.gstNumber}</p>
                            </div>
                        )}
                        {basicDetails && basicDetails?.company?.gstCertificate && (
                            <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                                <p className='text-gray-800 font-medium'>GST Certificate:-</p>
                                <p >{basicDetails?.company?.gstCertificate ? <a className='cursor-pointer font-medium text-blue-500' href={basicDetails?.company?.gstCertificate?.url}>Click hear</a> : <span>Not Avalable</span>}</p>
                            </div>
                        )}
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Code:- </p>
                            <p className='text-gray-600 font-medium'>{basicDetails?.company?.code}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className='border min-h-64 max-h-auto rounded-md px-2 py-1 shadow-md'>
                <h4 className='text-blue-800 font-medium text-xl '>Billing Address</h4>
                {billingAddress && (
                    <div className='pl-3 flex flex-col gap-3 mt-3'>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Country:-</p>
                            <p className='text-gray-600 font-medium'>{billingAddress?.country?.name}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>State:-</p>
                            <p className='text-gray-600 font-medium'>{billingAddress?.state?.name}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Attention:-</p>
                            <p className='text-gray-600 font-medium'>{billingAddress?.attention}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Address Line:-</p>
                            <p className='text-gray-600 font-medium'>{billingAddress?.addressLine}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>City:- </p>
                            <p className='text-gray-600 font-medium'>{billingAddress?.city}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Zip Code:- </p>
                            <p className='text-gray-600 font-medium'>{billingAddress?.zipCode}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className='border min-h-64 max-h-auto rounded-md px-2 py-1 shadow-md'>
                <h4 className='text-blue-800 font-medium text-xl '>Commercial Details</h4>
                {commercialDetails && (
                    <div className='pl-3 flex flex-col gap-3 mt-3'>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Company URL:-</p>
                            {
                                commercialDetails?.companyUrl === 'Not provided' ?
                                    <p>Not provided</p>
                                    :
                                    <Link className='cursor-pointer text-blue-500 truncate' to={commercialDetails?.companyUrl} target="_blank">{commercialDetails?.companyUrl}</Link>
                            }
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>PAN:-</p>
                            <p className='text-gray-600 font-medium'>{commercialDetails?.pan}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Tax Preference:-</p>
                            <p className='text-gray-600 font-medium'>{commercialDetails?.taxPreference}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Currency:-</p>
                            <p className='text-gray-600 font-medium'>{commercialDetails?.currency}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Contract Period:- </p>
                            <p className='text-gray-600 font-medium'>{commercialDetails?.contractPeriod}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Contract Start Date:- </p>
                            <p className='text-gray-600 font-medium'>{moment(commercialDetails?.contractStartDate).format('DD-MM-YYYY')}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Contract Renew Date:- </p>
                            <p className='text-gray-600 font-medium'>{moment(commercialDetails?.contractRenewDate).format('DD-MM-YYYY')}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Payment Term:- </p>
                            <p className='text-gray-600 font-medium'>{commercialDetails?.paymentTerms}</p>
                        </div>
                        <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                            <p className='text-gray-800 font-medium'>Number of Asset Onbording:- </p>
                            <p className='text-gray-600 font-medium'>{commercialDetails?.numberOfAssetsOnboarded}</p>
                        </div>
                        {commercialDetails && commercialDetails.contractCopy && (
                            <div className='flex flex-row gap-3 border-b-2 pb-2.5'>
                                <p className='text-gray-800 font-medium'>Contract Copy:- </p>
                                <p>{commercialDetails?.contractCopy ? <a className='cursor-pointer font-medium text-blue-500' href={commercialDetails?.contractCopy.url}>Click here</a> : <span>Not Avalable</span>}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className='border min-h-64 max-h-auto rounded-md px-2 py-1 shadow-md'>
                <h4 className='text-blue-800 font-medium text-xl '>Plants & Segments</h4>
                {plant?.length > 0 ?
                    <div className='pl-3 flex flex-col gap-3 mt-3'>
                        <div className='w-full h-auto overflow-auto flex flex-col gap-2 scrollbar-thin mb-2'>
                            {plant?.map((plant: any, index: number) => (
                                <div className='group' key={index}>
                                    <Accordion open={rangeOpen.includes(index)} key={index}>
                                        <AccordionHeader onClick={() => handleOpen(index)} className='flex w-full justify-start py-2 bg-skin-fill-company-table-bg text-skin-company-mainText font-medium'>
                                            <div className='flex justify-start items-center w-[88%] sm:w-[90%]'>
                                                <IoIosArrowForward className={`transition-all duration-200 ${rangeOpen.includes(index) ? 'rotate-90' : 'rotate-0'}`} />
                                                <div className='flex items-center justify-between w-full'>
                                                    <div className='text-sm ps-1 flex gap-3 items-center'>
                                                        {/* <p>Plant Name</p> */}
                                                        <p title='Plant Name'>{plant?.name}</p>
                                                    </div>
                                                    <div className='text-sm flex gap-3 items-center'>
                                                        {/* <p>GST Number</p> */}
                                                        <p title='GST Number'>{plant?.gstNumber}</p>
                                                    </div>
                                                    <div className='text-sm flex gap-3 items-center'>
                                                        {/* <p>GST Certificate </p> */}
                                                        <p title='GST Certificate'>
                                                            {plant?.gstCertificate ? <a className='cursor-pointer font-medium text-blue-500' href={plant?.gstCertificate?.url}>Click here</a> : <span>Not Avalable</span>}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionHeader>
                                        <AccordionBody className='py-1'>
                                            <div className='w-full py-2 bg-skin-fill-company-table-bg h-auto px-4 shadow-sm'>
                                                {segment && segment.find((isSegment: any) => isSegment?.plant === plant._id) ? (
                                                    segment
                                                        .filter((segmentItem: any) => segmentItem.plant === plant._id)
                                                        .map((filteredSegment: any, segmentIndex: number) => (
                                                            <p className='mb-2 ' key={segmentIndex}>{filteredSegment.name}</p>
                                                        ))
                                                ) : (
                                                    <p className='text-gray-600 font-medium'>No Segment Available</p>
                                                )}
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    <div className='w-full h-full flex flex-col justify-center items-center'>
                        <p className='text-gray-600 font-medium p-2'>No Plant Available</p>
                    </div>
                }
            </div>
        </div>
    );
}

export default OnbordingDetails;
