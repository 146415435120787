import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';
import { Fragment, useEffect, type FC } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import InputText from '../../Component/FormControl/InputText';
import InputSelect from '../../Component/FormControl/InputSelect';
import InputNumber from '../../Component/FormControl/InputNumber';

interface CompanyAssetMonitoringFormProps {
    selectedMonitoring: any[]
    assetIndex: number
    setFieldValue: Function
    rangeOpen: any
    handleOpen: any
    units: any
}

const CompanyAssetMonitoringForm: FC<CompanyAssetMonitoringFormProps> = ({ selectedMonitoring, assetIndex, setFieldValue, handleOpen, rangeOpen, units }) => {
    useEffect(() => {
        selectedMonitoring[assetIndex]?.forEach((item: any, index: number) => {
            setFieldValue(`companyAssets.${assetIndex}.monitoring.${index}.monitoringType`, item._id);
        });
    }, [selectedMonitoring, assetIndex, setFieldValue]);
    return (
        <>
            {selectedMonitoring[assetIndex]?.map((item: any, index: number) => (
                <Fragment key={index}>
                    <Accordion open={rangeOpen.includes(index)}>
                        <AccordionHeader onClick={() => handleOpen(index)} className='h-6 text-lg flex w-full justify-start py-0'>
                            <IoIosArrowForward className={`transition-all duration-200 ${rangeOpen.includes(index) ? 'rotate-90' : 'rotate-0'}`} />
                            {item?.name}
                        </AccordionHeader>
                        <AccordionBody className='py-1'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                <InputText
                                    name={`companyAssets.${assetIndex}.monitoring.${index}.monitoringType`}
                                    id={`companyAssets.${assetIndex}.monitoring.${index}.monitoringType`}
                                    label='Monitoring Type'
                                    hidden={true} />
                                <InputSelect
                                    name={`companyAssets.${assetIndex}.monitoring.${index}.unit`}
                                    id={`companyAssets.${assetIndex}.monitoring.${index}.unit`}
                                    label='Unit'
                                    Array={units?.filter((unit: any) => (unit.type === item.type))} />
                                <InputNumber
                                    name={`companyAssets.${assetIndex}.monitoring.${index}.ranges.minimum`}
                                    id={`companyAssets.${assetIndex}.monitoring.${index}.ranges.minimum`}
                                    label='Min'
                                />
                                <InputNumber
                                    name={`companyAssets.${assetIndex}.monitoring.${index}.ranges.maximum`}
                                    id={`companyAssets.${assetIndex}.monitoring.${index}.ranges.maximum`}
                                    label='Max' />
                                <InputNumber
                                    name={`companyAssets.${assetIndex}.monitoring.${index}.ranges.alarm`}
                                    id={`compmonitoringTypeanyAssets.${assetIndex}.monitoring.${index}.ranges.alarm`}
                                    label='Alarm' />
                                <InputNumber
                                    name={`companyAssets.${assetIndex}.monitoring.${index}.ranges.tripping`}
                                    id={`companyAssets.${assetIndex}.monitoring.${index}.ranges.tripping`}
                                    label='Tripping' />
                            </div>
                        </AccordionBody>
                    </Accordion>
                </Fragment>
            ))}
        </>
    );
}

export default CompanyAssetMonitoringForm;
