import type { FC } from 'react';
import React, { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { FiMoreVertical } from "react-icons/fi"
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { IoMdCheckmark } from 'react-icons/io';
import { GoReport } from 'react-icons/go'
import { DataContext } from '../../../Context/DataProvider';
interface Action {
    id: number;
    title?: string;
    name?: string;
    click?: any;
    icon:string
}
interface NotificationActionPopProps {
    action: Action[]
    icon: 'BsFilter' | 'FiMoreVertical' | 'TbStatusChange';
}

const NotificationActionPop: FC<NotificationActionPopProps> = ({ action, icon }) => {
    const {themes}=useContext(DataContext)
    return (
        <Menu as="div" className={`relative ${themes} inline-block text-left`}>
            <div>
                <Menu.Button className={`${icon === 'BsFilter' || icon === 'TbStatusChange' ? 'flex text-xl bg-white dark:text-white border focus:border-[#959CFA] focus:text-[#959CFA] focus:ring-[#959CFA] border-black p-2 rounded-full focus:ring-2 dark:focus:ring-gray-600' : ''}`}>
                    {icon === 'FiMoreVertical' && <FiMoreVertical className=' text-skin-base'/>}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none truncate">
                    <div className="py-1">
                        {action?.length > 0 && action?.map(e => (
                            <React.Fragment key={e?.id}>
                                <Menu.Item>
                                    {({ active }) => (
                                        <div className="flex items-center px-2 truncate w-full hover:bg-gray-100">
                                            <span>{e?.icon === 'IoMdCheckmark' ? <IoMdCheckmark/> :e?.icon === 'MdClose' ? <MdClose/> :e?.icon === 'GoReport' ? <GoReport/> : ''}</span>
                                            <p onClick={e?.click} className={classNames(
                                                active ? 'bg-gray-100 text-gray-900 truncate' : 'text-gray-700 truncate',
                                                'px-4 h-10 text-sm cursor-pointer flex items-center relative',
                                                e?.title && 'border-b bg-white font-medium ')} >
                                                {e?.name || e?.title}
                                            </p>
                                        </div>
                                    )}
                                </Menu.Item>
                            </React.Fragment>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

export default NotificationActionPop;
