import { useState, type FC, useEffect } from 'react';
import CompanyForm from './CompanyForm';
import BillingAddressForm from '../BillingAddress/BillingAddressForm';
import CommercialDetailsForm from '../CommercialDetails/CommercialDetailsForm';
import Loader from '../../../Component/Common/Loader/Loader';
import { useLocation } from 'react-router-dom';
import CompanyAdminForm from '../CompanyAdmin/CompanyAdminForm';
import CompanyAssetFormBulkCreate from '../../CompanyAsset/CompanyAssetFormBulkCreate';

interface OnbordingProps { }

interface tabsType {
    label: string
    value: string
}

const Onbording: FC<OnbordingProps> = () => {
    const { pathname } = useLocation()
    const exactPath = pathname.split('/')[3]
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isActive, setIsActive] = useState<string>(sessionStorage.getItem('activeTab') ?? 'basic_details')
    const completdTab = JSON.parse(sessionStorage.getItem('completdTab') || '[]');
    const [tabs, setTabs] = useState<tabsType[]>([{ label: 'Basic Details', value: 'basic_details' }, { label: 'Commercial Details', value: 'commercial_details' }, { label: 'Billing Address', value: 'billing_address' }]);

    const handleLoader = (data: boolean) => {
        setIsLoading(data)
    }
    const handleResponse = (data: string) => {
        sessionStorage.setItem('completdTab', JSON.stringify([...completdTab, data]));
    }
    const handleActive = (data: string) => {
        setIsLoading(true)
        sessionStorage.setItem('activeTab', data)
        setIsActive(data)
    }
    const handleClick = (tab: string) => {
        // if (exactPath === 'editCompany') {
        setIsActive(tab)
        setIsLoading(true)
        // }
    }
    useEffect(() => {
        let newTabe = [...tabs]
        if (exactPath !== 'editCompany' && !tabs.includes({ label: 'User', value: 'user' })) {
            newTabe.splice(3, 0, { label: 'User', value: 'user' })
            setTabs(newTabe);
        }
        if (exactPath !== 'editCompany' && !tabs.includes({ label: 'Asset', value: 'asset' })) {
            newTabe.splice(4, 0, { label: 'Asset', value: 'asset' })
            setTabs(newTabe);
        }
        // eslint-disable-next-line
    }, [pathname])

    return (
        <div className='w-full h-full'>
            <Loader loader={isLoading} />
            <div className="w-full overflow-x-scroll scrollbar-none flex items-center px-0 sm:px-2 sticky top-0 z-30 mb-8 bg-skin-fill-outlet">
                {tabs?.map((tab: tabsType, index: number) =>
                    <div onClick={() => handleClick(tab.value)} key={index} className={`${completdTab?.includes(tab?.value) && isActive?.includes(tab.value) ? 'text-white bg-blue-800' : completdTab?.includes(tab?.value) ? 'text-white bg-green-600' : isActive?.includes(tab.value) ? ' bg-blue-800 text-white' : 'bg-gray-300'} min-w-[200px] cursor-default truncate flex items-center justify-center py-1 -mx-1 font-medium sm:text-lg text-sm stepper-arrow`}>
                        <p title={tab.label} className={`px-10 truncate`}>{tab.label}</p>
                    </div>
                )}
            </div>
            {
                isActive === 'basic_details' &&
                <CompanyForm setIsLoading={handleLoader} setCompletedTabs={handleResponse} setIsActive={handleActive} completdTab={completdTab} />
            }
            {
                isActive === 'billing_address' &&
                <BillingAddressForm setIsLoading={handleLoader} setCompletedTabs={handleResponse} setIsActive={handleActive} completdTab={completdTab} />
            }
            {
                isActive === 'commercial_details' &&
                <CommercialDetailsForm setIsLoading={handleLoader} setCompletedTabs={handleResponse} setIsActive={handleActive} completdTab={completdTab} />
            }
            {
                exactPath === 'addcompany' && isActive === 'user' &&
                <CompanyAdminForm setIsLoading={handleLoader} setCompletedTabs={handleResponse} setIsActive={handleActive} completdTab={completdTab} />
            }
            {
                isActive === 'asset' &&
                <CompanyAssetFormBulkCreate setIsLoading={handleLoader} setCompletedTabs={handleResponse} setIsActive={handleActive} completdTab={completdTab} />
            }
        </div>
    );
}

export default Onbording;
