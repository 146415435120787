import type { FC } from 'react';
import datanotfound from '../../Assets/no-data-found.png'

interface DataNotFoundPageProps { }

const DataNotFoundPage: FC<DataNotFoundPageProps> = () => {
    return (
        <div className="w-full h-2/3 flex flex-col gap-1 items-center justify-center">
            <img src={datanotfound} alt="datanotfound" className='w-[180px]' />
            <h1 className='text-3xl text-gray-700'>Data Not Found</h1>
        </div>
    );
}

export default DataNotFoundPage;
