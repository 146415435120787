import { useContext, type FC, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { DataContext } from '../../Context/DataProvider';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import InputText from '../../Component/FormControl/InputText';
import InputSelect from '../../Component/FormControl/InputSelect';
import CancelButton from '../../Component/FormControl/CancelButton';
import SubmitButton from '../../Component/FormControl/SubmitButton';
import ButtonLoader from '../../Component/Common/Loader/ButtonLoader';
import { applicationOfAirs, capacityControls, cooleds, dataLoggingFrequencys, numberofStageses, technologys, typeOfCompressors } from '../../Component/FormControl/AllAssetTypeMaster';
import Multiselect from 'multiselect-react-dropdown';
import InputYeat from '../../Component/FormControl/InputYear';
import { RiDeleteBin4Line } from 'react-icons/ri';
import { FaPlusCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import CompanyAssetMonitoringForm from './CompanyAssetMonitoringForm';
import { CompanyAssetFormNewProps, assetTypesType, selectedMonitoringType, unitType, companyAssetValuesType, troubleshootsType } from './CompanyAssetsTypes/companyAssetTypes';


const CompanyAssetFormNew: FC<CompanyAssetFormNewProps> = ({ setIsLoading, setCompletedTabs, setIsActive, completdTab }) => {
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const [selectedMonitoring, setSelectedMonitoring] = useState<selectedMonitoringType[]>([]);
    const [selectedTroubleshoots, setSelectedTroubleshoots] = useState<any[]>([]);
    const [assetTypes, setAssetTypes] = useState<assetTypesType[]>([])
    const [units, setUnits] = useState<unitType[]>([])
    const [selectedAssetType, setSelectedAssetType] = useState<string>('')
    const [troubleshoots, setTroubleshoots] = useState<troubleshootsType[]>([])
    const [monitorings, setMonitorings] = useState<selectedMonitoringType[]>([])
    const companyOnbord = sessionStorage.getItem('companyOnbord');
    const [rangeOpen, setRangeOpen] = useState<number[]>([0]);
    const navigate = useNavigate()
    const [initialValues, setInitialValues] = useState<any>({
        companyAssets: [
            {
                assetType: '',
                dataLoggingFrequency: '',
                compressorLocation: '',
                assetNamebyCompany: '',
                applicationOfAir: '',
                typeOfCompressor: "",
                technology: "",
                numberofStagese: "",
                cooled: "",
                capacityControl: "",
                make: '',
                model: '',
                yearOfManufacturing: '',
                motorkW: '',
                workingPressureBarg: '',
                flowCFM: '',
                monitoring: [],
            }
        ]
    })
    const handlePrevious = () => {
        setIsActive('basic_details')
    }

    const handleMonitoringRemove = (e: any, assetIndex: number, values: companyAssetValuesType) => {
        let newValuesWithUpdatedMonitorings = values?.companyAssets[assetIndex].monitoring?.filter((mt: any) => e?.map((item: selectedMonitoringType) => { return item._id }).includes(mt.monitoringType));
        const newValues = { ...values, companyAssets: [...values?.companyAssets.slice(0, assetIndex), { ...values?.companyAssets[assetIndex], monitoring: newValuesWithUpdatedMonitorings }, ...values?.companyAssets.slice(assetIndex + 1)] };
        setInitialValues(newValues)
        const updatedSelectedMonitoring = [...selectedMonitoring];
        updatedSelectedMonitoring[assetIndex] = e;
        console.log(updatedSelectedMonitoring);
        setSelectedMonitoring(updatedSelectedMonitoring);
    }

    const handleMonitoringChange = (e: selectedMonitoringType, assetIndex: number) => {
        const updatedSelectedMonitoring = [...selectedMonitoring];
        updatedSelectedMonitoring[assetIndex] = e;
        setSelectedMonitoring(updatedSelectedMonitoring);
    };
    const handleTroubleshootsRemove = (e: any) => {
        setSelectedTroubleshoots(e)
    }

    const handleTroubleshootsChange = (e: any) => {
        setSelectedTroubleshoots(e);
    };



    const handleOpen = (index: number) => {
        if (rangeOpen.includes(index)) {
            setRangeOpen(rangeOpen.filter((range: number) => range !== index));
        } else {
            setRangeOpen([...rangeOpen, index])
        }
    }

    const getAssetType = async () => {
        try {
            const response: responseType = await http({
                url: `/assetType/getAssetTypeForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAssetTypes(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset type');
            }
        }
    }

    const getTroubleshoots = async () => {
        try {
            const response: responseType = await http({
                url: `/troubleshootingTip/getTroubleshootingTipForAsset`,
                method: 'get',
                data: { assetType: selectedAssetType }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setTroubleshoots(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching Troubleshoots');
            }
        }
    }
    const getUnits = async () => {
        try {
            const response: responseType = await http({
                url: `/unit/getUnitForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setUnits(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching units.');
            }
        }
    }

    const getMonitoringType = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringType/getMonitoringTypeForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setMonitorings(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error monitoring type.');
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (completdTab?.includes('commercial_details')) {
        }
        getAssetType();
        getUnits();
        getMonitoringType()
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (selectedAssetType !== '') {
            getTroubleshoots();
        }
        // eslint-disable-next-line
    }, [selectedAssetType])

    const validationSchema = Yup.object().shape({
        companyAssets: Yup.array().of(
            Yup.object().shape({
                assetType: Yup.string().required('Asset Type is required'),
                newAssetType: Yup.string().when('assetType', ([assetType]) => {
                    return assetType !== 'createNew'
                        ? Yup.string()
                        : Yup.string().required('Asset Type is required');
                }),
                dataLoggingFrequency: Yup.string().required('Data logging frequency is required'),
                compressorLocation: Yup.string().required('compressor location is required'),
                applicationOfAir: Yup.string().required('Application of air is required'),
                assetNamebyCompany: Yup.string().required('Asset name by company is required'),
                typeOfCompressor: Yup.string().required('Type of compressor is required'),
                technology: Yup.string().required('technology is required'),
                numberofStagese: Yup.string().required('Number of stagese is required'),
                cooled: Yup.string().required('Air cooled or water cooled is required'),
                capacityControl: Yup.string().required('Capacity control is required'),
                make: Yup.string().required('Make is required'),
                model: Yup.string().required('Model is required'),
                yearOfManufacturing: Yup.string().required('Year of manufacturing is required'),
                motorkW: Yup.string().required('Motor kW is required'),
                workingPressureBarg: Yup.string().required('Working pressure barg is required'),
                flowCFM: Yup.string().required('Flow CFM is required'),
                monitoring: Yup.array().of(
                    Yup.object().shape({
                        // monitoringType: Yup.string().required('mt is reqd...'),
                        unit: Yup.string().required('Unit is required'),
                        ranges: Yup.object().shape({
                            minimum: Yup.number().required('Min is required'),
                            maximum: Yup.number().required('Max is required'),
                            alarm: Yup.number().required('Alarm is required'),
                            tripping: Yup.number().required('Tripping is required'),
                        })
                    })
                )
            })
        )
    });

    const handleSubmit = async (values: companyAssetValuesType, { resetForm }: any) => {
        setButtonLoader(true)
        let newValues = values?.companyAssets?.map((value: any) => {
            return {
                ...value,
                company: companyOnbord,
                troubleshoots: selectedTroubleshoots
            }
        })
        try {
            const response: responseType = await http({
                url: `/companyAsset/addCompanyAsset`,
                method: 'post',
                data: newValues
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setTimeout(() => {
                    setCompletedTabs('asset')
                    setButtonLoader(false)
                    sessionStorage.removeItem('activeTab')
                    sessionStorage.removeItem('companyOnbord')
                    sessionStorage.removeItem('completdTab')
                    navigate('/home/configuration/company')
                }, 1000);
            } else {
                setButtonLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            setButtonLoader(false)
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error adding commercial details.');
            }
        }
    };

    const handleAssetTypeChange = (e: any, setFieldValue: any, assetIndex: number) => {
        setSelectedAssetType(e.target.value)
        setFieldValue(`companyAssets.${assetIndex}.assetType`, e.target.value)
    }

    const handleTechnologySelect = (e: any, setFieldValue: any, assetIndex: number) => {
        setFieldValue(`companyAssets.${assetIndex}.technology`, e.target.value)
        setFieldValue(`companyAssets.${assetIndex}.monitoring`, [])
        let newMonitorings: any = [
            ...selectedMonitoring.slice(0, assetIndex),
            [],
            ...selectedMonitoring.slice(assetIndex + 1)
        ];
        setSelectedMonitoring(newMonitorings)
    }
    return (
        <div className='w-full relative'>
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form className='flex flex-col gap-4 p-3'>
                            <FieldArray name="companyAssets">
                                {({ remove, push }: { remove: (index: number) => void, push: (value: any) => void }) => (
                                    <>
                                        {values?.companyAssets?.length > 0 && values?.companyAssets?.map((asset: any, assetIndex: number) => (
                                            <div key={assetIndex} className="w-full border-b-2 border-skin-table-color pb-2">
                                                <h2 className='text-gray-700 mb-2'>Company Asset: {assetIndex + 1}</h2>
                                                <div className="mb-2 w-full flex gap-8">
                                                    <div className='relative group w-[90%] flex flex-col gap-3'>
                                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                                            {values.companyAssets[assetIndex].assetType === 'createNew' ? (
                                                                <InputText
                                                                    name={`companyAssets.${assetIndex}.newAssetType`}
                                                                    id={`companyAssets.${assetIndex}.newAssetType`}
                                                                    label='Enter New Asset Type'
                                                                    autoFocus={true}
                                                                />
                                                            ) : (
                                                                <div className="relative z-0 w-full group">
                                                                    <Field
                                                                        name={`companyAssets.${assetIndex}.assetType`}
                                                                        id={`companyAssets.${assetIndex}.assetType`}
                                                                        onChange={(e: any) => handleAssetTypeChange(e, setFieldValue, assetIndex)}
                                                                        className="block p-2 w-full text-sm  rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer" as="select">
                                                                        <option value="">Select Asset Type</option>
                                                                        {assetTypes?.map((item: any) => (<option key={item?._id} value={item?._id}>{item.name}</option>))}
                                                                        {
                                                                            <option value="createNew">Create New Asset Type</option>
                                                                        }
                                                                    </Field>
                                                                    <label htmlFor={`companyAssets.${assetIndex}.assetType`} className='peer-focus:font-medium absolute text-gray-400 peer-focus:text-gray-800 font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet  z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>Asset type <span className={`text-red-500`}>*</span></label>
                                                                    <span className="text-red-500 text-sm"><ErrorMessage name={`companyAssets.${assetIndex}.assetType`} /></span>
                                                                </div>
                                                            )}
                                                            <InputSelect
                                                                name={`companyAssets.${assetIndex}.dataLoggingFrequency`}
                                                                id={`companyAssets.${assetIndex}.dataLoggingFrequency`}
                                                                label='Data Logging Frequency'
                                                                Array={dataLoggingFrequencys} />
                                                            <InputText
                                                                name={`companyAssets.${assetIndex}.compressorLocation`}
                                                                id={`companyAssets.${assetIndex}.compressorLocation`}
                                                                label='Compressor Location' />
                                                            <InputText
                                                                name={`companyAssets.${assetIndex}.assetNamebyCompany`}
                                                                id={`companyAssets.${assetIndex}.assetNamebyCompany`}
                                                                label='Asset Name by Company' />
                                                            <InputSelect
                                                                name={`companyAssets.${assetIndex}.applicationOfAir`}
                                                                id={`companyAssets.${assetIndex}.applicationOfAir`}
                                                                label='Application of Air'
                                                                Array={applicationOfAirs} />
                                                            <InputSelect
                                                                name={`companyAssets.${assetIndex}.typeOfCompressor`}
                                                                id={`companyAssets.${assetIndex}.typeOfCompressor`}
                                                                label='Type of Compressor'
                                                                Array={typeOfCompressors} />
                                                            <div className="relative z-0 w-full group">
                                                                <Field
                                                                    id={`companyAssets.${assetIndex}.technology`}
                                                                    name={`companyAssets.${assetIndex}.technology`}
                                                                    onChange={(e: any) => handleTechnologySelect(e, setFieldValue, assetIndex)}
                                                                    as="select"
                                                                    className={`block p-2 w-full text-sm rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer`}
                                                                >
                                                                    <option value="">Select technologys</option>
                                                                    {technologys?.map((item: any) => (
                                                                        <option key={item?._id} value={item?._id}>{item.name}</option>
                                                                    ))}
                                                                </Field>
                                                                <label htmlFor={`companyAssets.${assetIndex}.technology`} className='peer-focus:font-medium absolute text-skin-lable peer-focus:text-gray-800 font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>technologys  <span className={`text-red-500`}>*</span></label>
                                                                <span className="text-red-500 text-sm"><ErrorMessage name={`companyAssets.${assetIndex}.technology`} /></span>
                                                            </div>
                                                            <InputSelect
                                                                name={`companyAssets.${assetIndex}.numberofStagese`}
                                                                id={`companyAssets.${assetIndex}.numberofStagese`}
                                                                label='Number of Stagese'
                                                                Array={numberofStageses} />
                                                            <InputSelect
                                                                name={`companyAssets.${assetIndex}.cooled`}
                                                                id={`companyAssets.${assetIndex}.cooled`}
                                                                label='Air Cooled Or water Cooled'
                                                                Array={cooleds} />
                                                            <InputSelect
                                                                name={`companyAssets.${assetIndex}.capacityControl`}
                                                                id={`companyAssets.${assetIndex}.capacityControl`}
                                                                label='Capacity Control'
                                                                Array={capacityControls} />
                                                            <InputText
                                                                name={`companyAssets.${assetIndex}.make`}
                                                                id={`companyAssets.${assetIndex}.make`}
                                                                label='Make' />
                                                            <InputText
                                                                name={`companyAssets.${assetIndex}.model`}
                                                                id={`companyAssets.${assetIndex}.model`}
                                                                label='Model' />
                                                            <InputYeat
                                                                name={`companyAssets.${assetIndex}.yearOfManufacturing`}
                                                                id={`companyAssets.${assetIndex}.yearOfManufacturing`}
                                                                label='Year Of Manufacturing' />
                                                            <InputText
                                                                name={`companyAssets.${assetIndex}.motorkW`}
                                                                id={`companyAssets.${assetIndex}.motorkW`}
                                                                label='Motor kW' />
                                                            <InputText
                                                                name={`companyAssets.${assetIndex}.workingPressureBarg`}
                                                                id={`companyAssets.${assetIndex}.workingPressureBarg`}
                                                                label='Working Pressure-Barg' />
                                                            <InputText
                                                                name={`companyAssets.${assetIndex}.flowCFM`}
                                                                id={`companyAssets.${assetIndex}.flowCFM`}
                                                                label='Flow CFM' />
                                                            {
                                                                (values.companyAssets[assetIndex].assetType !== 'createNew') && (
                                                                    <Multiselect
                                                                        displayValue="problem"
                                                                        onKeyPressFn={() => { }}
                                                                        onRemove={(e) => handleTroubleshootsRemove(e)}
                                                                        onSearch={() => { }}
                                                                        onSelect={(e) => handleTroubleshootsChange(e)}
                                                                        options={troubleshoots || []}
                                                                        selectedValues={selectedTroubleshoots || []}
                                                                        placeholder="Select Troubleshoots"
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                (values.companyAssets[assetIndex].technology === 'Screw' || values.companyAssets[assetIndex].technology === 'Centrifugal') && (
                                                                    <Multiselect
                                                                        displayValue="name"
                                                                        onKeyPressFn={() => { }}
                                                                        onRemove={(e) => handleMonitoringRemove(e, assetIndex, values)}
                                                                        onSearch={() => { }}
                                                                        onSelect={(e) => handleMonitoringChange(e, assetIndex)}
                                                                        options={values.companyAssets[assetIndex].technology === 'Screw' ? monitorings?.filter((item) => item.technology === 'Screw') : values.companyAssets[assetIndex].technology === 'Centrifugal' ? monitorings?.filter((item) => item.technology === 'Centrifugal') : []}
                                                                        selectedValues={selectedMonitoring[assetIndex] || []}
                                                                        placeholder="Select Asset Monitoring"
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                        <CompanyAssetMonitoringForm assetIndex={assetIndex} handleOpen={handleOpen}
                                                            rangeOpen={rangeOpen} selectedMonitoring={selectedMonitoring}
                                                            setFieldValue={setFieldValue} units={units} />
                                                        <div onClick={() => remove(assetIndex)} className='absolute right-5 top-2.5 group-hover:block text-xl hidden text-red-600 cursor-pointer'><RiDeleteBin4Line />
                                                        </div>
                                                    </div>
                                                    {assetIndex === values?.companyAssets?.length - 1 && (
                                                        <div className={` text-green-600 text-2xl`}>
                                                            <FaPlusCircle
                                                                title='Add company asset'
                                                                onClick={() => push({
                                                                    assetType: '',
                                                                    dataLoggingFrequency: '',
                                                                    compressorLocation: '',
                                                                    assetNamebyCompany: '',
                                                                    applicationOfAir: '',
                                                                    typeOfCompressor: '',
                                                                    technology: '',
                                                                    numberofStagese: '',
                                                                    cooled: '',
                                                                    capacityControl: '',
                                                                    make: '',
                                                                    model: '',
                                                                    yearOfManufacturing: '',
                                                                    motorkW: '',
                                                                    workingPressureBarg: '',
                                                                    flowCFM: '',
                                                                    monitoring: [],
                                                                })}
                                                                className='cursor-pointer ' />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </>
                                )}
                            </FieldArray>
                            <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet pt-2">
                                <CancelButton onClick={handlePrevious} label='Previous' />
                                {buttonLoader ? <ButtonLoader /> : <SubmitButton label='Save' />}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default CompanyAssetFormNew;
