const taxPreferences = [{ _id: 'Tax Exempted', name: 'Tax Exempted' }, { _id: 'Taxable', name: 'Taxable' }]
const currencyes = [{ _id: 'Indian Rupee', "name": "Indian Rupee", "symbol": "₹" }, { _id: 'US Dollar', "name": "US Dollar", "symbol": "$" }]
const contractPeriods = [{ _id: 12, name: '12 Months' }, { _id: 24, name: '24 Months' }, { _id: 36, name: '36 Months' }]
const paymentTermses = [{ _id: 'As per Agreement', name: 'As per Agreement' }, { _id: 'Advance invoicing half Year', name: 'Advance invoicing half Year' }, { _id: 'Annually', name: 'Annually' }]

export {
    taxPreferences,
    currencyes,
    contractPeriods,
    paymentTermses
}