import { type FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { MdOutlineSyncProblem } from 'react-icons/md';
import { TbRulerMeasure } from 'react-icons/tb';
import { LiaToolsSolid } from 'react-icons/lia';
import { FaWatchmanMonitoring } from 'react-icons/fa'
import { IoLogoPlaystation } from 'react-icons/io'
import { BsBuildings } from 'react-icons/bs'
import { SiTaichilang } from 'react-icons/si'
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../../Context/DataProvider';

interface LeftSubNaveProps { }

const LeftSubNave: FC<LeftSubNaveProps> = () => {
    const { themes, isVisible, sideNavCollapse } = useContext(DataContext);
    // const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    const { t } = useTranslation('title');
    const preventChildClick = (e: any) => {
        e.stopPropagation();
    }
    return (
        <div className={`${themes} h-full bg-skin-fill block sm:hidden text-skin-base transition-all duration-500 ${sideNavCollapse === false ? 'translate-x-0 w-[180px] ' : '-translate-y-full w-0 h-0 '}`}>
            <nav onClick={(e) => preventChildClick(e)} className='flex flex-col gap-1 ps-1 pr-4 py-1 h-full'>
                {isVisible('role-read') &&
                    <NavLink to={'/home/configuration/rolesTable'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg  flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <AiOutlineUsergroupAdd className='text-lg' />
                        <span className={`truncate transition-all`}>{t('Roles')}</span>
                    </NavLink>}
                {isVisible('company-read') &&
                    <NavLink to={'/home/configuration/company'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <BsBuildings className='text-lg' />
                        <span className={`truncate transition-all`}>{t('Company')}</span>
                    </NavLink>}
                {/* {isVisible('asset-read') &&
                    <NavLink to={'/home/configuration/asset'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <TbAsset className='text-2xl' />
                        <span className={`truncate transition-all`}>{t('Asset')}</span>
                    </NavLink>} */}
                {isVisible('assettype-read') &&
                    <NavLink to={'/home/configuration/assettype'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <LiaToolsSolid className='text-2xl' />
                        <span className={`truncate transition-all`}>{t('Asset Type')}</span>
                    </NavLink>}
                {isVisible('monitoringtype-read') &&
                    <NavLink to={'/home/configuration/monitoringtype'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <FaWatchmanMonitoring className='text-2xl' />
                        <span className={`truncate transition-all`}>{t('Monitoring Type')}</span>
                    </NavLink>}
                {isVisible('unit-read') &&
                    <NavLink to={'/home/configuration/unit'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <TbRulerMeasure className='text-2xl' />
                        <span className={`truncate transition-all`}>{t('Unit')}</span>
                    </NavLink>}
                {isVisible('country-read') &&
                    <NavLink to={'/home/configuration/country'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <SiTaichilang className='text-2xl' />
                        <span className={`truncate transition-all`}>{t('Country')}</span>
                    </NavLink>}
                {isVisible('state-read') &&
                    <NavLink to={'/home/configuration/state'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <IoLogoPlaystation className='text-2xl' />
                        <span className={`truncate transition-all`}>{t('State')}</span>
                    </NavLink>}
                {isVisible('problemsolution-read') &&
                    <NavLink to={'/home/configuration/problemsolution'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <MdOutlineSyncProblem className='text-2xl' />
                        <span className={`truncate transition-all`}>{t('Troubleshooting')}</span>
                    </NavLink>}
                {/* {isVisible('notificationtype-read') &&
                    <NavLink to={'/home/configuration/notificationtype'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : ' text-skin-base-nav-text'} rounded-lg px-2 flex items-center  h-9 gap-1 text-sm  font-light ${sideNavCollapse === false ? 'px-2' : 'hidden'}  `}>
                        <IoMdNotificationsOutline className='text-2xl' />
                        <span className={`truncate transition-all`}>{t('Notification Type')}</span>
                    </NavLink>} */}
            </nav>
        </div>
    );
}

export default LeftSubNave;