import { FC, useContext, useEffect, useState } from 'react';
import NotificationActionPop from '../../Component/Common/ActionPop/NotificationActionPop';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import Loader from '../../Component/Common/Loader/Loader';
import nontfc from '../../Assets/no-notifications.png'
import { DataContext } from '../../Context/DataProvider';
import { GrActions } from 'react-icons/gr';
import moment from 'moment';

interface NotificationProps { }

const Notification: FC<NotificationProps> = () => {
    const [notifications, setNotifications] = useState<any[]>([])
    const loggedInUser = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const { notificationUpdate } = useContext(DataContext);
    const [seenFilter, setSeenFilter] = useState<boolean>(false)
    const [notificationId, setNotificationId] = useState<string>('')
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();
    const handleMarkAsSeen = async () => {
        try {
            const response: responseType = await http({
                url: `/notification/markNotificationAsSeen`,
                method: 'put',
                data: { _id: notificationId }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                console.log(response.data.message)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching notification detail.');
            }
        }
    }
    const handleRemove = () => {

    }
    const handleReport = () => {

    }
    const action: any[] = [
        { id: 1, name: 'Mark as Read', click: handleMarkAsSeen, icon: 'IoMdCheckmark' },
        { id: 2, name: 'Remove this notificatioin', click: handleRemove, icon: 'MdClose' },
        { id: 3, name: 'Report issue to notification team', click: handleReport, icon: 'GoReport' }
    ]
    const fetchNotifications = async () => {
        try {
            const response: responseType = await http({
                url: `/notification/getNotifications`,
                method: 'get',
                data: { seen: seenFilter, company: loggedInUser?.company?._id ? loggedInUser?.company?._id : null }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setNotifications(response?.data?.data);
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching notifications.');
            }
        }
    }
    useEffect(() => {
        fetchNotifications();
        // eslint-disable-next-line
    }, [seenFilter, notificationUpdate])
    const handleNotificationDetail = (_id: string) => {
        navigate(`/home/notificationDetail/${_id}`)
    }
    return (
        <>
            <div className='w-full h-full bg-skin-fill-notification overflow-y-scroll scrollbar-thin relative'>
                <Loader loader={isLoading} />
                <div className='w-full h-full px-4 flex flex-col gap-1'>
                    <div className='flex gap-2 justify-start py-2 bg-skin-fill-notification sticky top-0 z-40'>
                        <button onClick={() => setSeenFilter(true)} className={`${seenFilter ? 'bg-skin-fill-notification-unreadButton-hover' : 'bg-skin-fill-notification-unreadButton'} hover:bg-skin-fill-notification-unreadButton-hover text-skin-base-notification-text px-3 py-1 rounded-2xl`}>All</button>
                        <button onClick={() => setSeenFilter(false)} className={`${!seenFilter ? 'bg-skin-fill-notification-unreadButton-hover' : 'bg-skin-fill-notification-unreadButton'} hover:bg-skin-fill-notification-unreadButton-hover text-skin-base-notification-text px-3 py-1 rounded-2xl`}>Unread</button>
                    </div>
                    <div className='w-full h-full'>
                        {notifications.length > 0 ? notifications?.map((notification: any, index: number) => (
                            <div key={index} className={`flex gap-2 mt-2  justify-between hover:bg-skin-fill-notification-hover px-3 py-2 items-center border border-l-4 ${notification?.fallen_range === 'r1' ? ' border-red-600' : notification?.fallen_range === 'r2' ? ' border-orange-600' : notification?.fallen_range === 'r3' ? 'border-yellow-400' : notification?.fallen_range === 'r4' && 'border-green-600'} rounded-lg group`}>
                                {notification.action_taken !== null &&
                                    <div className="w-[5%] md:w-[3%] flex flex-col items-center">
                                        <GrActions size={22} />
                                        <span className='text-xs truncate'>{moment(notification.action_taken_at).format('hh:mm a')}</span>
                                    </div>}
                                <div className='flex gap-2 h-12 w-[95%] md:w-[97%]'>
                                    <div onClick={() => handleNotificationDetail(notification?._id)} className='w-full flex flex-col cursor-pointer'>
                                        <p className='font-medium w-full text-skin-base-notification-text'>Dear, {loggedInUser?.name}</p>
                                        <p className='text-skin-notification-secondary-text text-sm font-thin'>{notification?.log?.companyAsset?.name ?? notification?.log?.companyAsset?.asset?.name}:{notification.content}</p>
                                    </div>
                                </div>
                                <div onClick={() => setNotificationId(notification?._id)} className='group-hover:block hidden'><NotificationActionPop action={action} icon={'FiMoreVertical'} /></div>
                            </div>
                        ))
                            :
                            <div className="w-full h-full flex justify-center items-center">
                                <img src={nontfc} alt="no notifications" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notification;
