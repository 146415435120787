import dash from '../Assets/DefaultTheme/dash.png'
import t1 from '../Assets/DefaultTheme/t1.png'
import t2 from '../Assets/DefaultTheme/t2.png'
import card from '../Assets/DefaultTheme/cards.png'
import forms from '../Assets/DefaultTheme/forms.png'
import loader from '../Assets/DefaultTheme/loader.png'

const arrayOfDefault: any[] = [
    {_id:1,URL:dash,mimetype:'dash'},
    {_id:2,URL:t1,mimetype:'t1'},
    {_id:3,URL:t2,mimetype:'t2'},
    {_id:4,URL:card,mimetype:'card'},
    {_id:5,URL:forms,mimetype:'forms'},
    {_id:6,URL:loader,mimetype:'loader'},
];
export default arrayOfDefault;