import { Form, Formik } from 'formik'
import { FC, useEffect, useState, useContext } from 'react'
import * as Yup from 'yup';
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import Loader from '../../../Component/Common/Loader/Loader';
import InputText from '../../../Component/FormControl/InputText';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../../Context/DataProvider';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';

interface AddRoleFormProps { }
interface IPermission {
  controller: string;
  actions: [{
    map(arg0: (el: { _id: number; action: string; selected: boolean; }) => import("react/jsx-runtime").JSX.Element): import("react").ReactNode;
    _id: string
    action: string
    selected: boolean
  }]
  selected: boolean
}

interface editRole {
  permissions: any[] | null
  role: {
    code: string
    name: string
    _id: string
  }
}

const AddRoleForm: FC<AddRoleFormProps> = () => {
  const { buttonLoader, setButtonLoader } = useContext(DataContext)
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [permissionMaster, setPermissionMaster] = useState<IPermission[]>([]);
  const [rolePermission, setRolePermission] = useState<any[]>([]);
  const [editRole, setEditRole] = useState<editRole | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate()

  const initialValues = {
    name: '',
    code: ''
  };


  const fetchRole = async () => {
    try {
      const response: responseType = await http({
        url: `/role/getRoleForEdit`,
        method: 'get',
        data: { '_id': id }
      });
      if (response?.data?.code === 'SUCCESS_200') {
        setEditRole(response?.data?.data);
        initialValues.name = response?.data?.data.role.name;
        initialValues.code = response?.data?.data.role.code;
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Error fetching roles.');
      }
    }
  };

  const fetchPermissions = async () => {
    try {
      const response: responseType = await http({
        url: `/permission/getpermission`,
        method: 'get',
      });
      if (response.data?.code === 'SUCCESS_200') {
        if (editRole !== null) {
          let data = response?.data?.data ? response?.data?.data : [];
          let selectedPermissionList = editRole.permissions?.map((e: any) => { return e.permission; })
          for (let el of data) {
            if (el.actions) {
              for (let action of el.actions) {
                action.selected = selectedPermissionList?.includes(action._id);
              }
            }
          }
          setPermissionMaster([...data]);
        } else {
          setPermissions(response.data?.data)
        }
      } else {
        console.error(response.data.message);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Error fetching permissions: ${error.response.data.message}`);
      } else {
        toast.error('Error fetching permissions. Please try again later.');
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500);
    if (id !== undefined) {
      fetchRole();
    }
    // eslint-disable-next-line 
  }, []);


  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line 
  }, [editRole]);

  useEffect(() => {
    setPermissions([...permissionMaster]);
    // console.log("permissions",permissions,permissionMaster, selectedPermissions);
    // eslint-disable-next-line 
  }, [permissionMaster]);


  const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required').min(3, 'Too short name').max(50, 'Too long name'),
  });
  const handleSubmit = async (values: any, { resetForm }: any) => {
    setButtonLoader(true)
    if (editRole !== null) {
      values['_id'] = editRole?.role?._id;
      try {
        const response: responseType = await http({
          url: `/role/editRole`,
          method: 'put',
          data: values
        });
        if (response.data?.code === 'SUCCESS_200') {
          setRolePermission({ ...rolePermission[0], 'role': response?.data?.data });
          let selectedActions = [];
          for (let controller of permissions) {
            for (let action of controller.actions) {
              if (action.selected) {
                selectedActions.push({ role: response?.data?.data, permission: action._id });
              }
            }
          }
          try {
            const response2: any = await http({
              url: `/rolePermission/addRolePermission`,
              method: 'post',
              data: selectedActions
            })
            if (response2?.data?.code === 'SUCCESS_200') {
              toast.success('Role Permissions Updated.');
              setTimeout(() => {
                setButtonLoader(false)
                navigate('/home/configuration/rolesTable');
                resetForm();
              }, 1000);
            } else {
              toast.error(response2?.data?.message);
            }
          } catch (error: any | unknown) {
            setButtonLoader(false)
            toast.error((error as any)?.response?.data?.message);
          }
        } else {
          setButtonLoader(false)
          toast.error(response?.data?.message);
        }
      } catch (error: any | unknown) {
        setButtonLoader(false)
        toast.error((error as any)?.response?.data?.message);
      }
    } else {
      try {
        const response: responseType = await http({
          url: `/role/addRole`,
          method: 'post',
          data: values
        });
        if (response.data?.code === 'SUCCESS_200') {
          setRolePermission({ ...rolePermission[0], 'role': response?.data?.data?._id });
          let selectedActions = [];
          for (let controller of permissions) {
            for (let action of controller.actions) {
              if (action.selected) {
                selectedActions.push({ role: response?.data?.data?._id, permission: action._id });
              }
            }
          }
          try {
            const response2: any = await http({
              url: `/rolePermission/addRolePermission`,
              method: 'post',
              data: selectedActions
            })
            if (response2?.data?.code === 'SUCCESS_200') {
              toast.success(response2?.data?.message);
              setTimeout(() => {
                setButtonLoader(false)
                navigate('/home/configuration/rolesTable');
                resetForm();
              }, 1000);
            } else {
              toast.error(response2?.data?.message);
            }
          } catch (error: any | unknown) {
            setButtonLoader(false)
            toast.error((error as any)?.response?.data?.message);
          }
        } else {
          setButtonLoader(false)
          toast.error(response?.data?.message);
        }
      } catch (error: any | unknown) {
        setButtonLoader(false)
        toast.error((error as any)?.response?.data?.message);
      }
    }
  };

  const handleActionChange = (action: any, i: number) => {
    action.selected = !action.selected;
    let allActionsSelected = true;
    for (let index in permissions[i].actions) {
      if (!permissions[i].actions[index].selected) {
        allActionsSelected = false;
        break;
      }
    }
    permissions[i].selected = allActionsSelected;
    setPermissions([...permissions]);
  };
  const handleCheckAll = (controller: any, i: number) => {
    controller.selected = !controller.selected;
    for (let index in controller.actions) {
      controller.actions[index].selected = controller.selected;
    }
    permissions[i] = { ...controller };
    setPermissions([...permissions]);
  };
  return (
    <div className='w-full h-full relative overflow-y-auto scrollbar-thin'>
      <div className="w-full px-2 sticky top-0 z-30 text-skin-base bg-skin-fill-formheader">
        <p className='py-1 font-medium text-skin-base text-lg'>{editRole == null ? t('AddRole') : t('EditRole')}</p>
        <hr />
      </div>
      <Loader loader={isLoading} />
      <div className="w-full">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className='p-2 relative'>
            <div className="w-full flex justify-end gap-2 bg-skin-fill-outlet sticky top-9 pt-3 z-20 mr-2 mb-4">
              <CancelButton onClick={() => navigate('/home/configuration/rolesTable')} />
              {buttonLoader ? <ButtonLoader /> : <SubmitButton label={editRole == null ? 'Create Role' : 'Update Role'} />}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-2">
              <InputText name='name' id='name' label='Name' autoFocus={true} />
              <InputText name='code' id='code' label='Code' disabled={editRole !== null ? true : false} />
            </div>
            <div>
              <p className='w-full py-1 border-b text-lg font-medium'>Permissions :</p>
              <ul className='list-decimal px-3 pt-1'>
                {permissions?.map((controller: IPermission, i: number) => (
                  <li key={controller?.controller} className='border-b border-b-gray-400 hover:bg-gray-50 py-1'>
                    <div className="flex items-center gap-1">
                      <input type="checkbox" checked={controller?.selected} onClick={() => handleCheckAll(controller, i)} />
                      <label htmlFor="" className='font-medium'>{controller?.controller.charAt(0).toUpperCase() + controller?.controller.slice(1)} :</label>
                    </div>
                    <div className='pl-5 flex gap-4'>
                      {['create', 'read', 'update', 'delete'].map(actionType => (
                        <div key={actionType}>
                          {controller?.actions?.filter(action => action.action === actionType).map((action, index) => (
                            <div className="flex items-center gap-1" key={action._id}>
                              <input
                                type="checkbox"
                                defaultChecked={action?.selected}
                                onClick={() => handleActionChange(action, index)}
                              />
                              <label htmlFor="">{(action.action.charAt(0).toUpperCase() + action.action.slice(1))}</label>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                  </li>
                ))}
              </ul>
            </div>

          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default AddRoleForm;
