import type { FC } from 'react';
import pageNotFound from '../../../Assets/error404.png'

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
    return (
        <>
        <div className='w-full min-h-screen h-[100%] bg-yellow-100 flex items-center justify-center'>
            <div className="w-[80%] min-h-[550px] bg-white flex flex-col items-center gap-5 py-[25px]">
                <div className='w-full flex flex-col items-center gap-6 px-10 mt-8'>
                    <h1 className='text-6xl font-semibold text-[#6FBCA2]'>404</h1>
                    <div className='w-full h-[6px] rounded-full bg-[#6FBCA2]'></div>
                </div>
                <div className='text-[#6B4D3A] text-3xl sm:text-5xl font-extralight'>
                    Page Not Found
                </div>
                <div className='full h-auto'>
                    <img src={pageNotFound} alt="Page Not Found" />
                </div>
            </div>
        </div>
        </>
    );
}

export default NotFound;