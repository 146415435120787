import type { FC, SetStateAction } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { AnimatePresence, motion } from 'framer-motion';

interface DeleteModalProps {
    open: boolean
    setOpen: React.Dispatch<SetStateAction<boolean>>
    title: string
    click: Function
}

const DeleteModal: FC<DeleteModalProps> = ({ open, setOpen, title, click }) => {
    const childClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }
    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={()=>setOpen(false)}
                    className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-70 z-50 transition-opacity duration-500"
                >
                    <div onClick={(event) => childClick(event)} className='sm:max-w-lg sm:mx-3 mx-5 w-full h-auto bg-white rounded-lg shadow-lg'>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <div className="text-base font-semibold leading-6 text-gray-900">
                                        Delete {title}
                                    </div>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to deactivate this {title}?
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                onClick={() => click()}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>

    );
}

export default DeleteModal;
