import { Formik, Form } from 'formik';
import { type FC, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import * as Yup from 'yup';
import http from '../../../Services/http/http';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import { toast } from 'react-toastify';
import Loader from '../../../Component/Common/Loader/Loader';
import InputText from '../../../Component/FormControl/InputText';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import { useTranslation } from 'react-i18next';
import InputSelect from '../../../Component/FormControl/InputSelect';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import { DataContext } from '../../../Context/DataProvider';

interface StateFormProps { }

const StateForm: FC<StateFormProps> = () => {
    const [editState, setEditState] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [countries, setCountries] = useState<any[]>([])
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { buttonLoader, setButtonLoader } = useContext(DataContext)

    const initialValues = {
        name: '',
        // code: '',
        country: ''
    };

    const fetchCountries = async () => {
        try {
            const response: responseType = await http({
                url: `/country/getCountryForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCountries(response?.data?.data);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching country');
            }
        }
    };
    const fetchState = async () => {
        try {
            const response: responseType = await http({
                url: `/state/getStateForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditState(response?.data?.data);
                initialValues.name = response?.data?.data.name;
                // initialValues.code = response?.data?.data.code;
                initialValues.country = response?.data?.data.country._id;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching state.');
            }
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (id !== undefined) {
            fetchState();
        }
        fetchCountries();
        // eslint-disable-next-line 
    }, []);

    const validationSchema = Yup.object().shape({
        code: Yup.string().required('Code is required'),
        name: Yup.string().required('Name is required').min(3, 'Too short name').max(50, 'Too long name'),
        country: Yup.string().required('Country is required').min(1, 'Country is required'),
    });

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        if (editState !== null) {
            values['_id'] = editState._id;
            try {
                const response: responseType = await http({
                    url: `/state/editState`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/state');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error updating state.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/state/addstate`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/state');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding state.');
                }
            }
        }
    };

    return (
        <div className='w-full h-full relative'>
            <div className="w-full px-2 sticky top-0 z-30 bg-skin-fill-formheader">
                <p className='py-1 font-medium text-skin-base text-lg'>{editState == null ? t('AddState') : t('EditState')}</p>
                <hr />
            </div>
            <Loader loader={isLoading} />
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4 p-3'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            <InputSelect name='country' id='country' label='Country' Array={countries} />
                            <InputText name='name' id='name' autoFocus={true} label='Name' />
                            {/* <InputText name='code' id='code' disabled={editState !== null ? true : false} label='Code' /> */}
                        </div>
                        <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                            <CancelButton onClick={() => navigate('/home/configuration/state')} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton label={editState == null ? 'Create State' : 'Update State'} />}
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default StateForm;
