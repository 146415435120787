import { Formik, Form } from 'formik';
import { type FC, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import * as Yup from 'yup';
import http from '../../../Services/http/http';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import { toast } from 'react-toastify';
import Loader from '../../../Component/Common/Loader/Loader';
import InputText from '../../../Component/FormControl/InputText';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import { useTranslation } from 'react-i18next';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import { DataContext } from '../../../Context/DataProvider';

interface CountryFormProps { }

const CountryForm: FC<CountryFormProps> = () => {
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const [editCountry, setEditCountry] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const initialValues = {
        name: '',
        // code: ''
    };

    const fetchCountry = async () => {
        try {
            const response: responseType = await http({
                url: `/country/getCountryForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditCountry(response?.data?.data);
                initialValues.name = response?.data?.data.name;
                // initialValues.code = response?.data?.data.code;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching country.');
            }
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (id !== undefined) {
            fetchCountry();
        }
        // eslint-disable-next-line 
    }, []);

    const validationSchema = Yup.object().shape({
        // code: Yup.string().required('Code is required'),
        name: Yup.string().required('Name is required').min(3, 'Too short name').max(50, 'Too long name'),
    });

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        if (editCountry !== null) {
            values['_id'] = editCountry._id;
            try {
                const response: responseType = await http({
                    url: `/country/editCountry`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/country');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error updating country.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/country/addCountry`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/country');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding country.');
                }
            }
        }
    };
    return (
        <div className='w-full h-full relative'>
            <div className="w-full px-2 sticky top-0 z-30 bg-skin-fill-formheader">
                <p className='py-1 font-medium text-skin-base text-lg'>{editCountry == null ? t('AddCountry') : t('EditCountry')}</p>
                <hr />
            </div>
            <Loader loader={isLoading} />
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4 p-3'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            <InputText name='name' id='name' autoFocus={true} label='Name' />
                            {/* <InputText name='code' id='code' disabled={editCountry !== null ? true : false} label='Code' /> */}
                        </div>
                        <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                            <CancelButton onClick={() => navigate('/home/configuration/country')} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton label={editCountry == null ? 'Create  Country' : 'Update  Country'} />}
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default CountryForm;
