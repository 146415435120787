import { useState, type FC, useEffect } from 'react';
import Loader from '../../Component/Common/Loader/Loader';
import PopUp from '../../Component/Common/PoUp/PopUp';
import PendingTasks from './Company/PendingTasks';
import LogAlerts from './Company/LogAlerts';
import CompanyUsersCounts from './Company/CompanyUsersCounts';
import CompanyAssetsCounts from './Company/CompanyAssetsCounts';
import CompaniesCount from './SuperAdmin/CompaniesCount';
import AssetsCount from './SuperAdmin/AssetsCount';
import CompanyBarGrapData from './Company/CompanyBarGrapData';
import CompanyDoughnutChartData from './Company/CompanyDoughnutChartData';
import BarGraphData from './SuperAdmin/BarGraphData';
import DoughNutChartData from './SuperAdmin/DoughNutChartData';
import PieChartData1 from './SuperAdmin/PieChartData1';
import PieChartData2 from './SuperAdmin/PieChartData2';
import Indicator from './Company/Indicator';

interface DashboardProps { }

const Dashboard: FC<DashboardProps> = () => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const [loading, setLoading] = useState<boolean>(true);
    const [openPopUp, setOpenPopUp] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 5000);
        // eslint-disable-next-line 
    }, [])
    return (
        <>
            <div className='w-full h-full lg:flex overflow-y-scroll scrollbar-thin py-2 lg:py-0 gap-2 relative'>
                <Loader loader={loading} />
                {/* right portion */}
                {userDetails.role.code !== 'superadmin'
                    &&
                    <div className="w-full lg:w-1/4 mb-2 md:mb-0 h-full flex flex-col gap-2 order-first md:order-last">
                        <PendingTasks setLoading={setLoading} setOpenPopUp={setOpenPopUp} />
                        <LogAlerts setLoading={setLoading} />
                    </div>
                }
                {/* left portion */}
                <div className='w-full flex flex-col gap-2'>
                    {userDetails.role.code !== 'superadmin' &&
                        <Indicator setLoading={setLoading} />
                    }
                    <div className='grid grid-cols-1 xl:grid-cols-2 gap-5 w-full'>
                        {/* left card */}
                        {userDetails.role.code === 'superadmin'
                            ?
                            <CompaniesCount setLoading={setLoading} />
                            :
                            <CompanyUsersCounts setLoading={setLoading} />
                        }
                        {/* right card */}
                        {userDetails.role.code === 'superadmin'
                            ?
                            <AssetsCount setLoading={setLoading} />
                            :
                            <CompanyAssetsCounts setLoading={setLoading} />
                        }
                        {/* Pie Chart 1 */}
                        {userDetails.role.code === 'superadmin'
                            ?
                            <PieChartData1 setLoading={setLoading} />
                            : null
                            // <CompanyAssetsCountForPieChart setLoading={setLoading} />
                        }
                        {/* Bar Graph */}
                        {userDetails.role.code === 'superadmin' ?
                            <BarGraphData setLoading={setLoading} />
                            :
                            <CompanyBarGrapData setLoading={setLoading} />
                        }
                        {/* Doughnut Chart */}
                        {userDetails.role.code === 'superadmin' ?
                            <DoughNutChartData setLoading={setLoading} />
                            :
                            <CompanyDoughnutChartData setLoading={setLoading} />
                        }
                        {/* Pie Chart 2 */}
                        {userDetails.role.code === 'superadmin' &&
                            <PieChartData2 setLoading={setLoading} />
                        }
                        {/* Bar Graph 2 */}
                        {/* {userDetails.role.code !== 'superadmin' &&
                            <CompanyBarGrapData2 setLoading={setLoading} />
                        } */}
                    </div>
                </div>
                <PopUp openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} active='monitorinLogForm' />
            </div>
        </>
    );
}

export default Dashboard;