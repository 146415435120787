import { useContext, type FC, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { DataContext } from '../../Context/DataProvider';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import InputText from '../../Component/FormControl/InputText';
import InputSelect from '../../Component/FormControl/InputSelect';
import CancelButton from '../../Component/FormControl/CancelButton';
import SubmitButton from '../../Component/FormControl/SubmitButton';
import ButtonLoader from '../../Component/Common/Loader/ButtonLoader';
import { applicationOfAirs, capacityControls, cooleds, dataLoggingFrequencys, numberofStageses, technologys, typeOfCompressors } from '../../Component/FormControl/AllAssetTypeMaster';
import Multiselect from 'multiselect-react-dropdown';
import InputYeat from '../../Component/FormControl/InputYear';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CompanyAssetMonitoringForm2 from './CompanyAssetMonitoringForm2';
import Loader from '../../Component/Common/Loader/Loader';
import { assetTypesType, companyType, editCompanyAsset, editCompanyAssetValuesType, selectedMonitoringType, troubleshootsType, unitType } from './CompanyAssetsTypes/companyAssetTypes';

interface CompanyAssetFormEditProps { }


const CompanyAssetFormEdit: FC<CompanyAssetFormEditProps> = () => {
    const { id } = useParams()
    const { buttonLoader, setButtonLoader, isRender } = useContext(DataContext)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [editCompanyAsset, setEditCompanyAsset] = useState<editCompanyAsset | null>(null)
    const [selectedMonitoring, setSelectedMonitoring] = useState<selectedMonitoringType[]>([]);
    const [assetTypes, setAssetTypes] = useState<assetTypesType[]>([])
    const [selectedAssetType, setSelectedAssetType] = useState<string>('')
    const [assetTypeForEditTroubleshoots, setAssetTypeForEditTroubleshoots] = useState<string>('')
    const [units, setUnits] = useState<unitType[]>([])
    const [monitorings, setMonitorings] = useState<selectedMonitoringType[]>([])
    const [companys, setCompanys] = useState<companyType[]>([])
    const navigate = useNavigate()
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    const [rangeOpen, setRangeOpen] = useState<number[]>([0]);
    const multiselectRef: any = useRef()
    const [selectedTroubleshoots, setSelectedTroubleshoots] = useState<any[]>([]);
    const [troubleshoots, setTroubleshoots] = useState<troubleshootsType[]>([])
    const [initialValues, setInitialValues] = useState<editCompanyAssetValuesType>({
        assetType: '',
        dataLoggingFrequency: '',
        compressorLocation: '',
        assetNamebyCompany: '',
        applicationOfAir: "",
        typeOfCompressor: "",
        technology: "",
        numberofStagese: "",
        cooled: "",
        capacityControl: "",
        make: '',
        model: '',
        yearOfManufacturing: '',
        motorkW: '',
        workingPressureBarg: '',
        flowCFM: '',
        company: '',
        monitoring: [],
    })
    const handleMonitoringRemove = (e: selectedMonitoringType[], values: editCompanyAssetValuesType) => {
        let newValuesWithUpdatedMonitorings = values?.monitoring?.filter((mt: any) => e?.map((item: selectedMonitoringType) => { return item._id }).includes(mt.monitoringType))
        const newValues = { ...values, 'monitoring': newValuesWithUpdatedMonitorings }
        setInitialValues(newValues)
        setSelectedMonitoring(e);
    }
    const handleMonitoringChange = async (e: selectedMonitoringType[]) => {
        setSelectedMonitoring(e);
    };

    const handleTroubleshootsRemove = (e: any) => {
        setSelectedTroubleshoots(e)
    }

    const handleTroubleshootsChange = (e: any) => {
        setSelectedTroubleshoots(e);
    };

    const handleOpen = (index: number) => {
        if (rangeOpen.includes(index)) {
            setRangeOpen(rangeOpen.filter((range: number) => range !== index));
        } else {
            setRangeOpen([...rangeOpen, index])
        }
    }

    const getAssetType = async () => {
        try {
            const response: responseType = await http({
                url: `/assetType/getAssetTypeForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAssetTypes(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset type');
            }
        }
    }
    const getUnits = async () => {
        try {
            const response: responseType = await http({
                url: `/unit/getUnitForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setUnits(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching units.');
            }
        }
    }
    const getTroubleshoots = async () => {
        try {
            const response: responseType = await http({
                url: `/troubleshootingTip/getTroubleshootingTipForAsset`,
                method: 'get',
                data: { assetType: selectedAssetType !== '' ? selectedAssetType : assetTypeForEditTroubleshoots }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setTroubleshoots(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching Troubleshoots');
            }
        }
    }
    const getMonitoringType = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringType/getMonitoringTypeForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                return response?.data?.data
            } else {
                toast.error(response?.data?.message);
                return [];
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error monitoring type.');
            }
        }
    }

    const fetchCompanies = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getCompanyForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompanys(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company.');
            }
        }
    };
    const fetchcompanyAssetDetails = async (monitorings: any) => {
        try {
            const response: responseType = await http({
                url: `/companyAsset/getCompanyAssetForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditCompanyAsset(response?.data?.data);
                setSelectedTroubleshoots(response?.data?.data?.troubleshoots)
                setAssetTypeForEditTroubleshoots(response?.data?.data?.assetType?._id)
                let editValues: any = {}
                editValues.company = response?.data?.data?.company?._id;
                editValues.assetType = response?.data?.data?.assetType?._id;
                editValues.dataLoggingFrequency = response?.data?.data?.dataLoggingFrequency;
                editValues.compressorLocation = response?.data?.data?.compressorLocation;
                editValues.assetNamebyCompany = response?.data?.data?.assetNamebyCompany;
                editValues.applicationOfAir = response?.data?.data?.applicationOfAir;
                editValues.typeOfCompressor = response?.data?.data?.typeOfCompressor;
                editValues.technology = response?.data?.data?.technology;
                editValues.numberofStagese = response?.data?.data?.numberofStagese;
                editValues.cooled = response?.data?.data?.cooled;
                editValues.capacityControl = response?.data?.data?.capacityControl;
                editValues.make = response?.data?.data?.make;
                editValues.model = response?.data?.data?.model;
                editValues.yearOfManufacturing = response?.data?.data?.yearOfManufacturing;
                editValues.motorkW = response?.data?.data?.motorkW;
                editValues.workingPressureBarg = response?.data?.data?.workingPressureBarg;
                editValues.flowCFM = response?.data?.data?.flowCFM;
                editValues.monitoring = response?.data?.data?.monitoring?.map((monitor: any) => { return { ...monitor } });
                setInitialValues(editValues)
                const monitoringsData = response?.data?.data?.monitoring;
                if (monitoringsData) {
                    const selectedMonitoringIds = await Promise.all(
                        monitoringsData.map((monitor: any) => monitor.monitoringType)
                    );
                    const selectedMonitorings = monitorings.filter((item: any) => selectedMonitoringIds.includes(item._id));
                    setSelectedMonitoring(selectedMonitorings);
                }
                setIsLoading(false)
            }

        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching user.');
            }
        }
    };
    const maintainTiming = async () => {
        try {
            const mt: any = await getMonitoringType();
            setMonitorings(mt)
            if (id !== undefined) {
                await fetchcompanyAssetDetails(mt);
            }
        } catch (error) {
            console.error('Error occurred:', error);
        }
    }
    useEffect(() => {
        maintainTiming();
        if (editCompanyAsset === null) {
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        }
        // eslint-disable-next-line 
    }, [id]);

    useEffect(() => {
        getAssetType();
        getUnits();
        fetchCompanies()
        // eslint-disable-next-line 
    }, [isRender]);

    useEffect(() => {
        if (selectedAssetType !== '' || editCompanyAsset !== null) {
            getTroubleshoots();
        }
        // eslint-disable-next-line
    }, [selectedAssetType, editCompanyAsset])


    let validationSchema: any = Yup.object().shape({
        assetType: Yup.string().required('Asset Type is required'),
        newAssetType: Yup.string().when('assetType', ([assetType]) => {
            return assetType !== 'createNew'
                ? Yup.string()
                : Yup.string().required('Asset Type is required');
        }),
        dataLoggingFrequency: Yup.string().required('Data logging frequency is required'),
        compressorLocation: Yup.string().required('compressor location is required'),
        applicationOfAir: Yup.string().required('Application of air is required'),
        assetNamebyCompany: Yup.string().required('Asset name by company is required'),
        typeOfCompressor: Yup.string().required('Type of compressor is required'),
        technology: Yup.string().required('technology is required'),
        numberofStagese: Yup.string().required('Number of stagese is required'),
        cooled: Yup.string().required('Air cooled or water cooled is required'),
        capacityControl: Yup.string().required('Capacity control is required'),
        make: Yup.string().required('Make is required'),
        model: Yup.string().required('Model is required'),
        yearOfManufacturing: Yup.string().required('Year of manufacturing is required'),
        motorkW: Yup.string().required('Motor kW is required'),
        workingPressureBarg: Yup.string().required('Working pressure barg is required'),
        flowCFM: Yup.string().required('Flow CFM is required'),
        monitoring: Yup.array().of(
            Yup.object().shape({
                // monitoringType: Yup.string().required('mt is reqd...'),
                unit: Yup.string().required('Unit is required'),
                ranges: Yup.object().shape({
                    minimum: Yup.number().required('Min is required'),
                    maximum: Yup.number().required('Max is required'),
                    alarm: Yup.number().required('Alarm is required'),
                    tripping: Yup.number().required('Tripping is required'),
                })
            })
        ),

    });
    if (userDetails.role.code === 'superadmin') {
        validationSchema = validationSchema.concat(
            Yup.object().shape({
                company: Yup.string().required('Company Type is required'),
            })
        );
    }

    const handleSubmit = async (values: editCompanyAssetValuesType, { resetForm }: any) => {
        setButtonLoader(true)
        values['troubleshoots'] = selectedTroubleshoots
        if (editCompanyAsset !== null) {
            values['_id'] = editCompanyAsset._id;
            try {
                const response: responseType = await http({
                    url: `/companyAsset/editCompanyAsset`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate(-1);
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error editing commercial details.');
                }
            }
        } else {
            const newValues = []
            if (values.company === '') {
                values['company'] = userDetails?.company?._id
            }
            newValues.push(values)
            try {
                const response: responseType = await http({
                    url: `/companyAsset/addCompanyAsset`,
                    method: 'post',
                    data: newValues
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        resetForm();
                        navigate(-1)
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding cpompany Asset Details.');
                }
            }
        };
    };
    const handleAssetTypeChange = (e: any, setFieldValue: any) => {
        setSelectedAssetType(e.target.value)
        setFieldValue(`assetType`, e.target.value)
    }

    const handleTechnologySelect = (e: any, setFieldValue: any, values: any) => {
        setFieldValue(`technology`, e.target.value)
        setFieldValue(`monitoring`, [])
        setSelectedMonitoring([])
    }

    return (
        <div className='w-full h-full relative overflow-y-auto'>
            <Loader loader={isLoading} />
            <div className="w-full h-auto">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form className='flex flex-col gap-4 p-3'>
                            <div className="w-full pb-2">
                                <div className='relative w-[95%] flex flex-col gap-3'>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                        {userDetails.role.code === 'superadmin' ? <InputSelect name='company' id='company' label='Company' Array={companys} disabled={editCompanyAsset !== null ? true : false} /> : null}
                                        {values.assetType === 'createNew' ? (
                                            <InputText
                                                name={`newAssetType`}
                                                id={`newAssetType`}
                                                label='Enter New Asset Type'
                                                autoFocus={true}
                                            />
                                        ) : (
                                            <div className="relative z-0 w-full group">
                                                <Field
                                                    name={`assetType`}
                                                    id={`assetType`}
                                                    onChange={(e: any) => handleAssetTypeChange(e, setFieldValue)}
                                                    className="block p-2 w-full text-sm  rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer" as="select">
                                                    <option value="">Select Asset Type</option>
                                                    {assetTypes?.map((item: any) => (<option key={item?._id} value={item?._id}>{item.name}</option>))}
                                                    {
                                                        <option value="createNew">Create New Asset Type</option>
                                                    }
                                                </Field>
                                                <label htmlFor={`assetType`} className='peer-focus:font-medium absolute text-gray-400 peer-focus:text-gray-800 font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet  z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>Asset type <span className={`text-red-500`}>*</span></label>
                                                <span className="text-red-500 text-sm"><ErrorMessage name={`assetType`} /></span>
                                            </div>
                                        )}
                                        <InputSelect
                                            name={`dataLoggingFrequency`}
                                            id={`dataLoggingFrequency`}
                                            label='Data Logging Frequency'
                                            Array={dataLoggingFrequencys} />
                                        <InputText
                                            name={`compressorLocation`}
                                            id={`compressorLocation`}
                                            label='Compressor Location' />
                                        <InputText
                                            name={`assetNamebyCompany`}
                                            id={`assetNamebyCompany`}
                                            label='Asset Name by Company' />
                                        <InputSelect
                                            name={`applicationOfAir`}
                                            id={`applicationOfAir`}
                                            label='Application of Air'
                                            Array={applicationOfAirs} />
                                        <InputSelect
                                            name={`typeOfCompressor`}
                                            id={`typeOfCompressor`}
                                            label='Type of Compressor'
                                            Array={typeOfCompressors} />
                                        <div className="relative z-0 w-full group">
                                            <Field
                                                id='technology'
                                                name='technology'
                                                onChange={(e: any) => handleTechnologySelect(e, setFieldValue, values)}
                                                as="select"
                                                className={`block p-2 w-full text-sm rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer`}
                                            >
                                                <option value="">Select technologys</option>
                                                {technologys?.map((item: any) => (
                                                    <option key={item?._id} value={item?._id}>{item.name}</option>
                                                ))}
                                            </Field>
                                            <label htmlFor='technology' className='peer-focus:font-medium absolute text-skin-lable peer-focus:text-gray-800 font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>technologys  <span className={`text-red-500`}>*</span></label>
                                            <span className="text-red-500 text-sm"><ErrorMessage name='technology' /></span>
                                        </div>
                                        <InputSelect
                                            name={`numberofStagese`}
                                            id={`numberofStagese`}
                                            label='Number of Stagese'
                                            Array={numberofStageses} />
                                        <InputSelect
                                            name={`cooled`}
                                            id={`cooled`}
                                            label='Air Cooled Or water Cooled'
                                            Array={cooleds} />
                                        <InputSelect
                                            name={`capacityControl`}
                                            id={`capacityControl`}
                                            label='Capacity Control'
                                            Array={capacityControls} />
                                        <InputText
                                            name={`make`}
                                            id={`make`}
                                            label='Make' />
                                        <InputText
                                            name={`model`}
                                            id={`model`}
                                            label='Model' />
                                        <InputYeat
                                            name={`yearOfManufacturing`}
                                            id={`yearOfManufacturing`}
                                            label='Year Of Manufacturing' />
                                        <InputText
                                            name={`motorkW`}
                                            id={`motorkW`}
                                            label='Motor kW' />
                                        <InputText
                                            name={`workingPressureBarg`}
                                            id={`workingPressureBarg`}
                                            label='Working Pressure-Barg' />
                                        <InputText
                                            name={`flowCFM`}
                                            id={`flowCFM`}
                                            label='Flow CFM' />
                                        {
                                            (values.assetType !== 'createNew') && (
                                                <Multiselect
                                                    displayValue="problem"
                                                    onKeyPressFn={() => { }}
                                                    onRemove={(e) => handleTroubleshootsRemove(e)}
                                                    onSearch={() => { }}
                                                    onSelect={(e) => handleTroubleshootsChange(e)}
                                                    options={troubleshoots || []}
                                                    selectedValues={selectedTroubleshoots || []}
                                                    placeholder="Select Troubleshoots"
                                                />
                                            )
                                        }
                                        {
                                            (values.technology === 'Screw' || values.technology === 'Centrifugal') && (
                                                <Multiselect
                                                    displayValue="name"
                                                    onKeyPressFn={() => { }}
                                                    onRemove={(e) => handleMonitoringRemove(e, values)}
                                                    onSearch={() => { }}
                                                    ref={multiselectRef}
                                                    onSelect={(e) => handleMonitoringChange(e)}
                                                    options={values.technology === 'Screw' ? monitorings?.filter((item) => item.technology === 'Screw') : values.technology === 'Centrifugal' ? monitorings?.filter((item) => item.technology === 'Centrifugal') : []}
                                                    selectedValues={selectedMonitoring}
                                                    placeholder="Select Asset Monitoring"
                                                />
                                            )
                                        }
                                    </div>
                                    <CompanyAssetMonitoringForm2 handleOpen={handleOpen}
                                        rangeOpen={rangeOpen} selectedMonitoring={selectedMonitoring}
                                        setFieldValue={setFieldValue} units={units} />
                                </div>
                            </div>
                            <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet">
                                <CancelButton onClick={() => navigate(-1)} label='Cancel' />
                                {buttonLoader ? <ButtonLoader /> : <SubmitButton label='Save' />}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default CompanyAssetFormEdit;
