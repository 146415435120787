import { useContext, type FC, useEffect, useState } from 'react';
import { DataContext } from '../../Context/DataProvider';
import { Form, Formik } from 'formik';
import InputNumber from '../../Component/FormControl/InputNumber';
import CancelButton from '../../Component/FormControl/CancelButton';
import SubmitButton from '../../Component/FormControl/SubmitButton';
import * as Yup from 'yup';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import FormLoader from '../../Component/Common/FormLoader';
import ButtonLoader from '../../Component/Common/Loader/ButtonLoader';
import { NavLink } from 'react-router-dom';

interface MonitoringLogFormProps {
    openPopUp: boolean
    setOpenPopUp: React.Dispatch<React.SetStateAction<boolean>>
}
type AssetMonitoringItem = {
    _id: string;
    ranges: {
        minimum: number,
        maximum: number,
        alarm: number,
        tripping: number
    }
    monitoringType: {
        _id: string,
        name: string
    }
    unit: string,
};

const MonitoringLogForm: FC<MonitoringLogFormProps> = ({ setOpenPopUp, openPopUp }) => {
    const { assetForLog, setAssetForLog, buttonLoader, setButtonLoader, isVisible, setIsRender, isRender } = useContext(DataContext);
    const [assetMonitoringData, setAssetMonitoringData] = useState<AssetMonitoringItem[]>([])
    const [initialValues, setInitialValues] = useState({})
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const createValidationSchema = (assetMonitoringData: AssetMonitoringItem[]) => {
        const schema = assetMonitoringData?.reduce((accumulator, monitoring) => {
            const fieldKey = `count_${monitoring.monitoringType._id}`;
            const fieldSchema = Yup.number();
            accumulator[fieldKey] = fieldSchema;
            return accumulator;
        }, {} as Record<string, Yup.NumberSchema | Yup.StringSchema>);
        return Yup.object().shape(schema);
    };
    const validationSchema = createValidationSchema(assetMonitoringData);
    const fetchAssetMonitorings = async () => {
        try {
            const response: responseType = await http({
                url: `/companyAsset/getAssetMonitoringByCompanyAssetId`,
                method: 'get',
                data: { companyAsset: assetForLog?._id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setTimeout(() => {
                    setAssetMonitoringData(response?.data?.data?.monitoring)
                    setIsLoading(false)
                }, 2000);
                const IV = await response?.data?.data?.monitoring?.reduce((accumulator: any, monitoring: any) => {
                    const fieldKey = `count_${monitoring?.monitoringType?._id}`;
                    accumulator[fieldKey] = '';
                    return accumulator;
                }, {} as any);
                setInitialValues(IV);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching monitoring');
            }
        }
    };

    useEffect(() => {
        fetchAssetMonitorings();
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (openPopUp === false) {
            setAssetForLog(null)
        }
        // eslint-disable-next-line
    }, [openPopUp])
    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        let finalValue: any = []
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                const splitted = key.split('_');
                finalValue.push({
                    count: values[key],
                    monitoringType: splitted[1],
                    companyAsset: assetForLog?._id
                });
            }
        }
        if (assetForLog?.hasOwnProperty('existLog') && assetForLog?.existLog === true) {
            let updatedValue: any[] = []
            await finalValue.forEach(async (value: any) => {
                await assetForLog?.monitoring?.forEach(async (item: any) => {
                    if (value?.monitoringType === item?.monitoringType?._id) {
                        value['logId'] = await item.logId;
                        updatedValue.push(value)
                    }
                })
            });
            try {
                const response: responseType = await http({
                    url: `/monitoringLog/editMonitoringLog`,
                    method: 'put',
                    data: updatedValue
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setIsRender(!isRender)
                    setTimeout(() => {
                        setButtonLoader(false)
                        setOpenPopUp(false)
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error editing monitoring log');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/monitoringLog/addMonitoringLog`,
                    method: 'post',
                    data: finalValue
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        setOpenPopUp(false)
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding monitoring log');
                }
            }
        }
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000);
        // eslint-disable-next-line
    }, [])

    return (
        <div className="w-full h-auto relative">
            {isLoading &&
                <div className='h-[200px] absolute left-0 top-0 w-full flex items-center justify-center bg-white z-50'>
                    <FormLoader />
                </div>}
            {assetMonitoringData?.length > 0 ?
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4'>
                        {assetMonitoringData?.map((monitoring: AssetMonitoringItem) => (
                            <div key={monitoring._id}>
                                <InputNumber
                                    name={`count_${monitoring?.monitoringType?._id}`}
                                    id={`count_${monitoring?.monitoringType?._id}`}
                                    label={monitoring?.monitoringType?.name}
                                />
                            </div>
                        ))}
                        <div className="float-right py-2 flex gap-2 justify-end">
                            <CancelButton onClick={() => setOpenPopUp(false)} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton />}
                        </div>
                    </Form>
                </Formik>
                :
                <div className='h-[200px] w-full flex flex-col gap-1 text-sm text-indigo-600 items-center justify-center p-2'>
                    <p>No monitorings alloted</p>
                    {isVisible('assetmonitoring-create') &&
                        <div className='flex flex-col gap-3 items-center'>
                            <p>To add monitorings to the asset</p>
                            <NavLink to={`/home/assetmonitoring/${assetForLog?._id}`} className='bg-skin-button-bgcolor py-1.5 text-skin-button-text px-2.5 rounded text-sm transition-all duration-300  hover:bg-opacity-20 border-secondary border'>Click here!</NavLink>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default MonitoringLogForm;
