import { useEffect, type FC, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { IactionPopAction, responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../../Context/DataProvider';
import ActionPop from '../../../Component/Common/ActionPop/ActionPop';
import Loader from '../../../Component/Common/Loader/Loader';
import DeleteModal from '../../../Component/Common/DeleteModal';
import Pagination from '../../../Component/Common/Pagination';
import Counter from '../../../Component/Common/Counter';
import SearchFilter from '../../../Component/Common/SearchFilter';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';

interface UnitProps { }
interface unitData {
    name: string
    _id: string
    type: string
}

const Unit: FC<UnitProps> = () => {
    const [unitData, setUnitData] = useState<unitData[] | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [Id, setId] = useState<string>();
    const navigate = useNavigate();
    const { isRender, setIsRender, selectedLimit, isVisible, dateFilter } = useContext(DataContext)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [skip, setSkip] = useState(0)
    const [counts, setCounts] = useState<number>(0)
    const [searchFilter, setSearchFilter] = useState<string>('')

    const handleEdit = () => {
        navigate(`/home/configuration/editUnit/${Id}`)
    }

    const DeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }
    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/unit/deleteUnit`,
                method: 'delete',
                data: { '_id': Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting unit.');
            }
        }
    }
    const action: IactionPopAction[] = []
    isVisible('unit-update') && action.push({ id: 1, name: 'Edit', click: handleEdit });
    isVisible('unit-delete') && action.push({ id: 2, name: 'Delete', click: DeleteModalOpen });
    const fetchUnits = async () => {
        try {
            let data: any = {
                search: searchFilter,
                dateFilter: dateFilter
            }
            if (searchFilter === '') {
                data.skip = skip;
                data.limit = selectedLimit;
            }
            const response: responseType = await http({
                url: `/unit/getUnit`,
                method: 'get',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setUnitData(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching unit.');
            }
        }
    };
    const getUnitCount = async () => {
        try {
            const response: responseType = await http({
                url: `/unit/getUnitCount`,
                method: 'get',
                data: {
                    search: searchFilter,
                    dateFilter: dateFilter
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCounts(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching count.');
            }
        }
    }
    useEffect(() => {
        getUnitCount();
        // eslint-disable-next-line 
    }, [searchFilter, isRender, dateFilter]);

    useEffect(() => {
        fetchUnits();
        // eslint-disable-next-line 
    }, [isRender, skip, searchFilter, selectedLimit, dateFilter]);
    useEffect(() => {
        setIsLoading(true);
        // eslint-disable-next-line 
    }, [skip, selectedLimit]);
    useEffect(() => {
        setSkip(0)
        // eslint-disable-next-line 
    }, [selectedLimit]);
    return (
        <div className='h-full w-full relative'>
            <Loader loader={isLoading} />
            <div className='flex items-center justify-between sm:pb-4 pb-2 '>
                <Counter skip={skip} counts={counts} limit={selectedLimit} />
                <SearchFilter setSearch={setSearchFilter} />
            </div>
            {counts === 0 ?
                <DataNotFoundPage />
                :
                <div className="flex sm:h-[90%] h-[92%]  flex-col w-full">
                    <div className="w-full h-auto overflow-auto scrollbar-thin">
                        <table className="w-full mb-2 text-left ">
                            <thead className=" border-l-white px-6  bg-skin-fill-table-head text-skin-base-table-head-text z-10  sticky top-0">
                                <tr>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        S.No.
                                    </th>
                                    <th scope="col" className="sticky left-0 bg-skin-fill-table-head px-4 py-3 font-medium truncate">
                                        Units
                                    </th>
                                    <th scope="col" className=" bg-skin-fill-table-head px-4 py-3 font-medium truncate">
                                        Type
                                    </th>
                                    {isVisible('configuration-update') || isVisible('configuration-delete') ?
                                        <th scope="col" className="py-3 font-medium truncate">
                                            Actions
                                        </th>
                                        : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {unitData?.map((unit, index) =>
                                    <tr key={unit._id} className="bg-skin-fill-table hover:bg-skin-fill-hover hover:text-skin-base-table-hover hover:bg-opacity-10 text-skin-base-table-body-text border-b border-skin-table-color group">
                                        <td className="px-4 py-[8px]">
                                            {searchFilter ? (index + 1) : (skip + (index + 1))}
                                        </td>
                                        <td className="sticky left-0 group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px] truncate">
                                            {unit?.name}
                                        </td>
                                        <td className=" group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px] truncate">
                                            {unit?.type}
                                        </td>
                                        {isVisible('unit-update') || isVisible('unit-delete') ?
                                            <td className="px-4 py-[8px]" >
                                                <span onClick={() => setId(unit?._id)}><ActionPop action={action} icon={'FiMoreVertical'} />
                                                </span>
                                            </td>
                                            : null
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {(counts > selectedLimit && searchFilter === '') && <Pagination limit={selectedLimit} setSkip={setSkip} itemsCount={counts} />}
                </div>
            }
            <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='Unit' />
        </div>
    );
}

export default Unit;
