
export const disableButton = (buttonRef: React.RefObject<HTMLButtonElement>) => {
    if (buttonRef.current) {
        buttonRef.current.disabled = true;
    }
}
export function getRandomColor() {
    const colors = ['red', 'green', 'blue', 'yellow', 'pink', 'orange', 'sky', 'Purple', 'Black', 'Brown', 'Gray'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    const selectedColor: string = colors[randomIndex];
    const rgbaColor = colorToRGBA(selectedColor);
    return rgbaColor;
}

function colorToRGBA(color: string): any {
    const colorMap: any = {
        red: '255,155,155',
        green: '164,212,163',
        blue: '54, 162, 235',
        yellow: '238,215,161',
        pink: '255,102,136',
        orange: '223,145,82',
        Black: '59,68,75',
        sky: '80,184,231',
        Purple: '153, 102, 255',
        Brown: '163,87,58',
        Gray: '101,119,134'
    };
    return colorMap[color] || '0, 0, 0';
}

export const hours: { value: number, key: string }[] = [
    { value: 1, key: '01:00 am' }, { value: 2, key: '02:00 am' }, { value: 3, key: '03:00 am' }, { value: 4, key: '04:00 am' },
    { value: 5, key: '05:00 am' }, { value: 6, key: '06:00 am' }, { value: 7, key: '07:00 am' }, { value: 8, key: '08:00 am' },
    { value: 9, key: '09:00 am' }, { value: 10, key: '10:00 am' }, { value: 11, key: '11:00 am' }, { value: 12, key: '12:00 am' },
    { value: 13, key: '01:00 pm' }, { value: 14, key: '02:00 pm' }, { value: 15, key: '03:00 pm' }, { value: 16, key: '04:00 pm' },
    { value: 17, key: '05:00 pm' }, { value: 18, key: '06:00 pm' }, { value: 19, key: '07:00 pm' }, { value: 20, key: '08:00 pm' },
    { value: 21, key: '09:00 pm' }, { value: 22, key: '10:00 pm' }, { value: 23, key: '11:00 pm' }, { value: 24, key: '12:00 pm' }
];
export const weeklyDay: { _id: string, name: string }[] = [
    { _id: 'SUN', name: 'SUN' },
    { _id: 'Mon', name: 'Mon' },
    { _id: 'TUE', name: 'TUE' },
    { _id: 'WED', name: 'WED' },
    { _id: 'THU', name: 'THU' },
    { _id: 'FRI', name: 'FRI' },
    { _id: 'SAT', name: 'SAT' }
]
