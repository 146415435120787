import { useContext, type FC, useState, useEffect } from 'react';
import { DataContext } from '../../../Context/DataProvider';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputSelect from '../../../Component/FormControl/InputSelect';
import InputText from '../../../Component/FormControl/InputText';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import CancelButton from '../../../Component/FormControl/CancelButton';
import InputNumber from '../../../Component/FormControl/InputNumber';
import { useLocation, useNavigate } from 'react-router-dom';

interface BillingAddressFormProps {
    setIsLoading?: any
    setCompletedTabs?: any
    setIsActive?: any
    completdTab?: string
}

interface editBillingAddress {
    company: { _id: string }
    attention: string
    addressLine: string
    city: string
    zipCode: string
    country: { _id: string }
    state: { _id: string }
    _id?: string
}

const BillingAddressForm: FC<BillingAddressFormProps> = ({ setIsLoading, setCompletedTabs, setIsActive, completdTab }) => {
    const { pathname } = useLocation()
    const exactPath = pathname.split('/')[3]
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const [editBillingAddress, setEditBillingAddress] = useState<editBillingAddress | null>(null)
    const [countries, setCountries] = useState<any[]>([])
    const [selectedCountry, setSelectedCountry] = useState<string>('')
    const companyOnbord = sessionStorage.getItem('companyOnbord');
    const [states, setStates] = useState<any[]>([])
    const navigate = useNavigate()


    const handlePrevious = () => {
        setIsActive('commercial_details')
    }
    const initialValues = {
        attention: '',
        addressLine: '',
        city: '',
        zipCode: '',
        country: '',
        state: ''
    };

    const fetchCountries = async () => {
        try {
            const response: responseType = await http({
                url: `/country/getCountryForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCountries(response?.data?.data);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching Roles.');
            }
        }
    };
    const fetchStates = async () => {
        try {
            const response: responseType = await http({
                url: `/state/getStateForDD`,
                method: 'get',
                data: { country: selectedCountry }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setStates(response?.data?.data);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching Roles.');
            }
        }
    };
    const fetchBillingAddress = async () => {
        try {
            const response: responseType = await http({
                url: `/billingAddress/getBillingAddressForEdit`,
                method: 'get',
                data: { 'company_id': companyOnbord }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditBillingAddress(response?.data?.data);
                setIsLoading(false)
                setSelectedCountry(response?.data?.data?.country?._id);
                initialValues.attention = response?.data?.data?.attention;
                initialValues.zipCode = response?.data?.data?.zipCode;
                initialValues.addressLine = response?.data?.data?.addressLine;
                initialValues.city = response?.data?.data?.city;
                initialValues.country = response?.data?.data?.country?._id;
                initialValues.state = response?.data?.data?.state?._id;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching billing address.');
            }
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (completdTab?.includes('billing_address')) {
            fetchBillingAddress();
        }
        fetchCountries();
        // eslint-disable-next-line 
    }, []);
    useEffect(() => {
        if (selectedCountry !== '') {
            fetchStates();
        }
        // eslint-disable-next-line 
    }, [selectedCountry]);

    const validationSchema = Yup.object().shape({
        attention: Yup.string().required('Attention is required').min(3, 'Too short name').max(50, 'Too long name'),
        zipCode: Yup.string().matches(/^\d{6}$/, 'Zip code must be exactly 6 numbers').required('zipCode is required'),
        city: Yup.string().required('City is required'),
        addressLine: Yup.string().required('AddressLine is required'),
        country: Yup.string().required('Country is required').min(1, 'Country is required'),
        state: Yup.string().required('State is required').min(1, 'State is required'),
    });

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        values['company'] = companyOnbord;
        if (editBillingAddress !== null) {
            values['_id'] = editBillingAddress._id;
            try {
                const response: responseType = await http({
                    url: `/billingAddress/editBillingAddress`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    if (exactPath === 'editCompany') {
                        setTimeout(() => {
                            setCompletedTabs('billing_address')
                            setButtonLoader(false)
                            resetForm()
                            sessionStorage.removeItem('completdTab')
                            sessionStorage.removeItem('activeTab')
                            sessionStorage.removeItem('companyOnbord')
                            navigate('/home/configuration/company')
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            setButtonLoader(false)
                            setCompletedTabs('billing_address')
                            setIsActive('user')
                        }, 1000);
                    }
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error editing billing address.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/billingAddress/addBillingAddress`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        setCompletedTabs('billing_address')
                        setIsActive('user')
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding billing address.');
                }
            }
        }
    };
    const handleCountryChange = (e: any, setFieldValue: any) => {
        setSelectedCountry(e.target.value)
        setFieldValue('country', e.target.value);
    };
    return (
        <div className='w-full relative'>
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue }) => (
                        <Form className='flex flex-col gap-4 p-3'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                <div className="relative z-0 w-full group">
                                    <Field
                                        id='country'
                                        name='country'
                                        onChange={(e: any) => handleCountryChange(e, setFieldValue)}
                                        className="block p-2 w-full text-sm  rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer" as="select">
                                        <option value="">Select Country</option>
                                        {countries?.map((item: any) => (<option key={item?._id} value={item?._id}>{item.name}</option>))}
                                    </Field>
                                    <label htmlFor='country' className='peer-focus:font-medium absolute text-gray-400 peer-focus:text-gray-800 font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet  z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>Country <span className={`text-red-500`}>*</span></label>
                                    <span className="text-red-500 text-sm"><ErrorMessage name='country' /></span>
                                </div>
                                <InputSelect name='state' id='state' label='State' Array={states} />
                                <InputText name='attention' id='attention' label='Attention' />
                                <InputText name='addressLine' id='addressLine' label='Address Line' />
                                <InputText name='city' id='city' label='City' />
                                <InputNumber name='zipCode' id='zipCode' label='Zip Code' />
                            </div>
                            <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                                <CancelButton onClick={handlePrevious} label='Previous' />
                                {buttonLoader ? <ButtonLoader /> : <SubmitButton label='Save & Next' />}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default BillingAddressForm;
