import { Formik, Form } from 'formik';
import { type FC, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import http from '../../Services/http/http';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import { toast } from 'react-toastify';
import Loader from '../../Component/Common/Loader/Loader';
import InputText from '../../Component/FormControl/InputText';
import CancelButton from '../../Component/FormControl/CancelButton';
import SubmitButton from '../../Component/FormControl/SubmitButton';
import ImageUploadInput from '../../Component/FormControl/ImageUploadInput';
import { RxCross2 } from 'react-icons/rx';
import { DataContext } from '../../Context/DataProvider';
import MyCarousel from '../../Component/Common/Carousel/Carousel';
import noImage from '../../Assets/noimageavailable.jpg'
import Card from '../../Component/Common/Card/Card';
import arrayOfDefault from '../../ThemeData/Default';
import arrayOfTheme1 from '../../ThemeData/Theme1';
import arrayOfTheme2 from '../../ThemeData/Theme2';
import arrayOfTheme3 from '../../ThemeData/Theme3';
import arrayOfTheme4 from '../../ThemeData/Theme4';
import arrayOfTheme5 from '../../ThemeData/Theme5';
import FileUpload from "../../Services/file_upload";
import ButtonLoader from '../../Component/Common/Loader/ButtonLoader';

interface ProfileFormProps { }

const ProfileForm: FC<ProfileFormProps> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate()
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '[]');
    const { language, setLanguage, setThemes, themes, buttonLoader, setButtonLoader } = useContext(DataContext)
    const [uploadedImage, setUploadedImage] = useState<{ _id: string, url: string, mimetype: string } | null>(userDetails.profileImage)

    const initialValues = {
        name: userDetails.name,
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
    });

    const handleImageUpload = async (file: File) => {
        try {
            let data: any = await FileUpload(file);
            setUploadedImage(data);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error uploading image.');
            }
        }
    };

    const handleDeleteImage = async () => {
        try {
            const response: responseType = await http({
                url: `/media/deleteMediaPermanent`,
                method: 'delete',
                data: { _id: uploadedImage?._id },
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setUploadedImage(null)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting image');
            }
        }
    }

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        values['profileImage'] = uploadedImage?._id ? uploadedImage?._id : null;
        values['_id'] = userDetails?._id;
        values['language'] = language;
        values['theme'] = themes;
        try {
            const response: responseType = await http({
                url: `/user/editPersonalDetails`,
                method: 'put',
                data: values
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                sessionStorage.setItem('userDetails', JSON.stringify(response.data.data))
                setTimeout(() => {
                    setButtonLoader(false)
                    navigate('/home/dashboard');
                    resetForm();
                }, 1000);
            } else {
                setButtonLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            setButtonLoader(false)
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error updating personal details.');
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
        // eslint-disable-next-line
    }, []);

    return (
        <div className='w-full h-full relative overflow-auto scrollbar-thin'>
            <Loader loader={isLoading} />
            <div className="w-full h-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4'>
                        <div className="w-full flex gap-2 justify-end sticky top-0 z-20 bg-skin-fill-outlet py-2">
                            <CancelButton onClick={() => navigate('/home/dashboard')} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton />}
                        </div>
                        <div className='w-32 h-32 relative'>
                            {uploadedImage !== null ?
                                <div className="w-32 h-32 rounded-md border border-dotted group">
                                    <span onClick={handleDeleteImage} className='hidden w-5 h-5 group-hover:flex items-center justify-center absolute cursor-pointer -top-2 -right-2 rounded-full bg-gray-200 text-gray-700 text-sm border border-black'><RxCross2 /></span>
                                    <img src={uploadedImage?.url ?? noImage} alt={uploadedImage?.mimetype ?? 'img'} className='w-full h-full' />
                                </div>
                                :
                                <ImageUploadInput disabled={uploadedImage !== null ? true : false} onImageUpload={handleImageUpload} />
                            }
                        </div>
                        <InputText name='name' id='name' label='Name' />
                        <div className="relative">
                            <select id="language" onChange={(e) => setLanguage(e.target.value)} value={language}
                                className="block py-1.5 px-2 w-full text-sm rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-secondary focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer">
                                <option value='en' disabled>Choose Language</option>
                                <option value="en">English</option>
                                <option value="hi">Hindi</option>
                            </select>
                            <label htmlFor='language' className='peer-focus:font-medium absolute text-gray-400  font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-1.5 left-2 bg-skin-fill-outlet  z-10 origin-[0] peer-focus:left-2 peer-focus:text-secondary peer-focus:dark:text-seconborder-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4'>Language</label>
                        </div>
                        <p>Choose Theme</p>
                        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2 mb-12'>
                            <div className={`${themes === 'default' ? ' border-gray-800 ' : 'border-gray-300'} h-60 border bg-skin-fill-card p-2 rounded-md relative`}>
                                <Card title={'default'} description={'default theme'} body={<MyCarousel items={arrayOfDefault} />} />
                                <span onClick={() => setThemes('default')} className='absolute right-3 bottom-6 px-2 py-0.5 text-sm border border-skin-table-color bg-skin-fill-iconBg rounded-md cursor-pointer hover:bg-skin-fill-profile-btn-hover text-skin-base'>Apply</span>
                            </div>
                            <div className={`${themes === 'theme1' ? ' border-gray-800 ' : 'border-gray-300'} h-60 border bg-skin-fill-card p-2 rounded-md relative`}>
                                <Card title={'Theme 1'} description={'blue theme'} body={<MyCarousel items={arrayOfTheme1} />} />
                                <span onClick={() => setThemes('theme1')} className='absolute right-3 bottom-6 px-2 py-0.5 text-sm border border-skin-table-color bg-skin-fill-iconBg rounded-md cursor-pointer hover:bg-skin-fill-profile-btn-hover text-skin-base'>Apply</span>
                            </div>
                            <div className={`${themes === 'theme2' ? ' border-gray-800 ' : 'border-gray-300'} h-60 border bg-skin-fill-card p-2 rounded-md relative`}>
                                <Card title={'Theme 2'} description={'purple theme'} body={<MyCarousel items={arrayOfTheme2} />} />
                                <span onClick={() => setThemes('theme2')} className='absolute right-3 bottom-6 px-2 py-0.5 text-sm border border-skin-table-color bg-skin-fill-iconBg rounded-md cursor-pointer hover:bg-skin-fill-profile-btn-hover text-skin-base'>Apply</span>
                            </div>
                            <div className={`${themes === 'theme3' ? ' border-gray-800 ' : 'border-gray-300'} h-60 border bg-skin-fill-card p-2 rounded-md relative`}>
                                <Card title={'Theme 3'} description={'greenish theme'} body={<MyCarousel items={arrayOfTheme3} />} />
                                <span onClick={() => setThemes('theme3')} className='absolute right-3 bottom-6 px-2 py-0.5 text-sm border border-skin-table-color bg-skin-fill-iconBg rounded-md cursor-pointer hover:bg-skin-fill-profile-btn-hover text-skin-base'>Apply</span>
                            </div>
                            <div className={`${themes === 'theme4' ? ' border-gray-800 ' : 'border-gray-300'} h-60 border bg-skin-fill-card p-2 rounded-md relative`}>
                                <Card title={'Theme 4'} description={'light theme'} body={<MyCarousel items={arrayOfTheme4} />} />
                                <span onClick={() => setThemes('theme4')} className='absolute right-3 bottom-6 px-2 py-0.5 text-sm border border-skin-table-color bg-skin-fill-iconBg rounded-md cursor-pointer hover:bg-skin-fill-profile-btn-hover text-skin-base'>Apply</span>
                            </div>
                            <div className={`${themes === 'theme5' ? ' border-gray-800 ' : 'border-gray-300'} h-60 border bg-skin-fill-card p-2 rounded-md relative`}>
                                <Card title={'Theme 5'} description={'dark theme'} body={<MyCarousel items={arrayOfTheme5} />} />
                                <span onClick={() => setThemes('theme5')} className='absolute right-3 bottom-6 px-2 py-0.5 text-sm border border-skin-table-color bg-skin-fill-iconBg rounded-md cursor-pointer hover:bg-skin-fill-profile-btn-hover text-skin-base'>Apply</span>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default ProfileForm;
