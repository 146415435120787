import { useState, type FC, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import InputText from '../../../Component/FormControl/InputText';
import InputSelect from '../../../Component/FormControl/InputSelect';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import { useParams } from 'react-router-dom';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import Loader from '../../../Component/Common/Loader/Loader';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import { DataContext } from '../../../Context/DataProvider';
import { technologys } from '../../../Component/FormControl/AllAssetTypeMaster';
import { unitType } from '../../../Component/FormControl/unitType';

interface MonitoringFormProps { }
interface ImonitoringType {
    name: String
    // code: String
    type?: any
    _id?: any
}

interface monitoringTypeEdit {
    name: string
    code: string
    _id?: string
    type: string
}

const MonitoringForm: FC<MonitoringFormProps> = () => {
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const [monitoringTypeEdit, setMonitoringTypeEdit] = useState<monitoringTypeEdit | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate()
    const { id } = useParams();

    const initialValues = {
        name: '',
        // code: '',
        type: "",
        technology: ''
    };

    const fetchMonitoringType = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringType/getMonitoringTypeForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setMonitoringTypeEdit(response?.data?.data);
                initialValues.name = response?.data?.data.name;
                initialValues.technology = response?.data?.data?.technology;
                // initialValues.code = response?.data?.data.code;
                initialValues.type = response?.data?.data.type;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching monitoring type.');
            }
        }
    };


    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').min(3, 'Too short name').max(50, 'Too long name'),
        // code: Yup.string().required('Code is required'),
        type: Yup.string().required('Type is required'),
        technology: Yup.string().required('Technology is required'),

    });

    const handleSubmit = async (values: ImonitoringType, { resetForm }: any) => {
        setButtonLoader(true)
        if (monitoringTypeEdit !== null) {
            values['_id'] = monitoringTypeEdit._id;
            try {
                const response: responseType = await http({
                    url: `/monitoringType/editMonitoringType`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/monitoringtype');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error editing monitoring type.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/monitoringType/addMonitoringType`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/monitoringtype');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding monitoring type.');
                }
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (id !== undefined) {
            fetchMonitoringType();
        }
        // eslint-disable-next-line 
    }, []);
    return (
        <div className='w-full h-full relative'>
            <div className="w-full px-2 sticky top-0 z-30 bg-skin-fill-formheader">
                <p className='py-1 font-medium text-skin-base text-lg'>{monitoringTypeEdit == null ? 'Add' : 'Edit'} Monitoring Type</p>
                <hr />
            </div>
            <Loader loader={isLoading} />
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4 p-3'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            <InputText name='name' id='name' autoFocus={true} label='Name' />
                            <InputSelect Array={unitType} label='Type' name='type' id='type' />
                            <InputSelect Array={technologys} label='technology' name='technology' id='technology' />
                            {/* <InputText name='code' id='code' disabled={monitoringTypeEdit !== null ? true : false} label='Code' /> */}
                        </div>
                        <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                            <CancelButton onClick={() => navigate('/home/configuration/monitoringtype')} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton label={monitoringTypeEdit == null ? 'Add Monitoring Type' : 'Edit Monitoring Type'} />}
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default MonitoringForm;
