import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../Assets/logoText.jpeg'

interface ListViewProps {
    List: any[] | null
    searchFilter: string
    skip: number
    route: string
}

const ListView: FC<ListViewProps> = ({ List, searchFilter, skip, route }) => {
    const navigate = useNavigate();
    return (
        <div className="w-full h-full overflow-auto scrollbar-thin ">
            <table className="w-full mb-2 text-left ">
                <thead className="  border-l-white px-6  bg-skin-fill-table-head text-skin-base-table-head-text z-10  sticky top-0">
                    <tr>
                        <th scope="col" className="px-4 py-3 font-medium truncate">
                            S.No.
                        </th>
                        <th scope="col" className="px-4 py-3 bg-skin-fill-table-head font-medium truncate">
                            Logo
                        </th>
                        <th scope="col" className="px-4 py-3 bg-skin-fill-table-head font-medium truncate">
                            Company Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {List?.map((company, index) =>
                        <tr onClick={() => navigate(`/home/${route}/${company?._id}`)} key={company._id} className="bg-skin-fill-table hover:bg-skin-fill-hover hover:text-skin-base-table-hover hover:bg-opacity-10 text-skin-base-table-body-text border-b border-skin-table-color group">
                            <td className="px-4 py-[8px]">
                                {searchFilter ? (index + 1) : (skip + (index + 1))}
                            </td>
                            <td>
                                <img src={company?.logo ? company?.logo?.url : logo} className='w-7 h-7 rounded-full' alt="" />
                            </td>
                            <td className="sticky left-0 group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px] truncate">
                                {company?.name}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default ListView;
