import type { FC } from 'react';
import EditCompanyAssetForm from '../../../Pages/CompanyAsset/EditCompanyAssetForm';
import MonitoringLogForm from '../../../Pages/CompanyAsset/MonitoringLogForm';


interface PopUpProps {
    openPopUp: boolean
    setOpenPopUp: React.Dispatch<React.SetStateAction<boolean>>
    active: string
}

const PopUp: FC<PopUpProps> = ({ openPopUp, setOpenPopUp, active }) => {
    const childClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation(); // Prevent the event from propagating to the parent
    }
    return (
        <div onClick={() => setOpenPopUp(false)} className={`${openPopUp ? 'fixed left-0 top-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50' : 'hidden'}`}>
            <div onClick={(event) => childClick(event)} className='sm:w-[350px] sm:mx-0 mx-5 w-full h-auto bg-white rounded-lg px-3 pt-4 shadow-lg  max-h-[95%] overflow-y-scroll scrollbar-thin'>
                {(openPopUp === true && active === 'editAssetForm') && <EditCompanyAssetForm setOpenPopUp={setOpenPopUp} openPopUp={openPopUp} />}
                {(openPopUp === true && active === 'monitorinLogForm') && <MonitoringLogForm setOpenPopUp={setOpenPopUp} openPopUp={openPopUp}/>}
            </div>
        </div>
    );
}

export default PopUp;
