import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en_title from './locales/en/title.json'
import hi_title from './locales/hi/title.json'
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                title: en_title
            },
            hi: {
                title: hi_title
            }
        },
        fallbackLng: "en",
        ns: ["title"],
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });



export default i18n;