import { createContext, useState } from "react";
import { IUserPermission } from "../Component/Common/TypesAndInterfaces/TypesAndInterfaces";
import { Socket } from "socket.io-client";
interface IAssetEdit {
  _id?: string
  name?: string
  serialnumber?: string
  profiling: any
  images: any
  profilingkeys: any
  plant?: string,
  segment?: string
}
type DataContextValue = {
  isRender: boolean
  setIsRender: React.Dispatch<React.SetStateAction<boolean>>
  buttonLoader: boolean
  setButtonLoader: React.Dispatch<React.SetStateAction<boolean>>
  isVisible: Function
  assetDataForEdit: IAssetEdit | null
  setAssetDataForEdit: React.Dispatch<React.SetStateAction<IAssetEdit | null>>
  socket: Socket | undefined
  setSocket: React.Dispatch<React.SetStateAction<Socket | undefined>>
  assetForLog: any | null
  setAssetForLog: React.Dispatch<React.SetStateAction<any | null>>
  language: string
  setLanguage: React.Dispatch<React.SetStateAction<string>>
  themes: string
  setThemes: React.Dispatch<React.SetStateAction<string>>
  dateFilter: string | null
  setDateFilter: React.Dispatch<React.SetStateAction<string | null>>
  selectedLimit: number
  setSelectedLimit: React.Dispatch<React.SetStateAction<number>>
  sideNavCollapse:boolean
  setSideNavCollapse:React.Dispatch<React.SetStateAction<boolean>>
  notificationUpdate:boolean
  setNotificationUpdate:React.Dispatch<React.SetStateAction<boolean>>
};

export const DataContext = createContext<DataContextValue>({} as DataContextValue);
type DataProviderProps = {
  children: React.ReactNode;
};

const DataProvider = ({ children }: DataProviderProps) => {
  const [isRender, setIsRender] = useState(false);
  const [sideNavCollapse, setSideNavCollapse] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [assetDataForEdit, setAssetDataForEdit] = useState<IAssetEdit | null>(null)
  const [socket, setSocket] = useState<Socket>();
  const [assetForLog, setAssetForLog] = useState<any | null>(null)
  const [selectedLimit, setSelectedLimit] = useState<number>(10)
  const [language, setLanguage] = useState<string>('');
  const [themes, setThemes] = useState('');
  const [notificationUpdate,setNotificationUpdate]=useState(false)
  const [dateFilter, setDateFilter] = useState<string | null>(null)

  const isVisible = (code: string): boolean => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    if (userDetails?.role?.code === 'superadmin') {
      return true;
    }
    const userPermissions: IUserPermission[] = JSON.parse(sessionStorage.getItem('permissions') || '[]');
    return userPermissions.find((permission: IUserPermission) => `${permission?.controller}-${permission?.action}` === code) ? true : false;
  };

  const value: DataContextValue = {
    socket,
    setSocket,
    isVisible,
    isRender,
    setIsRender,
    assetDataForEdit,
    setAssetDataForEdit,
    assetForLog,
    setAssetForLog,
    language,
    setLanguage,
    themes,
    setThemes,
    dateFilter,
    setDateFilter,
    buttonLoader,
    setButtonLoader,
    selectedLimit,
    setSelectedLimit,
    sideNavCollapse,
    setSideNavCollapse,
    notificationUpdate,setNotificationUpdate
  };
  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};
export default DataProvider;