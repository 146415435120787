import type { FC } from 'react';
import InputText from '../../../../Component/FormControl/InputText';
import { FieldArray } from 'formik';
import ImageUploadInput from '../../../../Component/FormControl/ImageUploadInput';
import { RiDeleteBin4Line } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';
import { FaPlusCircle } from 'react-icons/fa';
import SegmentsForm from './SegmentsForm';

interface PlantsFormProps {
    values: any
    handlePdfUpload: Function,
    handleDeleteFile: Function
}

const PlantsForm: FC<PlantsFormProps> = ({ values, handleDeleteFile, handlePdfUpload }) => {
    return (
        <FieldArray name="plants">
            {({ remove, push }: { remove: (index: number) => void, push: (value: any) => void }) => (
                <>
                    {values.plants.length > 0 ? values?.plants?.map((plant: any, plantIndex: number) => (
                        <div key={plantIndex} className='w-full'>
                            <h2>Plant: {plantIndex + 1}</h2>
                            <div className="mb-2 w-full flex gap-8">
                                <div className='relative group w-[80%] flex flex-col gap-3'>
                                    <InputText name={`plants.${plantIndex}.name`} id={`plants.${plantIndex}.name`} label={`plants.${plantIndex + 1}.name`} />
                                    <InputText name={`plants.${plantIndex}.gstNumber`} id={`plants.${plantIndex}.gstNumber`} label='GST number' />
                                    <InputText name={`plants.${plantIndex}.location`} id={`plants.${plantIndex}.location`} label='Location' />
                                    <div className='w-full relative'>
                                        {plant?.gstCertificate ?
                                            <div className="w-full flex items-center justify-start gap-5">
                                                <p className='truncate w-40'>{plant?.gstCertificate?.url}</p>
                                                <p className={plant?.gstCertificate ? 'text-green-500' : 'text-gray-700'}>{plant?.gstCertificate ? 'File Uploaded' : 'Uploade File'}</p>
                                                <span onClick={() => handleDeleteFile(plant?.gstCertificate?._id, values, plantIndex)} className='w-5 h-5 flex justify-center items-center cursor-pointer rounded-full bg-gray-200 text-gray-700 text-sm border border-black'><RxCross2 /></span>
                                            </div>
                                            :
                                            <ImageUploadInput disabled={plant?.gstCertificate ? true : false} onImageUpload={(file) => handlePdfUpload(file, values, plantIndex)} lable='GST Certificate' />
                                        }
                                    </div>
                                    <div onClick={() => remove(plantIndex)} className='absolute right-5 top-2.5 group-hover:block text-xl hidden text-red-600 cursor-pointer'><RiDeleteBin4Line />
                                    </div>
                                </div>
                                {plantIndex === values.plants.length - 1 && (
                                    <div title='Add Plant' onClick={() => push({ name: '', gstNumber: '', gstCertificate: undefined, segments: [] })} className={` text-green-600 text-2xl cursor-pointer`}><FaPlusCircle /></div>
                                )}
                            </div>
                            {/* FieldArray for segments inside the FieldArray for plants */}
                            <SegmentsForm plant={plant} plantIndex={plantIndex} />
                        </div>
                    ))
                        :
                        <p onClick={() => push({ name: '', gstNumber: '', gstCertificate: undefined, segments: [] })} className='text-white text-sm flex flex-col justify-center items-center w-24 py-2 cursor-pointer border rounded-md bg-skin-button-bgcolor'>Add Plant</p>
                    }
                </>
            )}
        </FieldArray>
    );
}

export default PlantsForm;
