import { IoMdNotificationsOutline } from "react-icons/io"
import { LuUser } from "react-icons/lu"
import { RiDashboard3Line } from "react-icons/ri"
import { TbDeviceImacHeart } from "react-icons/tb"
import { NavLink } from "react-router-dom"
import BottomNavPopUp from "./BottomNavPopUp"
import { FiSettings } from "react-icons/fi"
import { useContext, useEffect, useState } from "react"
import { DataContext } from "../../../Context/DataProvider"
import { responseType } from "../TypesAndInterfaces/TypesAndInterfaces"
import http from "../../../Services/http/http"
import { toast } from "react-toastify"

const BottomNav = () => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    const { isVisible } = useContext(DataContext)
    const [ntfcsCount, setNtfcsCount] = useState(0);
    const getNotificationCount = async () => {
        try {
            const response: responseType = await http({
                url: `/notification/getNotificationCount`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setNtfcsCount(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching notification count.');
            }
        }
    }
    useEffect(() => {
        getNotificationCount();
    }, [])
    return (
        <div className="w-full  ">
            <div className="z-50 h-14 w-full bg-skin-fill border-t border-gray-200">
                <div className="h-full max-w-lg flex justify-evenly items-center mx-auto font-medium">
                    <NavLink to="/home/dashboard" title="Dashboard" className={({ isActive, }) => `${isActive ? 'text-skin-active-subnav' : 'text-skin-base'} text-[26px] cursor-pointer inline-flex items-center justify-center px-2 group`}>
                        <RiDashboard3Line />
                    </NavLink>
                    {isVisible('user-read') &&
                        <NavLink to={userDetails?.role?.code === 'superadmin' ? 'company/U' : 'user'} title='User' className={({ isActive, }) => `${isActive ? 'text-skin-active-subnav' : 'text-skin-base'} text-[26px] cursor-pointer inline-flex items-center justify-center px-2 group`}>
                            <LuUser />
                        </NavLink>}
                    {isVisible('user-read') &&
                        <NavLink to={userDetails?.role?.code === 'superadmin' ? 'company/A' : 'companyAsset'} title='CompanyAsset' className={({ isActive, }) => `${isActive ? 'text-skin-active-subnav' : 'text-skin-base'} text-[26px] cursor-pointer inline-flex items-center justify-center px-2 group`}>
                            <TbDeviceImacHeart />
                        </NavLink>}
                    {isVisible('user-read') &&
                        <NavLink to="/home/configuration" title='Configuration' className={({ isActive, }) => `${isActive ? 'text-skin-active-subnav' : 'text-skin-base'} text-[26px] cursor-pointer inline-flex items-center justify-center px-2 group`}>
                            <FiSettings />
                        </NavLink>}
                    {isVisible('user-read') &&
                        <NavLink to="/home/notifications" title='Notification' className={({ isActive, }) => `${isActive ? 'text-skin-active-subnav' : 'text-skin-base'} text-[26px] relative cursor-pointer inline-flex items-center justify-center px-2 group`}>
                            <IoMdNotificationsOutline />
                            <span className='absolute top-0 right-1 bg-gray-900 w-4 h-4 rounded-full flex items-center justify-center text-sm text-white'>{ntfcsCount}</span>
                        </NavLink>}
                    <BottomNavPopUp />
                </div>
            </div>
        </div>
    )
}

export default BottomNav