import { useState, type FC, Dispatch, SetStateAction, useEffect } from 'react';
import PieChart from '../../../Component/Common/Charts/PieChart';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';

interface PieChartData2Props {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const PieChartData2: FC<PieChartData2Props> = ({ setLoading }) => {
    const [companyCountStateWiseForGraph, setCounpanyCountStateWiseForGraph] = useState<any[]>([])
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '');
    const fetchAssetCountStateWiseForGraph = async () => {
        try {
            const response: responseType = await http({
                url: `/billingAddress/companyCountStateWiseForGraph`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                const data = await response?.data?.data;
                setCounpanyCountStateWiseForGraph(data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company asset count');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code === 'superadmin') {
            fetchAssetCountStateWiseForGraph();
        }
        // eslint-disable-next-line 
    }, [])
    return (
        <div className='w-full shadow-lg h-[440px] flex flex-col bg-white rounded-lg p-2 font-sans'>
            <h1 className='w-full self-start text-sm border-b'>Company count state wise</h1>
            <div className="w-full h-[400px] flex items-center justify-center">
                {companyCountStateWiseForGraph && companyCountStateWiseForGraph.length !== 0 ? <PieChart data={companyCountStateWiseForGraph} title='Companies' /> : <span className='text-sm flex items-center justify-center'>No company found.</span>}
            </div>
        </div>
    );
}

export default PieChartData2;
