import { useContext, type FC } from 'react';
import { DataContext } from '../../Context/DataProvider';


interface CounterProps {
    skip: number
    counts: number
    limit: number
}

const Counter: FC<CounterProps> = ({ skip, counts, limit }) => {
    const { setSelectedLimit } = useContext(DataContext)
    const paginationLimit: number[] = []
    for (let index = 0; index <= parseInt((counts / 10).toString()); index++) {
        if(index < parseInt((counts / 10).toString())){
            paginationLimit.push((index + 1) * 10);
        }else if(index === parseInt((counts / 10).toString())){
            paginationLimit.push(Number(counts))
        }
    }
    return (
        <div className="sm:text-sm text-xs flex items-center gap-2">
            {counts > 0 &&
                <div className='border px-2 py-1 text-gray-700 flex items-center gap-2'>
                    <span>Showing</span>
                    <select className='focus:outline-none bg-transparent text-black border px-1.5 cursor-pointer' onChange={(e: any) => setSelectedLimit(Number(e.target.value))} id="selectLimit" name="selectLimit">
                        {paginationLimit?.map((limit: number, index: number) => <option key={index} value={limit}>{limit}</option>)}
                    </select>
                    <p> {(counts > 0) ? (skip + 1) : 0}-{((skip + 1) * limit) > counts ? counts : (skip + 1) * limit} of {counts}</p>
                </div>
            }
        </div>
    );
}

export default Counter;
