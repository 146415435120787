import { FC, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../Auth/Login/Login';
import Protected from './Protected/Protected';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Configuration from '../Pages/Configuration/Configuration';
import AddRoleForm from '../Pages/Configuration/Roles/AddRoleForm';
import RolesTable from '../Pages/Configuration/Roles/RolesTable';
import Company from '../Pages/Configuration/Company/Company';
import CompanyAdmin from '../Pages/Configuration/CompanyAdmin/CompanyAdmin';
import Home from '../Home/Home'
import CompanyAdminForm from '../Pages/Configuration/CompanyAdmin/CompanyAdminForm';
import Asset from '../Pages/Configuration/Asset/Asset';
import AssetForm from '../Pages/Configuration/Asset/AssetForm';
import AssetType from '../Pages/Configuration/AssetType/AssetType';
import AssetTypeForm from '../Pages/Configuration/AssetType/AssetTypeForm';
import MonitoringType from '../Pages/Configuration/MonitoringType/MonitoringType';
import MonitoringForm from '../Pages/Configuration/MonitoringType/MonitoringForm';
import Unit from '../Pages/Configuration/Unit/Unit';
import UnitForm from '../Pages/Configuration/Unit/UnitForm';
import AssetMnitoringForm from '../Pages/CompanyAsset/AssetMonitoringForm';
// import NotificationType from '../Pages/Configuration/NotificationType/NotificationType';
// import NotificationTypeForm from '../Pages/Configuration/NotificationType/NotificationTypeForm';
import CompanyAsset from '../Pages/CompanyAsset/CompanyAsset';
import User from '../Pages/User/User';
import UserForm from '../Pages/User/UserForm';
import NotFound from '../Component/Common/NotFound/NotFound';
import CompanyList from '../Component/Common/CompanyList/CompanyList';
import { DataContext } from '../Context/DataProvider';
import AccessDenied from '../Component/Common/AccessDenied/AccessDenied';
import ProfileForm from '../Pages/Profile/ProfileForm';
import MonitoringLog from '../Pages/MonitoringLog/MonitoringLog';
import Notification from '../Pages/Notification/Notification';
import NotificationDetail from '../Pages/Notification/NotificationDetail';
import State from '../Pages/Configuration/State/State';
import StateForm from '../Pages/Configuration/State/StateForm';
import Country from '../Pages/Configuration/Country/Country';
import CountryForm from '../Pages/Configuration/Country/CountryForm';
import ProblemSolution from '../Pages/Configuration/ProblemSolution/ProblemSolution';
import ProblemSolutionForm from '../Pages/Configuration/ProblemSolution/ProblemSolutionForm';
import Plant from '../Pages/Configuration/Plant/Plant';
import Segment from '../Pages/Configuration/Segment/Segment';
import LogDetails from '../Pages/CompanyAsset/LogProgress';
import BillingAddressForm from '../Pages/Configuration/BillingAddress/BillingAddressForm';
import CommercialDetails from '../Pages/Configuration/CommercialDetails/CommercialDetails';
import CommercialDetailsForm from '../Pages/Configuration/CommercialDetails/CommercialDetailsForm';
import BillingAddress from '../Pages/Configuration/BillingAddress/BillingAddress';
import Onbording from '../Pages/Configuration/Company/Onbording';
import OnbordingDetails from '../Pages/Configuration/Company/OnbordingDetails';
import CompanyAssetDetailsNew from '../Pages/CompanyAsset/CompanyAssetDetailsNew';
import CompanyAssetForm from '../Pages/CompanyAsset/CompanyAssetForm';


interface RouterProps { }

const Router: FC<RouterProps> = () => {
    const { isVisible } = useContext(DataContext)
    return (
        <>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='*' element={<NotFound />} />
                <Route path="/home" element={<Protected Component={Home} />} >
                    <Route path='editProfile' element={<ProfileForm />} />
                    <Route path='notifications' element={<Notification />} />
                    <Route path='notificationDetail/:notificationId' element={<NotificationDetail />} />
                    <Route path='dashboard' element={<Dashboard />} />
                    <Route path='company/U' element={isVisible('user-read') ? <CompanyList route='companyUser' /> : <AccessDenied />} />
                    <Route path='company/A' element={isVisible('companyasset-read') ? <CompanyList route='companyAsset' /> : <AccessDenied />} />
                    <Route path='monitoringLog/:companyAsset' element={<MonitoringLog />} />
                    <Route path='user' element={isVisible('user-read') ? <User /> : <AccessDenied />} />
                    <Route path='addUser' element={isVisible('user-create') ? <UserForm /> : <AccessDenied />} />
                    <Route path='companyUser/:company' element={isVisible('user-read') ? <User /> : <AccessDenied />} />
                    <Route path='editUser/:id' element={isVisible('user-update') ? <UserForm /> : <AccessDenied />} />
                    <Route path='companyAsset' element={isVisible('companyasset-read') ? <CompanyAsset /> : <AccessDenied />} />
                    <Route path='logprogress/:companyAssetId' element={isVisible('companyasset-read') ? <LogDetails /> : <AccessDenied />} />
                    <Route path='companyAsset/:company' element={isVisible('companyasset-read') ? <CompanyAsset /> : <AccessDenied />} />
                    {/* <Route path='companyAsset/:company/:asset' element={<CompanyAssetDetails />} /> */}
                    <Route path='companyAsset/:company/:assetType' element={<CompanyAssetDetailsNew />} />
                    <Route path='addCompanyAsset' element={isVisible('companyasset-create') ? <CompanyAssetForm /> : <AccessDenied />} />
                    <Route path='editCompanyAsset/:id' element={isVisible('companyasset-update') ? <CompanyAssetForm /> : <AccessDenied />} />
                    <Route path='assetmonitoring/:companyAsset' element={isVisible('assetmon    itoring-read') ? <AssetMnitoringForm /> : <AccessDenied />} />
                    <Route path='configuration' element={isVisible('configuration-read') ? <Configuration /> : <AccessDenied />} >
                        <Route path='addRole' element={isVisible('role-create') ? <AddRoleForm /> : <AccessDenied />} />
                        <Route path='editRole/:id' element={isVisible('role-update') ? <AddRoleForm /> : <AccessDenied />} />
                        <Route path='rolesTable' element={isVisible('role-read') ? <RolesTable /> : <AccessDenied />} />
                        <Route path='company' element={isVisible('company-read') ? <Company /> : <AccessDenied />} />
                        <Route path='companyDetails/:Id' element={isVisible('company-read') ? <OnbordingDetails /> : <AccessDenied />} />
                        <Route path='addcompany' element={isVisible('company-create') ? <Onbording /> : <AccessDenied />} />
                        <Route path='editcompany/:Id' element={isVisible('company-update') ? <Onbording /> : <AccessDenied />} />
                        <Route path='companyadmin' element={isVisible('companyadmin-read') ? <CompanyAdmin /> : <AccessDenied />} />
                        <Route path='addcompanyadmin' element={isVisible('companyadmin-create') ? <CompanyAdminForm /> : <AccessDenied />} />
                        <Route path='editcompanyadmin/:Id' element={isVisible('companyadmin-update') ? <CompanyAdminForm /> : <AccessDenied />} />
                        <Route path='asset' element={isVisible('asset-read') ? <Asset /> : <AccessDenied />} />
                        <Route path='addasset' element={isVisible('asset-create') ? <AssetForm /> : <AccessDenied />} />
                        <Route path='editasset/:id' element={isVisible('asset-update') ? <AssetForm /> : <AccessDenied />} />
                        <Route path='unit' element={isVisible('unit-read') ? <Unit /> : <AccessDenied />} />
                        <Route path='addUnit' element={isVisible('unit-create') ? <UnitForm /> : <AccessDenied />} />
                        <Route path='editUnit/:id' element={isVisible('unit-update') ? <UnitForm /> : <AccessDenied />} />
                        <Route path='assettype' element={isVisible('assettype-read') ? <AssetType /> : <AccessDenied />} />
                        <Route path='addassettype' element={isVisible('assettype-create') ? <AssetTypeForm /> : <AccessDenied />} />
                        <Route path='editassettype/:id' element={isVisible('assettype-update') ? <AssetTypeForm /> : <AccessDenied />} />
                        <Route path='monitoringtype' element={isVisible('monitoringtype-read') ? <MonitoringType /> : <AccessDenied />} />
                        <Route path='editmonitoringtype/:id' element={isVisible('monitoringtype-update') ? <MonitoringForm /> : <AccessDenied />} />
                        <Route path='addmonitoringtype' element={isVisible('monitoringtype-create') ? <MonitoringForm /> : <AccessDenied />} />
                        {/* <Route path='notificationtype' element={isVisible('notificationtype-read') ? <NotificationType /> : <AccessDenied />} />
                        <Route path='addnotificationtype' element={isVisible('notificationtype-create') ? <NotificationTypeForm /> : <AccessDenied />} />
                        <Route path='editnotificationtype/:id' element={isVisible('notificationtype-update') ? <NotificationTypeForm /> : <AccessDenied />} /> */}
                        <Route path='state' element={isVisible('state-read') ? <State /> : <AccessDenied />} />
                        <Route path='addstate' element={isVisible('state-create') ? <StateForm /> : <AccessDenied />} />
                        <Route path='editstate/:id' element={isVisible('state-update') ? <StateForm /> : <AccessDenied />} />
                        <Route path='country' element={isVisible('country-read') ? <Country /> : <AccessDenied />} />
                        <Route path='addcountry' element={isVisible('country-create') ? <CountryForm /> : <AccessDenied />} />
                        <Route path='editcountry/:id' element={isVisible('country-update') ? <CountryForm /> : <AccessDenied />} />
                        <Route path='problemsolution' element={isVisible('problemsolution-read') ? <ProblemSolution /> : <AccessDenied />} />
                        <Route path='addproblemsolution' element={isVisible('problemsolution-create') ? <ProblemSolutionForm /> : <AccessDenied />} />
                        <Route path='editproblemsolution/:id' element={isVisible('problemsolution-update') ? <ProblemSolutionForm /> : <AccessDenied />} />
                        <Route path='companyList/P' element={isVisible('plant-read') ? <CompanyList route='configuration/plant' /> : <AccessDenied />} />
                        <Route path='plant/:company' element={isVisible('plant-read') ? <Plant /> : <AccessDenied />} />
                        <Route path='companyList/S' element={isVisible('plant-read') ? <CompanyList route='configuration/segment' /> : <AccessDenied />} />
                        <Route path='segment/:company' element={isVisible('segment-read') ? <Segment /> : <AccessDenied />} />
                        <Route path='companyList/b' element={isVisible('billingAddress-read') ? <CompanyList route='configuration/billingAddress' /> : <AccessDenied />} />
                        <Route path='billingAddress/:company' element={isVisible('billingAddress-read') ? <BillingAddress /> : <AccessDenied />} />
                        <Route path='addbillingAddress' element={isVisible('billingAddress-create') ? <BillingAddressForm /> : <AccessDenied />} />
                        <Route path='editbillingAddress/:id' element={isVisible('billingAddress-update') ? <BillingAddressForm /> : <AccessDenied />} />
                        <Route path='companyList/c' element={isVisible('commercialDetails-read') ? <CompanyList route='configuration/commercialDetails' /> : <AccessDenied />} />
                        <Route path='commercialDetails/:company' element={isVisible('commercialDetails-read') ? <CommercialDetails /> : <AccessDenied />} />
                        <Route path='addcommercialDetails' element={isVisible('commercialDetails-create') ? <CommercialDetailsForm /> : <AccessDenied />} />
                        <Route path='editcommercialDetails/:id' element={isVisible('commercialDetails-update') ? <CommercialDetailsForm /> : <AccessDenied />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default Router;