import { useEffect, type FC, useContext, useState } from 'react';
import { DataContext } from '../../Context/DataProvider';
import { IactionPopAction, responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import Loader from '../../Component/Common/Loader/Loader';
import ActionPop from '../../Component/Common/ActionPop/ActionPop';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteModal from '../../Component/Common/DeleteModal';
import Pagination from '../../Component/Common/Pagination';
import Counter from '../../Component/Common/Counter';
import SearchFilter from '../../Component/Common/SearchFilter';
import DataNotFoundPage from '../../Component/Common/DataNotFoundPage';

interface UserProps { }
interface companyAdmins {
    name?: string
    role?: {
        name: string
        _id: string
    }
    _id?: string
    email?: string
    company?: {
        name: string
        _id: string
    }
}

interface UserProps { }

const User: FC<UserProps> = () => {
    const [companyUser, setCompanyUser] = useState<companyAdmins[] | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { isRender, setIsRender, selectedLimit, isVisible, dateFilter } = useContext(DataContext)
    const [Id, setId] = useState<string>();
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    const { company } = useParams()
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [skip, setSkip] = useState(0)
    const [counts, setCounts] = useState<number>(0)
    const [searchFilter, setSearchFilter] = useState<string>('')
    const navigate = useNavigate()

    const handleEdit = () => {
        navigate(`/home/editUser/${Id}`)
    }

    const DeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }

    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/user/deletCompanyAdmin`,
                method: 'delete',
                data: { '_id': Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting company admin.');
            }
        }
    }
    const action: IactionPopAction[] = []
    isVisible('user-update') && action.push({ id: 1, name: 'Edit', click: handleEdit });
    isVisible('user-delete') && action.push({ id: 2, name: 'Delete', click: DeleteModalOpen });

    const fetchCompanyUsers = async () => {
        try {
            let data: any = {
                limit: 10,
                company: userDetails?.company?._id !== undefined ? userDetails?.company?._id : company,
                search: searchFilter,
                dateFilter: dateFilter
            }
            if (searchFilter === '') {
                data.skip = skip;
            }
            const response: responseType = await http({
                url: `/user/getCompanyUsers`,
                method: 'get',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompanyUser(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching user.');
            }
        }
    };
    const getCompanUserCount = async () => {
        try {
            const response: responseType = await http({
                url: `/user/getCompanyUsersPaginationCount`,
                method: 'get',
                data: {
                    company: userDetails?.company?._id !== undefined ? userDetails?.company?._id : company,
                    search: searchFilter,
                    dateFilter: dateFilter
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCounts(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching count.');
            }
        }
    }
    useEffect(() => {
        getCompanUserCount();
        // eslint-disable-next-line 
    }, [searchFilter, isRender, dateFilter]);

    useEffect(() => {
        fetchCompanyUsers();
        // eslint-disable-next-line 
    }, [isRender, skip, searchFilter, selectedLimit, dateFilter]);
    useEffect(() => {
        setIsLoading(true);
        // eslint-disable-next-line 
    }, [skip, selectedLimit]);
    useEffect(() => {
        setSkip(0)
        // eslint-disable-next-line 
    }, [selectedLimit]);
    return (
        <div className='w-full h-full relative'>
            <Loader loader={isLoading} />
            <div className='flex items-center justify-between sm:pb-4 pb-2 '>
                <Counter skip={skip} counts={counts} limit={selectedLimit} />
                <SearchFilter setSearch={setSearchFilter} />
            </div>
            {counts === 0 ?
                <DataNotFoundPage />
                :
                <div className="flex sm:h-[90%] h-[92%] flex-col w-full pb-3">
                    <div className="w-full h-auto overflow-auto scrollbar-thin">
                        <table className="w-full mb-2 text-left">
                            <thead className="border-l-white bg-skin-fill-table-head px-6 text-skin-base-table-head-text sticky z-20 top-0">
                                <tr className=''>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        S.No.
                                    </th>
                                    <th scope="col" className="sticky left-0 bg-skin-fill-table-head px-4 py-3 font-medium truncate">
                                        Name
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Email
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Company
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Role
                                    </th>
                                    {isVisible('user-update') || isVisible('user-delete') ?
                                        <th scope="col" className="py-3 font-medium truncate">
                                            Actions
                                        </th>
                                        : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {companyUser?.map((user, index) =>
                                    <tr key={user._id} className="bg-skin-fill-table hover:bg-skin-fill-hover hover:text-skin-base-table-hover hover:bg-opacity-10 text-skin-base-table-body-text border-b border-skin-table-color group">
                                        <td className="px-4 py-[8px]">
                                            {searchFilter ? (index + 1) : (skip + (index + 1))}
                                        </td>
                                        <td className="sticky left-0 group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px] truncate">
                                            {user?.name}
                                        </td>
                                        <td className="px-4 py-[8px] truncate">
                                            {user?.email}
                                        </td>
                                        <td className="px-4 py-[8px] truncate">
                                            {user?.company?.name}
                                        </td>
                                        <td className="px-4 py-[8px] truncate">
                                            {user?.role?.name}
                                        </td>
                                        {isVisible('user-update') || isVisible('user-delete') ?
                                            <td className="px-4 py-[8px]" >
                                                <span onClick={() => setId(user?._id)}><ActionPop action={action} icon={'FiMoreVertical'} /></span>
                                            </td>

                                            : null
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {(counts > selectedLimit && searchFilter === '') && <Pagination limit={selectedLimit} setSkip={setSkip} itemsCount={counts} />}
                </div>
            }
            <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='User' />
        </div>
    );
}

export default User;
