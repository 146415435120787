import { useState, type FC, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import { LuCalendarPlus } from 'react-icons/lu';
import { DataContext } from '../../../Context/DataProvider';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import moment from 'moment';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';

interface PendingTasksProps {
    setLoading: Dispatch<SetStateAction<boolean>>
    setOpenPopUp: Dispatch<SetStateAction<boolean>>
}

const PendingTasks: FC<PendingTasksProps> = ({ setLoading, setOpenPopUp }) => {
    const { setAssetForLog, isRender } = useContext(DataContext);
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const [pendingTasks, setPendingTasks] = useState<any[]>([])
    const fetchPendingTasks = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringLog/getPendingLogs`,
                method: 'get',
                data: { company: userDetails?.company?._id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setPendingTasks(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching pending log');
            }
        }
    }
    const addLog = (companyAsset: any) => {
        setAssetForLog(companyAsset)
        setOpenPopUp(true)
    }
    useEffect(() => {
        if (userDetails.role.code !== 'superadmin') {
            fetchPendingTasks();
        }
        // eslint-disable-next-line 
    }, [isRender])
    const countGenerator = (datas: any) => {
        let count = 0
        for (const data of datas) {
            for (const item of data?.logsByAssetType) {
                // eslint-disable-next-line
                for (const assets of item?.companyAssets) {
                    // for (const log of assets?.logs) {
                    count++
                    // }
                }
            }
        }
        return count;
    }
    return (
        <div className='w-full shadow-lg h-[440px] flex flex-col bg-white rounded-lg px-2 pb-1 overflow-y-scroll scrollbar-thin font-sans'>
            <h1 className='w-full self-start font-medium text-lg border-b sticky top-0 bg-white pt-1'>Upcoming tasks
                ({countGenerator(pendingTasks)})
            </h1>
            {pendingTasks.length > 0 ?
                pendingTasks.map((tasks: any, index: number) => (
                    <div key={index} className="flex flex-col w-full pt-1">
                        {tasks?.logsByAssetType?.map((task: any, index: number) => (
                            <div key={index} className='w-full grid text-sm grid-col-1 gap-1'>
                                <div className='flex items-center gap-4'>
                                    <h2 className='font-medium text-sm truncate'>{task?.assetType.name}</h2>
                                    <span className={`${tasks?.created_at !== moment().format('YYYY-MM-DD') ? "text-red-500" : "text-gray-800"} bg-gray-200 w-fit px-2 text-[12px] my-1`}>{moment(tasks?.created_at).format('MMM Do')}</span>
                                </div>
                                {task?.companyAssets?.map((item: any, index: number) => (
                                    <div key={index} className="flex flex-col w-full gap-1 border p-1 rounded-md">
                                        <div className="flex w-full justify-between gap-2 items-center">
                                            <div className="w-full">
                                                <h2 className='font-semibold py-1'>{item?.assetName}</h2>
                                                <div className="w-full flex justify-between">
                                                    <div className='flex items-center flex-wrap'>
                                                        {item?.logs?.map((log: any, index: number) => (
                                                            <p key={index} className='px-2 py-0.5 bg-gray-100 text-sm rounded-md'>{log?.monitoringType?.name}</p>
                                                        ))}
                                                    </div>
                                                    <LuCalendarPlus onClick={() => addLog({ _id: item?.assetId, existLog: true, monitoring: item?.logs })} title='addLog' className='cursor-pointer' size={18} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))
                :
                <DataNotFoundPage />
            }
        </div>
    );
}

export default PendingTasks;
