import { ToastContainer } from 'react-toastify';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Router from './Router/Router';

function App() {
  return (
    <>
      <Router />
      <ToastContainer
        position="top-right"
        limit={1}
        autoClose={1000}
        hideProgressBar={false}
        pauseOnFocusLoss
        theme="light"
      />
    </>
  );
}

export default App;
