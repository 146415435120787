import { responseType } from '../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from './http/http';

const handleImageUpload = (file: File) => {
        return new Promise(async (resolve, reject)=>{
            const FD = new FormData();
            FD.append('', file);
            try {
                const signedUrlResponse: responseType = await http({
                    url: `/s3/getSignedUrl`,
                    method: 'post',
                    data: {file_name: file.name, mime_type:file.type}
                });
                await http({
                    url: signedUrlResponse?.data?.data?.url,
                    method: 'put',
                    data: file
                }, false, true);
                const response: responseType = await http({
                    url: `/media/addMedia`,
                    method: 'post',
                    data: {
                        mimetype:file.type,
                        url:signedUrlResponse?.data?.data?.file_url
                    }
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    resolve(response?.data?.data);
                } else {
                    reject(response?.data?.message);
                }
            } catch (error: any) {
                if (error.response && error.response.data && error.response.data.message) {
                    reject(error?.response?.data?.message)
                } else {
                    reject(error?.message)
                }
            }
        });

    };
export default handleImageUpload;