import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../Assets/logoText.jpeg'

interface GridViewProps {
    List: any[] | null
    searchFilter: string
    skip: number
    route: string
}

const GridView: FC<GridViewProps> = ({ List, searchFilter, skip, route }) => {
    const navigate = useNavigate()
    return (
        <div className='w-full overflow-y-auto scrollbar-thin gap-2 grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 xl:grid-cols-5 p-2'>
            {List?.map((company: any) =>
                <div onClick={() => navigate(`/home/${route}/${company?._id}`)} key={company._id} className="rounded-md cursor-pointer h-40 flex text-skin-base bg-skin-fill-card flex-col shadow-lg">
                    <div className='h-32 rounded-t-md'>
                        <img src={company?.logo ? company?.logo?.url : logo} className='w-full h-full rounded-t-md' alt="" />
                    </div>
                    <div className='h-8 flex items-center justify-center'>
                        <h1 className='font-medium'>{company?.name}</h1>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GridView;
