import type { FC } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getRandomColor } from '../../../Utils/Utils';
ChartJS.register(ArcElement, Tooltip, Legend);


interface PieChartProps {
    data: any[]
    title:string
}

const PieChart: FC<PieChartProps> = ({ data ,title}) => {
    const convertedData: any = {
        labels: [],
        datasets: [
            {
                label: title,
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1,
            },
        ],
    };
    data?.forEach((item: any) => {
        const randomColor = getRandomColor();
        convertedData.labels.push(item?.company?.name ?? item?.asset?.name ?? item?.state?.name);
        convertedData.datasets[0].data.push(item?.count);
        convertedData.datasets[0].backgroundColor.push(`rgba(${randomColor},${0.8})`);
        convertedData.datasets[0].borderColor.push(`rgba(${randomColor},${1})`);
    });
    return (
        <Pie data={convertedData} className='w-full h-full' />
    );
}

export default PieChart;
