import { useState, type FC, Dispatch, SetStateAction, useEffect } from 'react';
import DoughnutChart from '../../../Component/Common/Charts/DoughnutChart';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';

interface CompanyDoughnutChartDataProps {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const CompanyDoughnutChartData: FC<CompanyDoughnutChartDataProps> = ({ setLoading }) => {
    const [roleWiseUsersCountsForGraph, setRoleWiseUsersCountsForGraph] = useState<any[]>([])
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')

    const fetchRoleWiseUsersCountFroGraph = async () => {
        try {
            const response: responseType = await http({
                url: `/user/getRoleWiseUsersCountFroGraph`,
                method: 'get',
                data: { company: userDetails?.company?._id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setRoleWiseUsersCountsForGraph(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching user count');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code !== 'superadmin') {
            fetchRoleWiseUsersCountFroGraph();
        }
        // eslint-disable-next-line 
    }, [])
    return (
        <div className='w-full shadow-lg h-[440px] flex flex-col items-center justify-center bg-white rounded-lg p-2 font-sans'>
            <h1 className='w-full self-start text-sm border-b'>Users count role wise</h1>
            <div className="w-full h-[400px] flex items-center justify-center">
                {roleWiseUsersCountsForGraph && roleWiseUsersCountsForGraph.length !== 0 ? <DoughnutChart data={roleWiseUsersCountsForGraph} /> : <span className='text-sm flex items-center justify-center'>No users found.</span>}
            </div>
        </div>
    );
}

export default CompanyDoughnutChartData;
