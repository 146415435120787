import { useEffect, type FC, useState, useContext } from 'react';
import Loader from '../../../Component/Common/Loader/Loader';
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputSelect from '../../../Component/FormControl/InputSelect';
import InputText from '../../../Component/FormControl/InputText';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import ImageUploadInput from '../../../Component/FormControl/ImageUploadInput';
import { toast } from 'react-toastify';
import { IAsset, responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { RxCross2 } from 'react-icons/rx'
import { useTranslation } from 'react-i18next';
import noImage from '../../../Assets/noimageavailable.jpg'
import FormBuilder from '../../../Component/Common/FormBuilder';
import HorizontalStepper from '../../../Component/Common/Stepper';
import ProblemSolutionFormForAsset from './ProblemSolutionFormForAsset';
import FileUpload from '../../../Services/file_upload'
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import { DataContext } from '../../../Context/DataProvider';

interface AssetFormProps { }

const AssetForm: FC<AssetFormProps> = () => {
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [imagePostedArray, setImagePostedArray] = useState<{ _id: string, url: string }[]>([])
    const [assetEdit, setAssetEdit] = useState<IAsset | null>(null)
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [allAssetTypes, setAllAssetTypes] = useState([]);
    const [imageArray, setImageArray] = useState<string[]>([]);
    const [activeStep, setActiveStep] = useState<number>(JSON.parse(sessionStorage.getItem('as') ?? '0'));
    const { id } = useParams();

    const initialValues = {
        name: '',
        // code: '',
        assetType: '',
    }

    const fetchAssetType = async () => {
        try {
            const response: responseType = await http({
                url: `/asset/getAssetForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAssetEdit(response?.data?.data);
                initialValues.name = response.data.data?.name;
                // initialValues.code = response.data.data?.code;
                initialValues.assetType = response.data.data?.assetType?._id;
                setImageArray(response.data.data?.images.map((image: { _id: string }) => image._id));
                setImagePostedArray(response.data.data?.images);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset');
            }
        }
    };
    useEffect(() => {
        if (id !== undefined) {
            fetchAssetType();
        }
        // eslint-disable-next-line
    }, [])
    const fetchAssetTypes = async () => {
        try {
            const response: responseType = await http({
                url: `/assetType/getAssetTypeForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAllAssetTypes(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset type');
            }
        }
    };

    useEffect(() => {
        fetchAssetTypes();
        // eslint-disable-next-line 
    }, []);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').min(3, 'Too short name').max(50, 'Too long name'),
        // code: Yup.string().required('Code is required').min(3, 'Too short code').max(6, 'Too long code'),
        assetType: Yup.string().required('AssetType is required'),
    });

    const handleSubmit = async (values: any) => {
        setButtonLoader(true)
        values['images'] = imageArray;
        if (assetEdit !== null) {
            values['_id'] = assetEdit?._id;
            try {
                const response: responseType = await http({
                    url: `/asset/editAsset`,
                    method: 'put',
                    data: values,
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    sessionStorage.setItem('lca', response?.data?.data?._id)
                    setTimeout(() => {
                        setButtonLoader(false)
                        setActiveStep(1)
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error editing asset');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/asset/addAsset`,
                    method: 'post',
                    data: values,
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    sessionStorage.setItem('lca', response?.data?.data?._id)
                    setTimeout(() => {
                        setButtonLoader(false)
                        setActiveStep(1)
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding asset');
                }
            }
        }
    }

    const handleDeleteImage = async (_id: string) => {
        try {
            const response: responseType = await http({
                url: `/media/deleteMediaPermanent`,
                method: 'delete',
                data: { _id },
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setImagePostedArray(imagePostedArray?.filter((image: { _id: string, url: string }) => (image._id !== response?.data?.data._id)));
                setImageArray(imageArray?.filter((imageId: string) => (imageId !== response?.data?.data._id)));
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting image');
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        // eslint-disable-next-line 
    }, []);
    useEffect(() => {
        sessionStorage.setItem('as', JSON.stringify(activeStep))
        // eslint-disable-next-line 
    }, [activeStep]);
    useEffect(() => {
        return () => {
            sessionStorage.removeItem('as');
        }
        // eslint-disable-next-line
    }, [pathname])

    const handleImageUpload = async (file: File) => {
        try {
            let data: any = await FileUpload(file);
            setImageArray([...imageArray, data._id]);
            setImagePostedArray([...imagePostedArray, data]);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error uploading image.');
            }
        }
    };
    const handleNext = () => {
        setActiveStep(1)
    }

    return (
        <div className='w-full h-full relative'>
            <div className="w-full px-2 sticky bg-skin-fill-formheader top-0 z-30">
                <p className='py-1 font-medium text-skin-base text-lg'>{activeStep === 0 ? (assetEdit === null ? t('AddAsset') : t('EditAsset')) : 'Aditional informathion'}</p>
                <hr />
            </div>
            <Loader loader={isLoading} />
            <div className="w-full">
                <HorizontalStepper {...{ activeStep, setActiveStep }} />
                {activeStep === 0
                    ?
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <Form className='flex flex-col gap-4 p-3'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                <InputSelect name='assetType' id='assetType' label='Asset Type' Array={allAssetTypes} />
                                <InputText name='name' id='name' label='Name' />
                                {/* <InputText name='code' id='code' label='Code' disabled={assetEdit !== null ? true : false} /> */}
                            </div>
                            <div className="w-full h-52 border border-dashed overflow-y-auto flex gap-2 flex-wrap scrollbar-thin p-2">
                                {imagePostedArray?.map((image: { _id: string, url: string }) => (
                                    <div key={image._id} className="w-32 h-32 rounded-md border border-dotted relative group">
                                        <span onClick={() => handleDeleteImage(image._id)} className='hidden w-5 h-5 group-hover:flex items-center justify-center absolute cursor-pointer -top-2 -right-2 rounded-full bg-gray-200 text-gray-700 text-sm border border-black'><RxCross2 /></span>
                                        <img src={image.url ?? noImage} alt="asset_image" className='w-full h-full' />
                                    </div>
                                ))}
                            </div>
                            <ImageUploadInput onImageUpload={handleImageUpload} />
                            <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet">
                                <CancelButton onClick={() => navigate('/home/configuration/asset')} />
                                {pathname.split('/')[3] === 'editasset' && <CancelButton onClick={handleNext} label='NEXT' />}
                                {buttonLoader ? <ButtonLoader /> : <SubmitButton label={assetEdit !== null ? 'Edit Asset' : 'Add Asset'} />}
                            </div>
                        </Form>
                    </Formik>
                    : activeStep === 1
                        ?
                        <FormBuilder setActiveStep={setActiveStep} editData={assetEdit} />
                        :
                        <ProblemSolutionFormForAsset setActiveStep={setActiveStep} editData={assetEdit} />
                }
            </div>
        </div>
    );
}

export default AssetForm;
