import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import noImage from '../../../Assets/noimageavailable.jpg'

interface CarouselItem {
    _id: string;
    url: string;
    mimetype: string;
    URL?: string
}

interface CarouselProps {
    items: CarouselItem[];
    click?: Function
}

const MyCarousel: React.FC<CarouselProps> = ({ items, click }) => {
    let mapperArray: CarouselItem[] = [];
    if (items?.length === 0) {
        mapperArray?.push({ _id: '1', url: '', mimetype: 'NOIMAGE' })
    } else {
        mapperArray = items;
    }

    return (
        <div className={`relative w-full h-full rounded-t-xl ${click && 'cursor-pointer'}`} onClick={() => { if (click) click() }}>
            <Carousel
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                showStatus={false}
                autoPlay={true}
                interval={5000} // Adjust the auto-play interval as needed
            >
                {mapperArray?.map((item: CarouselItem) => (
                    <div key={item?._id} className='w-full h-full'>
                        <img src={`${item.URL ?? (item.url === '' ? noImage : item.url)}`} alt={item.mimetype} className='h-40 w-60 rounded-t-xl' />
                    </div>
                ))}
            </Carousel>
        </div >
    );
};

export default MyCarousel;
