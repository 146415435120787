import { useState, type FC, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataContext } from '../../../Context/DataProvider';
import { IactionPopAction, responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import Loader from '../../../Component/Common/Loader/Loader';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';
import Counter from '../../../Component/Common/Counter';
import SearchFilter from '../../../Component/Common/SearchFilter';
import ActionPop from '../../../Component/Common/ActionPop/ActionPop';
import Pagination from '../../../Component/Common/Pagination';
import DeleteModal from '../../../Component/Common/DeleteModal';

interface BillingAddressProps { }
interface billingAddressData {
    _id: string
    attention: string
    country: { _id: string, name: string }
    state: { _id: string, name: string }
    addressLine: string
    city: string
    zipCode: string
    company: { _id: string, name: string }
}

const BillingAddress: FC<BillingAddressProps> = () => {
    const { company } = useParams()
    const [billingAddressData, setBillingAddressData] = useState<billingAddressData[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [Id, setId] = useState<string>();
    const navigate = useNavigate();
    const { isRender, setIsRender, selectedLimit, isVisible, dateFilter } = useContext(DataContext)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [skip, setSkip] = useState(0)
    const [counts, setCounts] = useState<number>(0)
    const [searchFilter, setSearchFilter] = useState<string>('')

    const handleEdit = () => {
        navigate(`/home/configuration/editbillingAddress/${Id}`)
    }

    const DeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }
    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/billingAddress/deleteBillingAddress`,
                method: 'delete',
                data: { '_id': Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting billing address');
            }
        }
    }
    const action: IactionPopAction[] = []
    isVisible('billingAddress-update') && action.push({ id: 1, name: 'Edit', click: handleEdit });
    isVisible('billingAddress-delete') && action.push({ id: 2, name: 'Delete', click: DeleteModalOpen });

    const fetchBillingAddress = async () => {
        try {
            let data: any = {
                company: company,
                search: searchFilter,
                dateFilter: dateFilter
            }
            if (searchFilter === '') {
                data.skip = skip;
                data.limit = selectedLimit;
            }
            const response: responseType = await http({
                url: `/billingAddress/getBillingAddress`,
                method: 'get',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setBillingAddressData(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching billing address.');
            }
        }
    };

    const getBillingAddressCount = async () => {
        try {
            const response: responseType = await http({
                url: `/billingAddress/getBillingAddressCountForPagination`,
                method: 'get',
                data: {
                    company: company,
                    search: searchFilter,
                    dateFilter: dateFilter
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCounts(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error FetchingCount.');
            }
        }
    }
    useEffect(() => {
        getBillingAddressCount();
        // eslint-disable-next-line 
    }, [searchFilter, isRender, dateFilter]);
    useEffect(() => {
        fetchBillingAddress();
        // eslint-disable-next-line 
    }, [isRender, skip, searchFilter, selectedLimit, dateFilter]);
    useEffect(() => {
        setIsLoading(true)
        // eslint-disable-next-line 
    }, [skip, selectedLimit]);
    return (
        <div className='h-full w-full relative  rounded-2xl'>
            <Loader loader={isLoading} />
            <div className='flex items-center justify-between sm:pb-4 pb-2 '>
                <Counter skip={skip} counts={counts} limit={selectedLimit} />
                <SearchFilter setSearch={setSearchFilter} />
            </div>
            {counts === 0 ?
                <DataNotFoundPage />
                :
                <div className="flex sm:h-[90%] h-[92%]  flex-col w-full">
                    <div className="w-full h-auto overflow-auto scrollbar-thin">
                        <table className="w-full mb-2 text-left">
                            <thead className=" px-6   bg-skin-fill-table-head text-skin-base-table-head-text z-10  sticky top-0">
                                <tr>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        S.No.
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Company
                                    </th>
                                    <th scope="col" className="sticky left-0 bg-skin-fill-table-head px-4 py-3 font-medium truncate">
                                        Attention
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Location
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Address line
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        City
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Zip Code
                                    </th>
                                    {isVisible('configuration-update') || isVisible('configuration-delete') ?
                                        <th scope="col" className="py-3 font-medium truncate">
                                            Actions
                                        </th>
                                        : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {billingAddressData?.map((billingAddress, index) =>
                                    <tr key={billingAddress._id} className=" bg-skin-fill-table  hover:bg-skin-fill-hover hover:text-skin-base-table-hover hover:bg-opacity-10 text-skin-base-table-body-text   border-b border-skin-table-color group">
                                        <td className="px-4 py-[8px] ">
                                            {searchFilter ? (index + 1) : (skip + (index + 1))}
                                        </td>
                                        <td className=" group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px]  truncate">
                                            {billingAddress?.company?.name}
                                        </td>
                                        <td className="sticky left-0 group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px]  truncate">
                                            {billingAddress?.attention}
                                        </td>
                                        <td className="px-4 py-[8px]  truncate">
                                            {billingAddress?.state?.name},{billingAddress?.country?.name}
                                        </td>
                                        <td className="px-4 py-[8px]  truncate">
                                            {billingAddress?.addressLine}
                                        </td>
                                        <td className="px-4 py-[8px]  truncate">
                                            {billingAddress?.city}
                                        </td>
                                        <td className="px-4 py-[8px]  truncate">
                                            {billingAddress?.zipCode}
                                        </td>
                                        {isVisible('billingAddress-update') || isVisible('billingAddress-delete') ?
                                            <td className="px-4 py-[8px] " >
                                                <span onClick={() => setId(billingAddress?._id)}><ActionPop action={action} icon={'FiMoreVertical'} />
                                                </span>
                                            </td>
                                            : null
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {(counts > selectedLimit && searchFilter === '') && <Pagination limit={selectedLimit} setSkip={setSkip} itemsCount={counts} />}
                </div>
            }
            <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='Billing address' />
        </div>
    );
}

export default BillingAddress;
