import { useEffect, type FC, useContext, useState } from 'react';
import { responseType } from '../TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import { DataContext } from '../../../Context/DataProvider';
import Loader from '../Loader/Loader';
import Pagination from '../Pagination';
import Counter from '../Counter';
import SearchFilter from '../SearchFilter';
import ListView from './ListView';
import { TbList } from 'react-icons/tb';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import GridView from './GridView';
import DataNotFoundPage from '../DataNotFoundPage';

interface CompanyListProps {
    route: string
}

const CompanyList: FC<CompanyListProps> = ({ route }) => {
    const { isRender, selectedLimit, dateFilter } = useContext(DataContext)
    const [companyList, setCompanyList] = useState<any[] | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [skip, setSkip] = useState(0)
    const [counts, setCounts] = useState<number>(0)
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [view, setView] = useState<'grid' | 'list'>('grid')

    const fetchCompanies = async () => {
        try {
            let data: any = {
                limit: selectedLimit,
                search: searchFilter
            }
            if (searchFilter === '') {
                data.skip = skip;
            }
            const response: responseType = await http({
                url: `/company/getCompany`,
                method: 'get',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompanyList(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company.');
            }
        }
    };
    const getCompanCount = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getCompanyCountForPagination`,
                method: 'get',
                data: {
                    search: searchFilter
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCounts(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching count.');
            }
        }
    }
    useEffect(() => {
        getCompanCount();
        // eslint-disable-next-line 
    }, [searchFilter, isRender]);

    useEffect(() => {
        fetchCompanies();
        // eslint-disable-next-line 
    }, [isRender, skip, searchFilter, selectedLimit, dateFilter]);
    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false)
        }, 5000);
        // eslint-disable-next-line 
    }, [skip, selectedLimit]);
    useEffect(() => {
        setSkip(0)
        // eslint-disable-next-line 
    }, [selectedLimit]);
    return (
        <div className='h-full w-full relative '>
            <Loader loader={isLoading} />
            {counts === 0 ?
                <DataNotFoundPage />
                :
                <>
                    <div className='flex items-center justify-between sm:pb-4 pb-2'>
                        <Counter skip={skip} counts={counts} limit={selectedLimit} />
                        <span className='flex items-center text-gray-700 gap-2'>
                            <span className='flex items-center text-gray-700 gap-2 mr-12 sm:mr-0'>
                                <BsFillGrid3X3GapFill onClick={() => setView('grid')} className={`${view === 'grid' && 'text-red-400'} cursor-pointer`} />
                                <span>|</span>
                                <TbList onClick={() => setView('list')} size={20} className={`${view === 'list' && 'text-red-400'} cursor-pointer`} />
                            </span>
                            <SearchFilter setSearch={setSearchFilter} />
                        </span>
                    </div>
                    <div className="flex sm:h-[90%] h-[92%] flex-col w-full">
                        {view === 'list' ?
                            <ListView List={companyList} route={route} searchFilter={searchFilter} skip={skip} />
                            :
                            <GridView List={companyList} route={route} searchFilter={searchFilter} skip={skip} />
                        }
                        {(counts > selectedLimit && searchFilter === '') && <Pagination limit={selectedLimit} setSkip={setSkip} itemsCount={counts} />}
                    </div>
                </>
            }
        </div>
    );
}

export default CompanyList;
