import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getRandomColor } from '../../../Utils/Utils';
import { FC } from 'react';
ChartJS.register(ArcElement, Tooltip, Legend);


interface DoughnutChartProps {
    data: any[]
}

const DoughnutChart: FC<DoughnutChartProps> = ({ data }) => {
    const convertedData: any = {
        labels: [],
        datasets: [
            {
                label: 'Users',
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1,
            },
        ],
    };
    data?.forEach((item: any) => {
        const randomColor = getRandomColor();
        convertedData.labels.push(item?.company?.name ?? item?.role?.name);
        convertedData.datasets[0].data.push(item?.count);
        convertedData.datasets[0].backgroundColor.push(`rgba(${randomColor},${0.8})`);
        convertedData.datasets[0].borderColor.push(`rgba(${randomColor},${1})`);
    });
    return (
        <Doughnut data={convertedData} />
    );
}
export default DoughnutChart;