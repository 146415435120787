import type { Dispatch, FC, SetStateAction } from 'react';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
    limit: number
    setSkip:Dispatch<SetStateAction<number>>;
    itemsCount:number
}

const Pagination: FC<PaginationProps> = ({ limit ,setSkip,itemsCount}) => {
    const pageCount = Math.ceil(itemsCount / limit);
    const handlePageClick = (event: any) => {
        const newOffset = (event.selected ) * limit;
        setSkip(newOffset);
    };
    return (
        <>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                className="flex items-center gap-1 self-end mr-2"
                pageClassName=""
                pageLinkClassName="px-2 py-1 border border-gray-600 rounded-md text-center"
                activeLinkClassName="bg-skin-fill-pagination-active text-white"
                previousClassName=""
                previousLinkClassName="px-2 py-1 border border-gray-600 rounded-md text-center"
                nextClassName=""
                nextLinkClassName="px-2 py-1 border border-gray-600 rounded-md text-center"
                breakLinkClassName="px-1.5 py-1 border border-gray-600 rounded-md text-center"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
            />
        </>
    );
}

export default Pagination;
