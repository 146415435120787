import type { Dispatch, FC, SetStateAction } from 'react';
import { motion } from 'framer-motion'
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

interface NotificationPopUpProps {
    data: any
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}


const NotificationPopUp: FC<NotificationPopUpProps> = ({ data, open, setOpen }) => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '{}');
    const navigate = useNavigate();
    const variants = {
        open: {
            opacity: 1,
            transition: {
                duration: 0.3,
            },
        },
        closed: {
            opacity: 0,
            transition: {
                duration: 0.3,
            },
        },
    };

    return (
        <motion.div
            className={`w-[320px] ${data?.fallen_range === 'r1' ? ' border-red-600' : data?.fallen_range === 'r2' ? ' border-orange-600' : data?.fallen_range === 'r3' ? 'border-yellow-400' : data?.fallen_range === 'r4' && 'border-green-600'} border border-l-4 z-50 h-[150px] overflow-hidden rounded cursor-pointer bg-white p-1 fixed transition-all duration-500 ${open ? 'top-20 right-4' : '-right-96'}`}
            variants={variants}
            initial="closed"
            animate={open ? 'open' : 'closed'}
        >
            <div className="flex flex-col px-1" onClick={() => navigate(`/home/notificationDetail/${data._id}`)}>
                <div className="w-full h-full flex justify-between border-b border-gray-200">
                    <h1 className='text-gray-700'>Dear {userDetails?.name}</h1>
                    <span className='text-gray-700 text-2xl' onClick={() => setOpen(false)}><IoClose /></span>
                </div>
                <div className="flex flex-col text-gray-700">
                    <h1 className='font-medium'>Regarding {data?.log?.companyAsset?.name}:</h1>
                    <p>{data?.content}</p>
                </div>
            </div>
        </motion.div>
    );
}

export default NotificationPopUp;