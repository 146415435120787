import { useEffect, type FC, useState, useContext } from 'react';
import { FieldArray, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { DataContext } from '../../../Context/DataProvider';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import InputSelect from '../../../Component/FormControl/InputSelect';
import InputText from '../../../Component/FormControl/InputText';
import InputEmail from '../../../Component/FormControl/InputEmail';
import InputPassword from '../../../Component/FormControl/InputPassword';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import CancelButton from '../../../Component/FormControl/CancelButton';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import InputNumber from '../../../Component/FormControl/InputNumber';
import { Salutation, countryCode } from '../../../Component/FormControl/CountryCode';
import InputSelectCountryCode from '../../../Component/FormControl/InputSelectCountryCode';
import { RiDeleteBin4Line } from 'react-icons/ri';
import { FaPlusCircle } from 'react-icons/fa';

interface CompanyAdminFormProps {
    setIsLoading?: any;
    setIsActive?: any;
    setCompletedTabs?: any;
    completdTab?: string;
}

const CompanyAdminForm: FC<CompanyAdminFormProps> = ({ setIsLoading, setIsActive, setCompletedTabs }) => {
    const [allRoles, setAllRoles] = useState<string[]>([]);
    const company = sessionStorage.getItem('companyOnbord');
    const { buttonLoader, setButtonLoader, isRender } = useContext(DataContext)

    const initialValues = {
        users: [
            {
                role: '',
                name: '',
                email: '',
                password: '',
                primaryPhone: '',
                primaryPhoneCode: '+91',
                workPhoneCode: '+91',
                workPhone: '',
                salutation: 'Mr',
            }
        ]
    }

    const handlePrevious = () => {
        setIsActive('billing_address');
    };
    const fetchRoles = async () => {
        try {
            const response: responseType = await http({
                url: `/role/getRoleForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAllRoles(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching roles.');
            }
        }
    };


    useEffect(() => {
        fetchRoles();
        // eslint-disable-next-line 
    }, [isRender]);

    const validationSchema = Yup.object().shape({
        users: Yup.array().of(
            Yup.object().shape({
                role: Yup.string().required('Role is required'),
                name: Yup.string().required('Full Name is required'),
                primaryPhone: Yup.string().required('Primary phone number is required').matches(/^[0-9]{10}$/, 'Must be exactly 10 digits'),
                workPhone: Yup.string().required('Work phone number is required').matches(/^[0-9]{10}$/, 'Must be exactly 10 digits'),
                email: Yup.string().required('Email is required').email('Invalid Email'),
                password: Yup.string().required('Password is required').min(4, 'Your password is too short'),
            })
        )
    });

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        let final_values: any = []
        values.users.map((user: any) => (
            final_values.push(
                {
                    'name': `${user.salutation} ${user.name}`,
                    'primaryPhone': `${user.primaryPhoneCode}-${user.primaryPhone}`,
                    'workPhone': `${user.workPhoneCode}-${user.workPhone}`,
                    'role': user.role,
                    'email': user.email,
                    'password': user.password,
                    'company': company
                }
            )
        ))
        try {
            const response: responseType = await http({
                url: `/user/addCompanyAdmin`,
                method: 'post',
                data: final_values
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setTimeout(() => {
                    setButtonLoader(false)
                    setCompletedTabs('user')
                    setIsActive('asset')
                }, 1000);
            } else {
                setButtonLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            setButtonLoader(false)
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error adding company admin.');
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        // eslint-disable-next-line 
    }, []);
    return (
        <div className='w-full relative'>
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values }) => (
                        <Form className='flex flex-col gap-4 p-3'>
                            <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                                <CancelButton onClick={handlePrevious} label='Previous' />
                                {buttonLoader ? <ButtonLoader /> : <SubmitButton label='Save & Next' />}
                            </div>
                            <FieldArray name="users">
                                {({ remove, push }: { remove: (index: number) => void, push: (value: any) => void }) => (
                                    <>
                                        {values.users.length > 0 && values?.users?.map((user: any, userIndex: number) => (
                                            <div key={userIndex} className='w-full'>
                                                <h2>User: {userIndex + 1}</h2>
                                                <div className="mb-2 w-full flex gap-8">
                                                    <div className='relative group w-[90%] flex flex-col gap-3'>
                                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                                            <div className='flex  w-full'>
                                                                <div className='w-16'>
                                                                    <InputSelectCountryCode name={`users.${userIndex}.salutation`} id={`users.${userIndex}.salutation`} label='' Array={Salutation} isCorner={true} />
                                                                </div>
                                                                <div className='w-full'>
                                                                    <InputText name={`users.${userIndex}.name`} id={`users.${userIndex}.name`} label='Full name' isCorner={true} />
                                                                </div>
                                                            </div>
                                                            <InputSelect name={`users.${userIndex}.role`} id={`users.${userIndex}.role`} label='Role' Array={allRoles} />
                                                            <InputEmail name={`users.${userIndex}.email`} id={`users.${userIndex}.email`} label='Email' />
                                                            <div className='flex  w-full'>
                                                                <div className='w-20'>
                                                                    <InputSelectCountryCode name={`users.${userIndex}.primaryPhoneCode`} id={`users.${userIndex}.primaryPhoneCode`} label='' Array={countryCode} isCorner={true} />
                                                                </div>
                                                                <div className='w-full'>
                                                                    <InputNumber name={`users.${userIndex}.primaryPhone`} id={`users.${userIndex}.primaryPhone`} label='primary phone' isCorner={true} />
                                                                </div>
                                                            </div>
                                                            <div className='flex w-full'>
                                                                <div className='w-20'>
                                                                    <InputSelectCountryCode name={`users.${userIndex}.workPhoneCode`} id={`users.${userIndex}.workPhoneCode`} label='' Array={countryCode} isCorner={true} />
                                                                </div>
                                                                <div className='w-full'>
                                                                    <InputNumber name={`users.${userIndex}.workPhone`} id={`users.${userIndex}.workPhone`} label='Work phone' isCorner={true} />
                                                                </div>
                                                            </div>
                                                            <InputPassword name={`users.${userIndex}.password`} id={`users.${userIndex}.password`} label='Password' />
                                                        </div>
                                                        <div onClick={() => remove(userIndex)} className='absolute right-5 top-2.5 group-hover:block text-xl hidden text-red-600 cursor-pointer'><RiDeleteBin4Line />
                                                        </div>
                                                    </div>
                                                    {userIndex === values.users.length - 1 && (
                                                        <div className={` text-green-600 text-2xl`}>
                                                            <FaPlusCircle
                                                                className='cursor-pointer'
                                                                title='Add User' onClick={() => push({
                                                                    role: '',
                                                                    name: '',
                                                                    email: '',
                                                                    password: '',
                                                                    primaryPhone: '',
                                                                    primaryPhoneCode: '+91',
                                                                    workPhoneCode: '+91',
                                                                    workPhone: '',
                                                                    salutation: 'mr',
                                                                })}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </>
                                )}
                            </FieldArray>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default CompanyAdminForm;
