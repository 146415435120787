import { useState, type FC, Dispatch, SetStateAction, useEffect } from 'react';
import BarChart from '../../../Component/Common/Charts/BarChart';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';

interface CompanyBarGrapDataProps {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const CompanyBarGrapData: FC<CompanyBarGrapDataProps> = ({ setLoading }) => {
    const [barGraphData2, setBarGraphData2] = useState<any>();
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const fetchMonitoringLogCountsAssetWiseForGraph = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringLog/getMonitoringLogCountsAssetWiseForGraph`,
                method: 'get',
                data: { company: userDetails?.company?._id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setBarGraphData2(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching monitoring log');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code !== 'superadmin') {
            fetchMonitoringLogCountsAssetWiseForGraph();
        }
        // eslint-disable-next-line 
    }, [])

    return (
        <div className='w-full flex flex-col items-center shadow-lg h-[440px] bg-white rounded-lg p-2 font-sans'>
            <h1 className='w-full self-start text-sm border-b'>Logs count asset wise</h1>
            <div className="w-full h-[400px] flex items-center justify-center">
                {(barGraphData2 && barGraphData2?.datasets?.length !== 0) ? <BarChart data={barGraphData2} /> : <span className='text-sm flex items-center justify-center'>No logs found.</span>}
            </div>
        </div>
    );
}

export default CompanyBarGrapData;
