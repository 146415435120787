import { useEffect, type FC, useState, useContext } from 'react';
import Loader from '../../Component/Common/Loader/Loader';
import { Form, Formik } from 'formik';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { DataContext } from '../../Context/DataProvider';
import CancelButton from '../../Component/FormControl/CancelButton';
import SubmitButton from '../../Component/FormControl/SubmitButton';
import Multiselect from 'multiselect-react-dropdown';
import ButtonLoader from '../../Component/Common/Loader/ButtonLoader';
import CompanyAssetMonitoringForm2 from './CompanyAssetMonitoringForm2';
import { editCompanyAsset, unitType } from './CompanyAssetsTypes/companyAssetTypes';


interface AssetMonitoringProps { }

const AssetMonitoring: FC<AssetMonitoringProps> = () => {
    const { companyAsset } = useParams()
    const [editCompanyAsset, setEditCompanyAsset] = useState<editCompanyAsset | null>(null)
    const [allMonitoringType, setAllMonitoringType] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedMonitoringType, setSelectedMonitoringType] = useState<any[]>([])
    const navigate = useNavigate()
    const [units, setUnits] = useState<unitType[]>([])
    const { isRender, isVisible, buttonLoader, setButtonLoader } = useContext(DataContext)
    const [rangeOpen, setRangeOpen] = useState<number[]>([0]);

    const [initialValues, setInitialValues] = useState({
        monitoring: [],
    })

    const fetchMonitoringType = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringType/getMonitoringTypeForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAllMonitoringType(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching monitoring types');
            }
        }
    };

    const getUnits = async () => {
        try {
            const response: responseType = await http({
                url: `/unit/getUnitForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setUnits(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching units.');
            }
        }
    }

    const fetchcompanyAsset = async () => {
        try {
            const response: responseType = await http({
                url: `/companyAsset/getCompanyAssetForEdit`,
                method: 'get',
                data: { '_id': companyAsset }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditCompanyAsset(response?.data?.data)
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset monitoring type');
            }
        }
    };

    useEffect(() => {
        fetchMonitoringType();
        fetchcompanyAsset();
        // eslint-disable-next-line 
    }, [isRender]);


    const validationSchema = Yup.object().shape({
        monitoring: Yup.array().of(
            Yup.object().shape({
                monitoringType: Yup.string().required('mt is reqd...'),
                unit: Yup.string().required('Unit is required'),
                ranges: Yup.object().shape({
                    minimum: Yup.number().required('Min is required'),
                    maximum: Yup.number().required('Max is required'),
                    alarm: Yup.number().required('Alarm is required'),
                    tripping: Yup.number().required('Tripping is required'),
                })
            })
        )
    });
    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        values['_id'] = editCompanyAsset?._id
        try {
            const response: responseType = await http({
                url: `/companyAsset/editCompanyAsset`,
                method: 'put',
                data: values
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setTimeout(() => {
                    setButtonLoader(false)
                    navigate(-1);
                    resetForm();
                }, 1000);
            } else {
                setButtonLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            setButtonLoader(false)
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error editing monitoring type.');
            }
        }
    }

    useEffect(() => {
        getUnits()
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
        // eslint-disable-next-line  
    }, []);
    const handleMonitoringTypeChange = (e: any) => {
        setSelectedMonitoringType(e);
    }
    const handleMonitoringTypeRemove = (e: any, values: any) => {
        let newValuesWithUpdatedMonitorings = values?.monitoring?.filter((mt: any) => e?.map((item: any) => { return item._id }).includes(mt.monitoringType))
        const newValues = { ...values, 'monitoring': newValuesWithUpdatedMonitorings }
        setInitialValues(newValues)
        setSelectedMonitoringType(e);

    }
    const handleOpen = (index: number) => {
        if (rangeOpen.includes(index)) {
            setRangeOpen(rangeOpen.filter((range: number) => range !== index));
        } else {
            setRangeOpen([...rangeOpen, index])
        }
    }
    const checkPermission = (): boolean => {
        if ((isVisible("assetmonitoring-create") || isVisible("assetmonitoring-update"))) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div className='w-full h-full relative overflow-y-auto scrollbar-thin'>
            {isLoading ?
                <Loader loader={isLoading} />
                : <div className="w-full">
                    {editCompanyAsset?.technology === 'Screw' || editCompanyAsset?.technology === 'Centrifugal' ?
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className='w-full flex flex-col gap-4 pb-4'>
                                    <div className="w-full flex items-center justify-end gap-2 bg-skin-fill-outlet sticky top-0 z-20">
                                        <CancelButton onClick={() => navigate(-1)} />
                                        {(isVisible("assetmonitoring-create") || isVisible("assetmonitoring-update")) && (buttonLoader ? <ButtonLoader /> : <SubmitButton label={'SAVE'} />)}
                                    </div>
                                    <Multiselect
                                        displayValue="name"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={(e: any) => handleMonitoringTypeRemove(e, values)}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={(e: any) => handleMonitoringTypeChange(e)}
                                        options={allMonitoringType}
                                        selectedValues={selectedMonitoringType}
                                        placeholder="Select Monitoring Type"
                                        disable={checkPermission()}
                                    />
                                    <CompanyAssetMonitoringForm2 handleOpen={handleOpen}
                                        rangeOpen={rangeOpen} selectedMonitoring={selectedMonitoringType}
                                        setFieldValue={setFieldValue} units={units} />
                                </Form>
                            )}
                        </Formik>
                        :
                        <div className='w-full h-full flex items-center justify-center'>
                            <div className='flex items-center justify-center w-40 h-40'>
                                <p>
                                    Go Back
                                </p>
                            </div>
                        </div>
                    }
                </div>}
        </div>
    );
}

export default AssetMonitoring;
