import React, { forwardRef, ForwardedRef, useImperativeHandle } from 'react';

interface SubmitButtonProps {
    label?: string;
    onClick?: any
}

const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
    ({ label, onClick }, ref: ForwardedRef<HTMLButtonElement>) => {
        const buttonRef = React.useRef<HTMLButtonElement>(null);

        // Forward the ref to the underlying button element
        useImperativeHandle(ref, () => buttonRef.current!);

        return (
            <button
                onClick={onClick}
                type='submit'
                ref={buttonRef}
                className='bg-skin-button-bgcolor py-1.5 text-skin-button-text px-2.5 rounded text-sm transition-all duration-300  hover:bg-opacity-20 border-secondary border'
            >
                {label ? label : 'SUBMIT'}
            </button>
        );
    }
);
export default SubmitButton;