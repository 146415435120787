import { useEffect, type FC, useState, useContext } from 'react';
import Loader from '../../../Component/Common/Loader/Loader';
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import InputText from '../../../Component/FormControl/InputText';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import { DataContext } from '../../../Context/DataProvider';

interface AssetTypeFormProps { }
interface IassetType {
    name: String
    // code: String
    _id?: any
}

interface assetTypeEdit {
    // code: string
    name: string
    _id?: string
}

const AssetTypeForm: FC<AssetTypeFormProps> = () => {
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const [assetTypeEdit, setAssetTypeEdit] = useState<assetTypeEdit | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { id } = useParams();
    const navigate = useNavigate()

    const initialValues = {
        name: '',
        // code: '',
    }

    const fetchAssetType = async () => {
        try {
            const response: responseType = await http({
                url: `/assetType/getAssetTypeForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAssetTypeEdit(response?.data?.data);
                initialValues.name = response?.data?.data.name;
                // initialValues.code = response?.data?.data.code;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset type.');
            }
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').min(3, 'Too short name').max(50, 'Too long name')
        // code: Yup.string().required('Code is required'),
    });

    const handleSubmit = async (values: IassetType, { resetForm }: any) => {
        setButtonLoader(true)
        if (assetTypeEdit !== null) {
            values['_id'] = assetTypeEdit._id;
            try {
                const response: responseType = await http({
                    url: `/assetType/editAssetType`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/assettype');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error updating asset type.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/assetType/addAssetType`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/assettype');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding asset type.');
                }
            }
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (id !== undefined) {
            fetchAssetType();
        }
        // eslint-disable-next-line 
    }, []);
    return (
        <div className='w-full h-full relative'>
            <div className="w-full px-2 sticky top-0 z-30 bg-skin-fill-formheader">
                <p className='py-1 font-medium text-skin-base text-lg'>{assetTypeEdit == null ? 'Add' : 'Edit'} Asset Type</p>
                <hr />
            </div>
            <Loader loader={isLoading} />
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4 p-3'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            <InputText id='name' name='name' label='Name' autoFocus={true} />
                            {/* <InputText id='code' name='code' disabled={assetTypeEdit !== null ? true : false} label='Code' /> */}
                        </div>
                        <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                            <CancelButton onClick={() => navigate('/home/configuration/assettype')} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton label={assetTypeEdit == null ? 'Add Asset Type' : 'Edit Asset Type'} />}
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default AssetTypeForm;
