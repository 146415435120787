import { useEffect, type FC, useState, Dispatch, SetStateAction, useContext } from 'react';
import { toast } from 'react-toastify';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { DataContext } from '../../../Context/DataProvider';
import { FaLightbulb } from 'react-icons/fa';

interface IndicatorProps {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const Indicator: FC<IndicatorProps> = ({ setLoading }) => {
    const { isRender } = useContext(DataContext);
    const [companyAsset, setCompanyAsset] = useState<any>()
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const fetchCompanyAsset = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringLog/getCompanyAssetLog`,
                method: 'get',
                data: { company: userDetails?.company?._id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompanyAsset(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetch monitoring log');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code !== 'superadmin') {
            fetchCompanyAsset();
        }
        // eslint-disable-next-line 
    }, [isRender])
    return (
        <div className="w-full h-auto cursor-default bg-white text-gray-700 rounded-xl px-3 pt-3 shadow-lg">
            <div className="flex gap-4 overflow-x-scroll scrollbar-thin">
                {companyAsset?.map((asset: any, index: number) =>
                    <div key={index} className="flex flex-col items-center justify-center gap-2">
                        <p className={`${asset?.data?.fallen_range === 'less-min' ? ' text-red-500' : asset?.data?.fallen_range === 'min-max' ? ' text-orange-600' : asset?.data?.fallen_range === 'max-alarm' ? 'text-yellow-400' : asset?.data?.fallen_range === 'alarm-tripping' ? asset?.data?.fallen_range === 'tripping-more' : 'text-green-600'} text-4xl`}><FaLightbulb /></p>
                        <span className='truncate text-sm'>{asset?.data?.companyAsset?.assetNamebyCompany}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Indicator;
