import { useState, type FC, Dispatch, SetStateAction, useEffect } from 'react';
// import { RiArrowRightDownLine, RiArrowRightUpLine } from 'react-icons/ri';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';

interface CompanyUsersCountsProps {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const CompanyUsersCounts: FC<CompanyUsersCountsProps> = ({ setLoading }) => {
    const [companyUsersCounts, setCompanyUsersCounts] = useState<any>()
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const fetchCompanyUsersCount = async () => {
        try {
            const response: responseType = await http({
                url: `/user/getCompanyUsersCount`,
                method: 'get',
                data: { company: userDetails?.company?._id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompanyUsersCounts(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetch company user count');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code !== 'superadmin') {
            fetchCompanyUsersCount();
        }
        // eslint-disable-next-line 
    }, [])
    return (
        <div className="w-full h-auto cursor-default bg-white text-gray-700 hover:bg-blue-950 hover:text-white flex flex-col justify-between rounded-xl p-2 px-3 shadow-lg">
            <p className="text-2xl font-semibold">Users</p>
            <div className="pt-2 pb-2.5 flex items-baseline gap-3">
                <div className="text-6xl font-bold flex items-center">
                    {companyUsersCounts?.count}
                </div>
                {/* <div className={`${companyUsersCounts?.percentageChange < 0 ? 'text-red-500' : companyUsersCounts?.percentageChange >= 0 && 'text-green-500'} flex items-center`}>
                    {companyUsersCounts?.percentageChange !== null && companyUsersCounts?.percentageChange < 0 ? companyUsersCounts?.percentageChange?.toString()?.slice(1) : companyUsersCounts?.percentageChange >= 0 ? companyUsersCounts?.percentageChange : '0'}%
                    {companyUsersCounts?.percentageChange !== null && companyUsersCounts?.percentageChange < 0 ? <RiArrowRightDownLine className="text-[20px]" /> : <RiArrowRightUpLine className="text-[20px]" />}
                </div> */}
            </div>
        </div>
    );
}

export default CompanyUsersCounts;
