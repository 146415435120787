import type { FC } from 'react';

interface CardProps {
    title?:string
    description?:string
    body?:any
    click?:any
}

const Card: FC<CardProps> = ({title,description,body,click}) => {
    return (
        <div className="w-full h-full flex text-skin-base flex-col">
            <div className='h-40 w-full'>{body}</div>
            <div className='flex py-3 px-2 h-20 flex-col justify-between'>
            <h1 onClick={click} className={`${click !== undefined && 'cursor-pointer'} font-medium`}>{title}</h1>
            <p className='text-sm'>{description}</p>
            </div>
        </div>
    );
}

export default Card;
