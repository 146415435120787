import { useState, type FC, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ActionPop from '../../../Component/Common/ActionPop/ActionPop';
import Counter from '../../../Component/Common/Counter';
import DeleteModal from '../../../Component/Common/DeleteModal';
import Loader from '../../../Component/Common/Loader/Loader';
import SearchFilter from '../../../Component/Common/SearchFilter';
import { responseType, IactionPopAction } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import { DataContext } from '../../../Context/DataProvider';
import http from '../../../Services/http/http';
import Pagination from '../../../Component/Common/Pagination';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';

interface PlantProps { }

const Plant: FC<PlantProps> = () => {
    const { company } = useParams()
    const [plantData, setPlantData] = useState<any[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [id, setId] = useState<string>();
    const navigate = useNavigate();
    const { isRender, setIsRender, selectedLimit, isVisible, dateFilter } = useContext(DataContext)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [skip, setSkip] = useState(0)
    const [counts, setCounts] = useState<number>(0)
    const [searchFilter, setSearchFilter] = useState<string>('')

    const handleEdit = () => {
        navigate(`/home/configuration/editplant/${id}`)
    }

    const DeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }
    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/plant/deletePlant`,
                method: 'delete',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting plant.');
            }
        }
    }
    const action: IactionPopAction[] = []
    isVisible('plant-update') && action.push({ id: 1, name: 'Edit', click: handleEdit });
    isVisible('plant-delete') && action.push({ id: 2, name: 'Delete', click: DeleteModalOpen });

    const fetchPlant = async () => {
        try {
            let data: any = {
                company: company,
                search: searchFilter,
                dateFilter: dateFilter
            }
            if (searchFilter === '') {
                data.skip = skip;
                data.limit = selectedLimit;
            }
            const response: responseType = await http({
                url: `/plant/getPlant`,
                method: 'get',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setPlantData(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching plant');
            }
        }
    };

    const getPlant = async () => {
        try {
            const response: responseType = await http({
                url: `/plant/getPlantCountPagination`,
                method: 'get',
                data: {
                    company: company,
                    search: searchFilter,
                    dateFilter: dateFilter
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCounts(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching count.');
            }
        }
    }
    useEffect(() => {
        getPlant();
        // eslint-disable-next-line 
    }, [searchFilter, isRender, company, dateFilter]);
    useEffect(() => {
        fetchPlant();
        // eslint-disable-next-line 
    }, [isRender, skip, searchFilter, company, dateFilter]);
    useEffect(() => {
        setIsLoading(true);
        // eslint-disable-next-line 
    }, [skip, selectedLimit]);
    useEffect(() => {
        setSkip(0)
        // eslint-disable-next-line 
    }, [selectedLimit]);
    return (
        <div className='h-full w-full relative  rounded-2xl'>
            <Loader loader={isLoading} />
            <div className='flex items-center justify-between sm:pb-4 pb-2 '>
                <Counter skip={skip} counts={counts} limit={selectedLimit} />
                <SearchFilter setSearch={setSearchFilter} />
            </div>
            {counts === 0 ?
                <DataNotFoundPage />
                :
                <div className="flex sm:h-[90%] h-[92%]  flex-col w-full">
                    <div className="w-full h-auto overflow-auto scrollbar-thin">
                        <table className="w-full mb-2 text-left">
                            <thead className=" px-6   bg-skin-fill-table-head text-skin-base-table-head-text z-10  sticky top-0">
                                <tr>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        S.No.
                                    </th>
                                    <th scope="col" className="sticky left-0 bg-skin-fill-table-head px-4 py-3 font-medium truncate">
                                        Name
                                    </th>
                                    <th scope="col" className="sticky left-0 bg-skin-fill-table-head px-4 py-3 font-medium truncate">
                                        Company
                                    </th>
                                    {(isVisible('plant-update') || isVisible('plant-delete')) ?
                                        <th scope="col" className="py-3 font-medium truncate">
                                            Actions
                                        </th>
                                        : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {plantData?.map((plant, index) =>
                                    <tr key={plant._id} className=" bg-skin-fill-table  hover:bg-skin-fill-hover hover:text-skin-base-table-hover hover:bg-opacity-10 text-skin-base-table-body-text   border-b border-skin-table-color group">
                                        <td className="px-4 py-[8px] ">
                                            {searchFilter ? (index + 1) : (skip + (index + 1))}
                                        </td>
                                        <td className="sticky left-0 group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px]  truncate">
                                            {plant?.name}
                                        </td>
                                        <td className="sticky left-0 group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px]  truncate">
                                            {plant?.company?.name}
                                        </td>
                                        {(isVisible('plant-update') || isVisible('plant-delete')) ?
                                            <td className="px-4 py-[8px] " >
                                                <span onClick={() => setId(plant?._id)}><ActionPop action={action} icon={'FiMoreVertical'} />
                                                </span>
                                            </td>
                                            : null
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {(counts > selectedLimit && searchFilter === '') && <Pagination limit={selectedLimit} setSkip={setSkip} itemsCount={counts} />}
                </div>
            }
            <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='Plant' />
        </div>

    );
}

export default Plant;
