import { FC, useContext, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataContext } from '../../../Context/DataProvider';
import { IactionPopAction, responseType } from '../TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from "react-i18next";
import ActionPop from '../ActionPop/ActionPop';
import { MdOutlineNotes } from 'react-icons/md';
import { ImTree } from 'react-icons/im'
import DiaGramPopUp from '../PoUp/DiaGramPopUp';

interface TopNavProps {
    sidebarOpen: boolean
    setSidebarOpen: (value: boolean) => void
    sidebarWidth: boolean
}

const TopNav: FC<TopNavProps> = ({ sidebarOpen, sidebarWidth }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { isVisible, setDateFilter, sideNavCollapse, setSideNavCollapse } = useContext(DataContext);
    const [openDiagram, setOpenDiagram] = useState<boolean>(false)
    const { company } = useParams()
    const [companyDetail, setCompanyDetail] = useState<{ _id: string, name: string } | null>(null)
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '[]')
    const pageTitle = pathname?.split('/')?.[2]?.charAt(0)?.toUpperCase() + pathname?.split('/')?.[2]?.slice(1)
    const { t } = useTranslation('title');
    const handleFilterClick = (e: any) => {
        setDateFilter(e.target.innerText);
    }
    const dataFilterAction: IactionPopAction[] = [
        { id: 1, title: 'Filter By' },
        { id: 2, title: 'Today', click: handleFilterClick },
        { id: 3, title: 'This Week', click: handleFilterClick },
        { id: 4, title: 'This Month', click: handleFilterClick }
    ]
    const getCompany = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getOneCompany`,
                method: 'get',
                data: { _id: company }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompanyDetail(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching User.');
            }
        }
    }
    useEffect(() => {
        if (company !== undefined) {
            getCompany()
        }
        // eslint-disable-next-line
    }, [pathname])
    const addRole = () => {
        navigate('/home/configuration/addRole');
    }
    const addCompany = () => {
        navigate('/home/configuration/addcompany');
    }
    const addAsset = () => {
        navigate('/home/configuration/addasset');
    }
    const addAssetType = () => {
        navigate('/home/configuration/addassettype');
    }
    const addMonitoringtype = () => {
        navigate('/home/configuration/addmonitoringtype');
    }
    const addUnit = () => {
        navigate('/home/configuration/addUnit');
    }

    const addUser = () => {
        navigate('/home/addUser');
    }
    const addCompanyAsset = () => {
        navigate(`/home/addCompanyAsset`);
    }
    const addProblemSolution = () => {
        navigate('/home/configuration/addproblemsolution');
    }
    const addState = () => {
        navigate('/home/configuration/addstate');
    }
    const addCountry = () => {
        navigate('/home/configuration/addcountry');
    }

    return (
        <>
            <nav className='fixed top-0 z-50 w-full bg-skin-fill border-gray-200'>
                <div className="px-3 h-16 lg:px-5 lg:pl-3">
                    <div className="flex h-full items-center justify-between">
                        <div className="flex items-center justify-start gap-2">
                            {pathname?.split('/')[2] === 'configuration' &&
                                <span onClick={() => setSideNavCollapse(!sideNavCollapse)} className='text-2xl text-skin-base cursor-pointer sm:hidden'><MdOutlineNotes /></span>
                            }
                            <p className={`${sidebarWidth === true || sidebarOpen === true ? ' sm:pl-16' : ' sm:pl-[250px]'} pl-1 text-xl text-skin-base font-medium transition-all duration-1000`}>
                                <Trans>{pageTitle}</Trans>
                                {userDetails?.role?.code === 'superadmin' && (pathname === `/home/companyUser/${company}` || pathname === `/home/companyAsset/${company}`) && ' / ' + companyDetail?.name}
                            </p>
                        </div>
                        <div className="flex gap-2">
                            {/* date Filter */}
                            {(
                                // pathname?.split('/')[3] === 'plant' ||
                                // pathname?.split('/')[3] === 'segment' ||
                                // pathname?.split('/')[3] === 'companyadmin' ||
                                // pathname?.split('/')[3] === 'assettype' ||
                                // pathname?.split('/')[3] === 'monitoringtype' ||
                                pathname?.split('/')[3] === 'rolesTable' ||
                                pathname?.split('/')[3] === 'company' ||
                                pathname?.split('/')[3] === 'unit' ||
                                pathname?.split('/')[3] === 'country' ||
                                pathname?.split('/')[3] === 'state' ||
                                pathname?.split('/')[2] === 'companyUser'
                            )
                                && <ActionPop icon='BsFilter' action={dataFilterAction} />
                            }
                            {/* Tree Controller Button */}
                            {(pathname === '/home/dashboard' && userDetails.role.code !== 'superadmin') && <span onClick={() => setOpenDiagram(!openDiagram)} className='text-white border border-white rounded-full p-2.5 cursor-pointer text-2xl'><ImTree /></span>}
                            {/* icons according to routes */}
                            {isVisible('role-create') && (pathname === '/home/configuration/rolesTable' || pathname === '/home/configuration') &&
                                <button title='Add Role' onClick={addRole} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Role')}</span>
                                </button>
                            }
                            {isVisible('company-create') && pathname === '/home/configuration/company' &&
                                <button title='Add Company' onClick={addCompany} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Company')}</span>
                                </button>
                            }
                            {isVisible('asset-create') && pathname === '/home/configuration/asset' &&
                                <button title='Add Asset' onClick={addAsset} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Asset')}</span>
                                </button>}
                            {isVisible('assettype-create') && pathname === '/home/configuration/assettype' &&
                                <button title='Add Asset Type' onClick={addAssetType} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Asset Type')}</span>
                                </button>
                            }
                            {isVisible('unit-create') && pathname === '/home/configuration/unit' &&
                                <button title='Add Unit' onClick={addUnit} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Unit')}</span>
                                </button>
                            }
                            {isVisible('monitoringtype-create') && pathname === '/home/configuration/monitoringtype' &&
                                <button title='Add Monitoring Type' onClick={addMonitoringtype} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Monitoring Type')}</span>
                                </button>
                            }
                            {isVisible('state-create') && pathname === '/home/configuration/state' &&
                                <button title='Add State' onClick={addState} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add State')}</span>
                                </button>
                            }
                            {isVisible('country-create') && pathname === '/home/configuration/country' &&
                                <button title='Add Country' onClick={addCountry} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Country')}</span>
                                </button>
                            }

                            {isVisible('problemsolution-create') && pathname === '/home/configuration/problemsolution' &&
                                <button title='Add Troubleshoots' onClick={addProblemSolution} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Troubleshoots')}</span>
                                </button>
                            }

                            {isVisible('user-create') && (pathname === '/home/user' || pathname === '/home/company/U') &&
                                <button title='Add User' onClick={addUser} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add User')}</span>
                                </button>
                            }
                            {isVisible('companyasset-create') && (pathname === '/home/companyAsset' || pathname === '/home/company/A') &&
                                <button title='Add Company Asset' onClick={addCompanyAsset} type="button" className="flex items-center gap-1 text-sm text-skin-base-Topnav-button-text bg-skin-fill-Topnav-button px-2 py-1.5 rounded-md" >
                                    <AiOutlinePlus />
                                    <span>{t('Add Company Asset')}</span>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </nav>
            <DiaGramPopUp openPopUp={openDiagram} setOpenPopUp={setOpenDiagram} />
        </>
    )
}

export default TopNav;
