import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import * as Yup from 'yup'
import CancelButton from '../FormControl/CancelButton';
import { responseType } from './TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

interface FormBuilderProps {
    setActiveStep: Dispatch<SetStateAction<number>>
    editData: any
}

const FormBuilder: FC<FormBuilderProps> = ({ setActiveStep, editData }) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const { pathname } = useLocation();
    const initialValues = {
        items: (editData && editData?.profiling.length > 0)
            ?
            editData?.profiling?.map((item: any) => ({
                label: item?.label ?? "",
                type: item?.type ?? "text",
                required: item?.required ?? "",
                options: item?.options?.join(',') ?? "",
            }))
            :
            [{ label: '', type: 'text', required: false, options: '' }],
    }
    const Types = [
        'Checkbox',
        'Date',
        'Email',
        'Number',
        'Textarea',
        'Radio',
        'Select',
        'Text',
    ];

    const forbiddenLabels = ['name', 'Name', 'Serial number', 'serial number', 'serial Number', 'Serial Number', 'serialnumber', 'SerialNumber'];
    const validationSchema = Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                label: Yup.string()
                    .matches(/^[a-zA-Z_ ]*$/, 'Only letters,spaces,and underscores are allowed')
                    .notOneOf(forbiddenLabels, 'Key already exists')
                    .required('Label is required'),
                type: Yup.string(),
                required: Yup.boolean().required('Required is required'),
                options: Yup.string().when('type', ([type]) => {
                    return !['select', 'checkbox', 'radio'].includes(type)
                        ? Yup.string()
                        : Yup.string().required('Options are required for select, checkbox, or radio type');
                }),
            })
        ),
    });

    const handleSubmit = async (values: any) => {
        let finalValues: any = [];
        values?.items?.forEach(async (value: any) => {
            await finalValues?.push({ label: value?.label?.toLowerCase()?.split(/[ _]/)?.join('_'), type: value?.type?.toLowerCase(), required: value?.required, options: value?.options ? value?.options?.split(',') : [] })
        });
        try {
            const _id = editData?._id ?? sessionStorage.getItem('lca');
            const response: responseType = await http({
                url: `/asset/editAsset`,
                method: 'put',
                data: { _id, profiling: finalValues },
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setTimeout(() => {
                    setActiveStep(2)
                }, 1000);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error Adding AssetType.');
            }
        }
    }
    const handleSkip = () => {
        setActiveStep(2)
    }
    const handleNext = () => {
        setActiveStep(2)
    }
    return (
        <div className="w-full h-auto overflow-x-auto scrollbar-none mt-1">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values }) => (
                    <Form className='relative'>
                        <FieldArray name="items">
                            {(arrayHelpers: any) => (
                                <>
                                    <table className='w-full h-auto'>
                                        <thead>
                                            <tr className='py-1 bg-white'>
                                                <th className='w-8 px-2'>Add</th>
                                                <th className='text-center w-fit'>Label</th>
                                                <th className='text-center w-fit'>Type</th>
                                                <th className='text-center w-fit px-2'>Required</th>
                                                <th className='text-center w-fit'>Options</th>
                                                <th className='w-10 px-2'>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.items.map((item: any, index: number) => (
                                                <tr key={index} className='text-sm border text-center group'>
                                                    <td className='w-8 text-lg px-2 border'>
                                                        <BsPlusLg className=' rounded-sm border cursor-pointer hidden group-hover:block' onClick={() => {
                                                            arrayHelpers.insert(index + 1, { label: '', type: 'text', required: false, options: '' });
                                                        }} />
                                                    </td>
                                                    <td className='text-center border pt-[4px]'>
                                                        <Field className='px-2 py-1 outline-none rounded text-xs bg-white' type="text" name={`items[${index}].label`} />
                                                        <p className="text-xs h-[14px] text-red-500" ><ErrorMessage name={`items[${index}].label`} /></p>
                                                    </td>
                                                    <td className='text-center px-2 flex pt-[4px] flex-col '>
                                                        <Field className='px-2 py-1 outline-none rounded text-xs bg-white' as="select" name={`items[${index}].type`}>
                                                            {Types?.map((options: string) => (
                                                                <option key={options} value={options?.toLowerCase()} label={options} />
                                                            ))}
                                                        </Field>
                                                        <p className="text-xs h-[14px] text-red-500" ><ErrorMessage name={`items[${index}].type`} /></p>
                                                    </td>
                                                    <td className='text-center border pt-[4px]'>
                                                        <Field className='px-2 py-1 outline-none rounded text-xs bg-white' type="checkbox" name={`items[${index}].required`} />
                                                    </td>
                                                    <td className='text-center border pt-[4px]'>
                                                        <Field className='px-2 py-1 outline-none rounded text-xs bg-white' type="text" name={`items[${index}].options`} />
                                                        <p className="text-xs h-[14px] text-red-500" ><ErrorMessage name={`items[${index}].options`} /></p>
                                                    </td>
                                                    <td className='w-10 text-lg px-2 border'>
                                                        <MdDelete onClick={() => { if (arrayHelpers.form.values.items.length > 1) { arrayHelpers.remove(index) } }} className='text-red-500 text-2xl cursor-pointer' />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </FieldArray>
                        <div className="w-full float-right flex gap-2 mt-2 justify-end bg-skin-fill-outlet">
                            {pathname.split('/')[3] === 'editasset'
                                ? <CancelButton onClick={handleNext} label='NEXT' />
                                :
                                <CancelButton onClick={handleSkip} label='SKIP' />
                            }
                            <button
                                type='submit'
                                ref={buttonRef}
                                className='bg-skin-button-bgcolor py-1.5 text-skin-button-text px-2.5 rounded text-sm transition-all duration-300  hover:bg-opacity-20 border-secondary border'
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default FormBuilder;
