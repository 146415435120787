import { useState, type FC, useContext, useEffect } from 'react';
import Loader from '../../Component/Common/Loader/Loader';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputSelect from '../../Component/FormControl/InputSelect';
import InputEmail from '../../Component/FormControl/InputEmail';
import InputText from '../../Component/FormControl/InputText';
import InputPassword from '../../Component/FormControl/InputPassword';
import CancelButton from '../../Component/FormControl/CancelButton';
import SubmitButton from '../../Component/FormControl/SubmitButton';
import { useNavigate, useParams } from 'react-router-dom';
import { DataContext } from '../../Context/DataProvider';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import ButtonLoader from '../../Component/Common/Loader/ButtonLoader';
import InputSelectCountryCode from '../../Component/FormControl/InputSelectCountryCode';
import InputNumber from '../../Component/FormControl/InputNumber';
import { Salutation, countryCode } from '../../Component/FormControl/CountryCode';

interface UserFormProps { }

const UserForm: FC<UserFormProps> = () => {
    const [allCompany, setAllCompany] = useState<string[]>([]);
    const [allRoles, setAllRoles] = useState<string[]>([]);
    const [alluser, setAlluser] = useState<any | null>(null);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate()
    const { isRender, buttonLoader, setButtonLoader } = useContext(DataContext)
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');

    const initialValues = {
        role: '',
        name: '',
        email: '',
        password: '',
        primaryPhone: '',
        primaryPhoneCode: '+91',
        workPhoneCode: '+91',
        workPhone: '',
        salutation: 'Mr',
        company: ''
    }

    const fetchRoles = async () => {
        try {
            const response: responseType = await http({
                url: `/role/getRoleForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAllRoles(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching roles.');
            }
        }
    };
    const fetchCompanies = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getCompanyForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAllCompany(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company.');
            }
        }
    };

    const fetchUser = async () => {
        try {
            const response: responseType = await http({
                url: `/user/getCompanyUsersForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAlluser(response?.data?.data);
                initialValues.name = response?.data?.data.name?.split(' ')[1];
                initialValues.primaryPhone = response?.data?.data?.primaryPhone?.split('-')[1];
                initialValues.primaryPhoneCode = response?.data?.data?.primaryPhone?.split('-')[0];
                initialValues.workPhoneCode = response?.data?.data?.workPhone?.split('-')[0];
                initialValues.workPhone = response?.data?.data?.workPhone?.split('-')[1];
                initialValues.salutation = response?.data?.data?.name?.split(' ')[0];
                initialValues.email = response?.data?.data?.email;
                initialValues.role = response?.data?.data?.role?._id;
                initialValues.company = response?.data?.data?.company?._id;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching user.');
            }
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (id !== undefined) {
            fetchUser();
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        fetchRoles();
        fetchCompanies();
        // eslint-disable-next-line 
    }, [isRender]);
    let validationSchema:any = Yup.object().shape({
        role: Yup.string().required('Role is required'),
        name: Yup.string().required('Full Name is required'),
        primaryPhone: Yup.string().required('Primary phone number is required').matches(/^[0-9]{10}$/, 'Must be exactly 10 digits'),
        workPhone: Yup.string().required('Work phone number is required').matches(/^[0-9]{10}$/, 'Must be exactly 10 digits'),
        email: Yup.string().required('Email is required').email('Invalid Email'),
        password: Yup.string().required('Password is required').min(4, 'Your password is too short'),
    });
    if (userDetails.role.code === 'superadmin') {
        validationSchema = validationSchema.concat(
            Yup.object().shape({
                company: Yup.string().required('Company Type is required'),
            })
        );
    }


    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        const final_values: any = {};
        final_values['name'] = `${values.salutation} ${values.name}`;
        final_values['primaryPhone'] = `${values.primaryPhoneCode}-${values.primaryPhone}`;
        final_values['workPhone'] = `${values.workPhoneCode}-${values.workPhone}`;
        final_values['role'] = values.role;
        final_values['email'] = values.email;
        final_values['password'] = values.password;
        final_values['company'] = values.company;

        if (alluser !== null) {
            final_values['_id'] = id;
            try {
                const response: responseType = await http({
                    url: `/user/editCompanyUsers`,
                    method: 'put',
                    data: final_values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate(-1);
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error updating company users.');
                }
            }
        } else {
            if (values.company === '') {
                final_values['company'] = userDetails?.company?._id
            }
            try {
                const response: responseType = await http({
                    url: `/user/addUser`,
                    method: 'post',
                    data: final_values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        if (userDetails?.role?.code === 'superadmin') {
                            navigate('/home/company/U');
                        } else {
                            navigate('/home/company/U');
                        }
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error fetching User.');
                }
            }
        }

    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        // eslint-disable-next-line 
    }, []);
    return (
        <div className='w-full h-full relative'>
            <Loader loader={isLoading} />
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4 p-3'>
                        {userDetails.role.code === 'superadmin' ? <InputSelect name='company' id='company' label='Company' Array={allCompany} disabled={alluser !== null ? true : false} /> : null}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            <div className='flex  w-full'>
                                <div className='w-16'>
                                    <InputSelectCountryCode name='salutation' id='salutation' label='' Array={Salutation} isCorner={true} />
                                </div>
                                <div className='w-full'>
                                    <InputText name='name' id='name' label='Full name' isCorner={true} />
                                </div>
                            </div>
                            <InputSelect name='role' id='role' label='Role' Array={allRoles} disabled={alluser !== null} />
                            <InputEmail name='email' id='email' label='Email' disabled={alluser !== null} />
                            <div className='flex  w-full'>
                                <div className='w-20'>
                                    <InputSelectCountryCode name='primaryPhoneCode' id='primaryPhoneCode' label='' Array={countryCode} isCorner={true} />
                                </div>
                                <div className='w-full'>
                                    <InputNumber name='primaryPhone' id='primaryPhone' label='primary phone' isCorner={true} />
                                </div>
                            </div>
                            <div className='flex w-full'>
                                <div className='w-20'>
                                    <InputSelectCountryCode name='workPhoneCode' id='workPhoneCode' label='' Array={countryCode} isCorner={true} />
                                </div>
                                <div className='w-full'>
                                    <InputNumber name='workPhone' id='workPhone' label='Work phone' isCorner={true} />
                                </div>
                            </div>
                            <InputPassword name='password' id='password' label='Password' />
                        </div>
                        <div className="w-full float-right bg-skin-fill-outlet flex gap-2 justify-end">
                            <CancelButton onClick={() => navigate(-1)} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton />}
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default UserForm;
