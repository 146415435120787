import { useState, type FC, useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../Component/Common/Loader/Loader';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import { DataContext } from '../../../Context/DataProvider';
import http from '../../../Services/http/http';
import CancelButton from '../../../Component/FormControl/CancelButton';
import { useNavigate } from 'react-router-dom';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { IoIosArrowForward } from 'react-icons/io';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';

interface ProblemSolutionFormForAssetProps {
    setActiveStep: Dispatch<SetStateAction<number>>
    editData: any
}


const ProblemSolutionFormForAsset: FC<ProblemSolutionFormForAssetProps> = ({ setActiveStep, editData }) => {
    const [problemSolutionData, setProblemSolutionData] = useState<any[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { isRender, buttonLoader, setButtonLoader } = useContext(DataContext)
    const [rangeOpen, setRangeOpen] = useState<number[]>([0]);
    const navigate = useNavigate()
    const [selectedItem, setSelectedItem] = useState<string[]>([])

    const handleCheckboxChange = (ProblemSolution: any) => {
        if (selectedItem?.find((item) => ProblemSolution?._id === item)) {
            setSelectedItem(selectedItem?.filter((item) => ProblemSolution?._id !== item))
        } else {
            setSelectedItem([...selectedItem, ProblemSolution?._id])
        }
    }
    const handleOpen = (index: number) => {
        if (rangeOpen.includes(index)) {
            setRangeOpen([])
        } else {
            setRangeOpen([index])
        }
    }

    const fetchProblemSolution = async () => {
        try {
            const _id = editData?._id ?? sessionStorage.getItem('lca');
            const response: responseType = await http({
                url: `/troubleshootingTip/getTroubleshootingTipForAsset`,
                method: 'get',
                data: { AssetId: _id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setProblemSolutionData(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching Problem & solution.');
            }
        }
    };

    const handleSubmit = async () => {
        setButtonLoader(true)
        try {
            const _id = editData?._id ?? sessionStorage.getItem('lca');
            const response: responseType = await http({
                url: `/asset/editAsset`,
                method: 'put',
                data: { _id, troubleshoots: selectedItem },
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                sessionStorage.removeItem('lca');
                sessionStorage.removeItem('as');
                setTimeout(() => {
                    setActiveStep(3)
                }, 1000);
                setTimeout(() => {
                    setButtonLoader(false)
                    navigate('/home/configuration/asset')
                }, 2000);
            } else {
                setButtonLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            setButtonLoader(false)
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error editing asset');
            }
        }
    }
    const handleSkip = () => {
        sessionStorage.removeItem('lca');
        sessionStorage.removeItem('as');
        setActiveStep(3)
        setTimeout(() => {
            navigate('/home/configuration/asset')
        }, 1000);
    }
    useEffect(() => {
        fetchProblemSolution();
        if (editData) {
            setSelectedItem(editData?.troubleshoots ?? [])
        }
        // eslint-disable-next-line 
    }, [isRender]);
    return (
        <div className='h-full w-full relative  rounded-2xl'>
            <Loader loader={isLoading} />
            {problemSolutionData?.length === 0 ?
                <DataNotFoundPage />
                :
                <>
                    <div className="flex sm:h-[90%] h-[92%]  flex-col w-full">
                        <div className="w-full h-auto ">
                            <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet mb-2">
                                <CancelButton onClick={handleSkip} label='SKIP' />
                                {buttonLoader ?
                                    <div className='bg-skin-button-bgcolor py-1.5 text-skin-button-text px-6 rounded text-sm transition-all duration-300  hover:bg-opacity-20 border-secondary border'>
                                        <div className="w-5 h-5  border-t-2  border-r-0 border-b-0 border-red-600 border-solid rounded-full animate-spin"></div>
                                    </div>
                                    :
                                    <button
                                        type='button'
                                        onClick={handleSubmit}
                                        className='bg-skin-button-bgcolor py-1.5 text-skin-button-text px-2.5 rounded text-sm transition-all duration-300  hover:bg-opacity-20 border-secondary border'
                                    >
                                        SUBMIT
                                    </button>}
                            </div>
                            <div className='w-full flex gap-2 flex-col '>
                                {problemSolutionData?.map((ProblemSolution: any, index: number) => (
                                    <div key={index} className='flex items-center pl-1'>
                                        <div className="flex w-4 mr-2 h-4 items-center">
                                            <input
                                                value={ProblemSolution?._id}
                                                id={`checkbox-${ProblemSolution._id}`}
                                                type="checkbox"
                                                checked={selectedItem?.find((selected: any): any => ProblemSolution._id === selected) ? true : false}
                                                onChange={() => handleCheckboxChange(ProblemSolution)}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 " />
                                            <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                        </div>
                                        <Accordion open={rangeOpen.includes(index)}>
                                            <AccordionHeader onClick={() => handleOpen(index)} className='flex w-full justify-start py-2 bg-skin-fill-company-table-bg text-skin-company-mainText font-medium'>
                                                <IoIosArrowForward className={`transition-all duration-200 ${rangeOpen.includes(index) ? 'rotate-90' : 'rotate-0'}`} />
                                                <p className='text-sm ps-1'>{ProblemSolution?.problem} ?</p>
                                            </AccordionHeader>
                                            <AccordionBody className='py-0'>
                                                <div key={ProblemSolution?._id} className='w-full bg-skin-fill-company-table-bg h-auto flex items-center px-4 py-1 shadow-sm group'>
                                                    <div className='px-3 w-full text-skin-company-taible-text'>
                                                        <div dangerouslySetInnerHTML={{ __html: ProblemSolution?.solution }} />
                                                    </div>
                                                </div>
                                            </AccordionBody>
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >

    );
}

export default ProblemSolutionFormForAsset;
