import { useState, type FC, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import MyCarousel from '../../Component/Common/Carousel/Carousel';
import Loader from '../../Component/Common/Loader/Loader';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { IoIosArrowForward } from 'react-icons/io';
import AreaChart from '../../Component/Common/Charts/AreaChart';
import DataNotFoundPage from '../../Component/Common/DataNotFoundPage';

interface LogProgressProps { }

const LogProgress: FC<LogProgressProps> = () => {
    const [problemSolutionData, setProblemSolutionData] = useState<any[] | null>(null)
    const [rangeOpenProblemSolution, setRangeOpenProblemSolution] = useState<number[]>([]);
    const [rangeOpen, setRangeOpen] = useState<number[]>([]);
    const { companyAssetId } = useParams();
    const [logProgress, setlogProgress] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [graphData, setGraphData] = useState<any[] | null>(null)

    const handleOpen = (index: number) => {
        if (rangeOpen.includes(index)) {
            const updatedRange = rangeOpen.filter((item) => item !== index);
            setRangeOpen(updatedRange);
        } else {
            setRangeOpen([...rangeOpen, index])
        }
    }

    const handleOpenProblemSolution = (index: number) => {
        if (rangeOpenProblemSolution.includes(index)) {
            setRangeOpenProblemSolution([])
        } else {
            setRangeOpenProblemSolution([index])
        }
    }

    const fetchCompanyAssetProgress = async () => {
        try {
            const response: responseType = await http({
                url: `/companyAsset/getCompanyAssetProgress`,
                method: 'get',
                data: { _id: companyAssetId }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setlogProgress(response?.data?.data);
                setProblemSolutionData(response?.data?.data?.troubleshoots)
                setTimeout(() => {
                    setIsLoading(false)
                }, 2000);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company asset progress');
            }
        }
    }
    const fetchCompanyAssetLogsForProgress = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringLog/getCompanyAssetLogsForProgress`,
                method: 'get',
                data: { companyAsset: companyAssetId }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setGraphData(response?.data?.data);
                setTimeout(() => {
                    setIsLoading(false)
                }, 2000);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching logProgress detail');
            }
        }
    }

    useEffect(() => {
        fetchCompanyAssetProgress();
        fetchCompanyAssetLogsForProgress();
        setTimeout(() => {
            setIsLoading(false)
        }, 5000);
        // eslint-disable-next-line
    }, [companyAssetId])

    return (
        <div className="w-full h-full flex flex-col xl:flex-row relative overflow-y-auto scrollbar-none">
            <Loader loader={isLoading} />
            {/* Left Portion */}
            <div className='p-2 w-full xl:w-[30%] h-min xl:h-full'>
                <div className="max-w-sm bg-skin-fill-card h-64 border  flex flex-col justify-between  rounded-xl relative ">
                    <div className="">
                        <MyCarousel items={logProgress?.images} />
                    </div>
                    {/* <div className="text-skin-base flex group flex-col gap-4 p-3">
                        <span>{logProgress?.name}</span>
                        <span>{logProgress?.serialnumber ?? 'Not Set'}</span>
                        {logProgress?.profile &&
                            <div className="cursor-pointer hidden group-hover:block text-skin-company-taible-text group-hover:text-skin-company-mainText group-hover:overflow-y-visible group-hover:absolute group-hover:w-fit group-hover:bg-gray-100 group-hover:mb-0 group-hover:h-fit group-hover:px-2 px-2 py-2 group-hover:py-2 group-hover:rounded-md group-hover:z-40 mb-2">
                                {Object.entries(logProgress?.profile)?.map(([key, value], index): any => (
                                    <p key={index} className='truncate px-1'>
                                        <strong>{key[0]?.toUpperCase() + key?.slice(1)} :</strong> <span>{typeof (value) === 'object' ? JSON.parse(JSON.stringify(value)).join(' , ') : value}</span>
                                    </p>
                                ))}
                            </div>
                        }
                    </div> */}
                </div>
                <div>
                    <div className="p-1 rounded-md mt-12">
                        {logProgress?.monitoring?.length > 0 &&
                            <h1 className='font-medium flex items-center gap-1 w-full pb-1'>
                                <span className='w-full border-b border-gray-700'></span>
                                <span>Ranges</span>
                                <span className='w-full border-b border-gray-700'></span>
                            </h1>}
                        {logProgress?.monitoring && logProgress?.monitoring?.map((mt: any, index: number) => (
                            <Fragment key={index}>
                                <Accordion open={!rangeOpen.includes(index)}>
                                    <AccordionHeader onClick={() => handleOpen(index)} className='h-6 text-gray-700 text-base flex w-full justify-start bg-opacity-40 my-0.5'><IoIosArrowForward className={`transition-all duration-200 ${rangeOpen.includes(index) ? 'rotate-90' : 'rotate-0'}`} />{mt?.monitoringType?.name}</AccordionHeader>
                                    <AccordionBody className='py-1'>
                                        <div className='flex gap-2 flex-col'>
                                            {mt?.ranges && (
                                                <div className="flex gap-2 rounded-md shadow text-gray-700 text-sm bg-opacity-70 px-1 py-0.5">
                                                    <div className="flex gap-1">
                                                        <p>{mt?.ranges?.alarm}</p>
                                                        <span>-</span>
                                                        <p>{mt?.ranges?.maximum}</p>
                                                        <span>-</span>
                                                        <p>{mt?.ranges?.minimum}</p>
                                                        <span>-</span>
                                                        <p>{mt?.ranges?.tripping}</p>
                                                    </div>
                                                    <p>{mt?.unit?.name ?? 'Unit name not provided'}</p>
                                                </div>
                                            )}
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            </Fragment>
                        ))}
                    </div>
                    <div className='w-full h-min mt-2 flex gap-2 flex-col '>
                        {problemSolutionData &&
                            <h1 className='font-medium flex items-center gap-1 w-full pb-1'>
                                <span className='w-full border-b border-gray-700'></span>
                                <span>Suggestions</span>
                                <span className='w-full border-b border-gray-700'></span>
                            </h1>}
                        {problemSolutionData !== null ? problemSolutionData?.map((ProblemSolution: any, index: number) => (
                            <div key={index} className='flex items-center pl-1'>
                                <Accordion open={rangeOpenProblemSolution.includes(index)}>
                                    <AccordionHeader onClick={() => handleOpenProblemSolution(index)} className='flex w-full justify-start py-2 bg-skin-fill-company-table-bg text-skin-company-mainText font-medium'>
                                        <IoIosArrowForward className={`transition-all duration-200 ${rangeOpenProblemSolution.includes(index) ? 'rotate-90' : 'rotate-0'}`} />
                                        <p className='text-sm ps-1'>{ProblemSolution?.problem} ?</p>
                                    </AccordionHeader>
                                    <AccordionBody className='py-0'>
                                        <div className='w-full  bg-skin-fill-company-table-bg h-auto flex items-center px-4 py-1 shadow-sm group'>
                                            <div className='px-5 w-full text-skin-company-taible-text'>
                                                <div className='empty' dangerouslySetInnerHTML={{ __html: ProblemSolution?.solution }} />
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            </div>
                        ))
                            :
                            <DataNotFoundPage />
                        }
                    </div>
                </div>
            </div>
            {/* Right Portion */}
            <div className='w-full grid grid-cols-1 xl:grid-cols-2 xl:w-[70%] h-min mt-3 xl:mt-0 xl:h-full'>
                {graphData && graphData?.map((graph: any, index: number) => (
                    <div key={index} className="flex flex-col h-auto xl:h-[330px] gap-2 bg-white mb-2 rounded-md shadow-lg p-2">
                        <h1 className='w-full self-start text-sm border-b'>{graph?.heading}</h1>
                        <AreaChart data={graph?.graphData} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LogProgress;
