import { useEffect, type FC, useContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import LeftSubNave from '../../Component/Common/LeftSubNav/LeftSubNav';
import { DataContext } from '../../Context/DataProvider';

interface ConfigurationProps { }

const Configuration: FC<ConfigurationProps> = () => {
    const { setSideNavCollapse, isVisible } = useContext(DataContext);
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '{}');
    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (pathname === '/home/configuration') {
            if (isVisible('role-read')) {
                navigate('/home/configuration/rolesTable')
            } else if (isVisible('company-read')) {
                navigate('/home/configuration/company')
            } else if (isVisible('plant-read')) {
                navigate(`/home/configuration/plant/${userDetails?.company?._id}`)
            } else if (isVisible('segment-read')) {
                navigate(`/home/configuration/segment/${userDetails?.company?._id}`)
            } else if (isVisible('companyadmin-read')) {
                navigate(`/home/configuration/companyadmin`)
            } else if (isVisible('asset-read')) {
                navigate(`/home/configuration/asset`)
            } else if (isVisible('assettype-read')) {
                navigate(`/home/configuration/assettype`)
            } else if (isVisible('monitoringtype-read')) {
                navigate(`/home/configuration/monitoringtype`)
            } else if (isVisible('unit-read')) {
                navigate(`/home/configuration/unit`)
            } else if (isVisible('country-read')) {
                navigate(`/home/configuration/country`)
            } else if (isVisible('state-read')) {
                navigate(`/home/configuration/state`)
            } else if (isVisible('problemsolution-read')) {
                navigate(`/home/configuration/problemsolution`)
            }
        }
        // eslint-disable-next-line
    }, [pathname])
    return (
        <div className="w-full h-full flex gap-1 relative">
            <div className="absolute z-40 -left-2 h-full">
                <LeftSubNave />
            </div>
            <div onClick={() => setSideNavCollapse(true)} className="w-full h-full flex items-center justify-center bg-skin-fill-outlet shadow-sm scrollbarsecondary overflow-x-auto">
                <Outlet />
            </div>
        </div>
    );
}

export default Configuration;
