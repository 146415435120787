import { useState, type ChangeEvent, type Dispatch, type FC, type SetStateAction } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

interface SearchFilterProps {
    setSearch: Dispatch<SetStateAction<string>>
}

const SearchFilter: FC<SearchFilterProps> = ({ setSearch }) => {
    const [focusOn, setFocusOn] = useState<boolean>(false)
    return (
        <div className={`sm:static absolute right-1.5 ${focusOn ? 'w-full sm:w-auto' : 'w-auto'} z-10`}>
            <div className='w-full relative h-hull flex justify-end'>
                <input onFocus={() => setFocusOn(true)} onBlur={() => setFocusOn(false)} type="search" id='search' placeholder='Search by Name' onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)} className='focus:w-full w-4 sm:w-44 sm:focus:w-44 md:w-64 md:focus:w-64 focus:outline-none ps-7 pe-1 py-1 rounded border text-sm text-gray-700' />
                <label htmlFor='search' className='cursor-pointer absolute left-2 sm:left-1 top-1.5 text-gray-700'><AiOutlineSearch /></label>
            </div>
        </div>
    );
}

export default SearchFilter;
