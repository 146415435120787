import { type FC, useContext, useState, useEffect, Fragment } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import CancelButton from '../../Component/FormControl/CancelButton';
import SubmitButton from '../../Component/FormControl/SubmitButton';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { DataContext } from '../../Context/DataProvider';
import ImageUploadInput from '../../Component/FormControl/ImageUploadInput';
import noImage from '../../Assets/noimageavailable.jpg'
import { RxCross2 } from 'react-icons/rx';
import InputText from '../../Component/FormControl/InputText';
import { useParams } from 'react-router-dom';
import InputSelect from '../../Component/FormControl/InputSelect';
import FileUpload from '../../Services/file_upload'
import ButtonLoader from '../../Component/Common/Loader/ButtonLoader';

interface EditCompanyAssetFormProps {
    setOpenPopUp: React.Dispatch<React.SetStateAction<boolean>>
    openPopUp: boolean
}

const EditCompanyAssetForm: FC<EditCompanyAssetFormProps> = ({ setOpenPopUp, openPopUp }) => {
    const { assetDataForEdit, isRender, setIsRender, setAssetDataForEdit, buttonLoader, setButtonLoader } = useContext(DataContext);
    const [imageArray, setImageArray] = useState<string[]>([])
    const [imagePostedArray, setImagePostedArray] = useState<{ _id: string, url: string }[]>([])
    const { company } = useParams()
    const [plants, setPlants] = useState<any[]>([])
    const [segment, setSegment] = useState<any[]>([])
    const [selectedPlant, setSelectedPlant] = useState<string | undefined>(assetDataForEdit?.plant)

    const initialValues = assetDataForEdit?.profilingkeys?.reduce((initialValues: any, field: any) => {
        const name = field.label
        initialValues[name] = (assetDataForEdit?.profiling && assetDataForEdit?.profiling[name] !== 'undefined') ? assetDataForEdit?.profiling[name] : (field.type === "checkbox" ? [] : "");
        return initialValues;
    }, {
        name: assetDataForEdit?.name ?? "",
        serialnumber: assetDataForEdit?.serialnumber ?? "",
        plant: assetDataForEdit?.plant ?? "",
        segment: assetDataForEdit?.segment ?? ""
    })
    const validationSchema = assetDataForEdit?.profilingkeys?.reduce((schema: any, field: any) => {
        if (field.type === "text") {
            return schema.shape({
                [field.label]: field.required ? Yup.string().required() : Yup.string()
            });
        } else if (field.type === "select") {
            return schema.shape({
                [field.label]: field.required ? Yup.string().required() : Yup.string()
            });
        } else if (field.type === "radio") {
            return schema.shape({
                [field.label]: field.required ? Yup.string().required() : Yup.string()
            });
        } else if (field.type === "checkbox") {
            return schema.shape({
                [field.label]: field.required ? Yup.array().min(1) : Yup.array()
            });
        } else if (field.type === "date") {
            return schema.shape({
                [field.label]: field.required ? Yup.date().required() : Yup.date()
            });
        }
        return schema;
    }, Yup.object({
        name: Yup.string().required(),
        serialnumber: Yup.string().required(),
    }));

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        const nameAndSerial = {} as any;
        const profile = {} as any;
        for (const key in values) {
            if (key === "name" || key === "serialnumber" || key === "plant" || key === "segment") {
                nameAndSerial[key] = values[key];
            } else {
                profile[key] = values[key];
            }
        }
        try {
            const data: any = { _id: assetDataForEdit?._id, profile: profile, name: nameAndSerial.name, serialnumber: nameAndSerial.serialnumber, images: imageArray }
            if (nameAndSerial.plant !== '') {
                data['plant'] = nameAndSerial.plant;
            }
            if (nameAndSerial.segment !== '') {
                data['segment'] = nameAndSerial.segment;
            }
            const response: responseType = await http({
                url: `/companyAsset/editCompanyAsset`,
                method: 'put',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setIsRender(!isRender)
                setTimeout(() => {
                    setButtonLoader(false)
                    setAssetDataForEdit(null)
                    setOpenPopUp(false);
                    resetForm();
                }, 1000);
            } else {
                setButtonLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            setButtonLoader(false)
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error editing company asset');
            }
        }
    };
    const handleImageUpload = async (file: File) => {
        try {
            let data: any = await FileUpload(file);
            setImageArray([...imageArray, data._id]);
            setImagePostedArray([...imagePostedArray, data]);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error uploading image.');
            }
        }
    };

    const handleDeleteImage = async (_id: string) => {
        try {
            const response: responseType = await http({
                url: `/media/deleteMediaPermanent`,
                method: 'delete',
                data: { _id },
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setImagePostedArray(imagePostedArray?.filter((image: { _id: string, url: string }) => (image._id !== response?.data?.data._id)));
                setImageArray(imageArray?.filter((imageId: string) => (imageId !== response?.data?.data._id)));
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting image.');
            }
        }
    }
    const fetchPlants = async () => {
        try {
            const response: responseType = await http({
                url: `/plant/getPlantForDD`,
                method: 'get',
                data: { company }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setPlants(response?.data?.data);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error getting plants.');
            }
        }
    }
    const fetchSegments = async () => {
        try {
            const response: responseType = await http({
                url: `/segment/getSegmentForDD`,
                method: 'get',
                data: { plant: selectedPlant }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setSegment(response?.data?.data);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error getting segments');
            }
        }
    }
    const imageArrayBuilder = (data: any) => {
        let newArray: any = []
        data?.forEach((image: any) => newArray.push(image?._id))
        return newArray;
    }
    useEffect(() => {
        if (openPopUp === true) {
            setImagePostedArray(assetDataForEdit?.images ?? [])
            setImageArray(imageArrayBuilder(assetDataForEdit?.images))
        } else {
            setImagePostedArray([])
            setImageArray([])
        }
        fetchPlants();
        // eslint-disable-next-line
    }, [assetDataForEdit, openPopUp])
    useEffect(() => {
        if (selectedPlant !== '' || selectedPlant !== undefined) {
            fetchSegments();
        }
        // eslint-disable-next-line
    }, [selectedPlant])
    const handlefrequencyChange = (e: any, setFieldValue: any) => {
        setSelectedPlant(e.target.value)
        setFieldValue('plant', e.target.value);
    };

    return (
        <div className="w-full h-auto md:pb-0 pb-10">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form className='flex flex-col gap-4'>
                        <InputText autoFocus={true} id='name' name='name' label='Name' />
                        <InputText id='serialnumber' name='serialnumber' label='Serial Number' />
                        {assetDataForEdit?.profilingkeys && assetDataForEdit?.profilingkeys.map((field: any, index: number) => (
                            <Fragment key={index}>
                                {["text", "number", "date", "email"]?.includes(field?.type) ? (
                                    <div className="relative z-0 w-full group">
                                        <Field type={field?.type}
                                            id={field?.label}
                                            name={field?.label}
                                            className="block p-2 w-full text-sm  rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer" placeholder=" " />
                                        <label htmlFor={field?.label} className='peer-focus:font-medium absolute text-gray-400  font-normal text-sm peer-focus:text-gray-800 duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet  z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>{field?.label?.split('_').join(' ')[0]?.toUpperCase() + field?.label.split('_').join(' ')?.slice(1)} {field?.required === true ? <span className={`text-red-500`}>*</span> : null}</label>
                                        <span className="text-red-500 text-sm"><ErrorMessage name={field?.label} /></span>
                                    </div>
                                ) : field.type === "select" ? (
                                    <div className="relative z-0 w-full group">
                                        <Field
                                            as='select'
                                            id={field.label}
                                            name={field.label}
                                            placeholder={field.label}
                                            className="block p-2 w-full text-sm  rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer"
                                        >
                                            <option value="">Select {field?.label?.split('_').join(' ')[0]?.toUpperCase() + field?.label.split('_').join(' ')?.slice(1)}</option>
                                            {field?.options?.map((option: string) => (
                                                <option key={option} value={option}>
                                                    {option[0]?.toUpperCase() + option?.slice(1)}
                                                </option>
                                            ))}
                                        </Field>
                                        <label htmlFor={field.label} className='peer-focus:font-medium absolute text-gray-400 peer-focus:text-gray-800 font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet  z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>{field?.label?.split('_').join(' ')[0]?.toUpperCase() + field?.label.split('_').join(' ')?.slice(1)} {field.required === true ? <span className={`text-red-500`}>*</span> : null}</label>
                                        <span className="text-red-500 text-sm"><ErrorMessage name={field.label} /></span>
                                    </div>
                                ) : field.type === "radio" ? (
                                    <>
                                        <span>{field?.label?.split('_').join(' ')[0]?.toUpperCase() + field?.label.split('_').join(' ')?.slice(1)} {field.required === true ? <span className={`text-red-500`}>*</span> : null}</span>
                                        {field.options.map((option: string) => (
                                            <div key={option} className='flex items-center gap-2'>
                                                <Field
                                                    type="radio"
                                                    id={`${field.label}-${option}`}
                                                    name={field.label}
                                                    value={option}
                                                    className='py-1 border'
                                                />
                                                <label htmlFor={`${field.label}-${option}`}>{option}</label>
                                            </div>
                                        ))}
                                    </>
                                ) : field.type === "checkbox" ? (
                                    <>
                                        <span>{field?.label?.split('_').join(' ')[0]?.toUpperCase() + field?.label.split('_').join(' ')?.slice(1)} {field.required === true ? <span className={`text-red-500`}>*</span> : null}</span>
                                        {field.options.map((option: string) => (
                                            <div key={option} className='flex items-center gap-2'>
                                                <Field
                                                    type="checkbox"
                                                    id={`${field.label}-${option}`}
                                                    name={field.label}
                                                    value={option}
                                                    className='py-1 border'
                                                />
                                                <label htmlFor={`${field.label}-${option}`}>{option}</label>
                                            </div>
                                        ))}
                                    </>
                                ) : field.type === 'textarea' && (
                                    <div className="relative z-0 w-full group">
                                        <Field as="textarea"
                                            id={field?.label}
                                            name={field?.label}
                                            className="block max-h-24 p-2 w-full text-sm  rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer" placeholder=" " />
                                        <label htmlFor={field?.label} className='peer-focus:font-medium absolute text-gray-400  font-normal text-sm peer-focus:text-gray-800 duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet  z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>{field?.label?.split('_').join(' ')[0]?.toUpperCase() + field?.label.split('_').join(' ')?.slice(1)} {field?.required === true ? <span className={`text-red-500`}>*</span> : null}</label>
                                        <span className="text-red-500 text-sm"><ErrorMessage name={field?.label} /></span>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                        <div className="relative z-0 w-full group">
                            <Field
                                id='plant'
                                name='plant'
                                onChange={(e: any) => handlefrequencyChange(e, setFieldValue)}
                                className="block p-2 w-full text-sm  rounded text-gray-500 bg-transparent border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer" as="select">
                                <option value="">Select Plant</option>
                                {plants?.map((item: any) => (<option key={item?._id} value={item?._id}>{item.name}</option>))}
                            </Field>
                            <label htmlFor='plant' className='peer-focus:font-medium absolute text-gray-400 peer-focus:text-gray-800 font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet  z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>Plant</label>
                        </div>
                        <InputSelect Array={segment} id='segment' name='segment' label='Segment' required={false} />
                        <div className="w-full h-36 border border-dashed overflow-x-auto flex gap-2 scrollbar-thin p-2">
                            {imagePostedArray?.map((image: { _id: string, url: string }) => (
                                <div key={image._id} className="w-32 min-w-[128px] h-32 rounded-md border border-dotted relative group">
                                    <span onClick={() => handleDeleteImage(image._id)} className='hidden w-5 h-5 group-hover:flex items-center justify-center absolute cursor-pointer -top-2 -right-2 rounded-full bg-gray-200 text-gray-700 text-sm border border-black'><RxCross2 /></span>
                                    <img src={image.url ?? noImage} alt="asset_image" className='w-full h-full' />
                                </div>
                            ))}
                        </div>
                        <ImageUploadInput onImageUpload={handleImageUpload} height='70px' />
                        <div className="float-right py-2 flex gap-2 justify-end">
                            <CancelButton onClick={() => setOpenPopUp(false)} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton label={'Update'} />}
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    );
}

export default EditCompanyAssetForm;
