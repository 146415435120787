import { useContext, type FC, useState, useEffect } from 'react';
import { DataContext } from '../../../Context/DataProvider';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import FileUpload from '../../../Services/file_upload'
import * as Yup from 'yup';
import InputSelect from '../../../Component/FormControl/InputSelect';
import InputText from '../../../Component/FormControl/InputText';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import ImageUploadInput from '../../../Component/FormControl/ImageUploadInput';
import { RxCross2 } from 'react-icons/rx';
import InputDate from '../../../Component/FormControl/InputDate';
import InputNumber from '../../../Component/FormControl/InputNumber';
import { contractPeriods, currencyes, paymentTermses, taxPreferences } from './selectFieldOptions'
import CancelButton from '../../../Component/FormControl/CancelButton';

interface CommercialDetailsFormProps {
    setIsLoading?: any
    setCompletedTabs?: any
    setIsActive?: any
    completdTab?: string
}
interface editcommercialDetails {
    company: { _id: string }
    pan: string
    taxPreference: string
    currency: string
    contractPeriod: string
    contractRenewDate: Date
    contractStartDate: Date
    numberOfAssetsOnboarded: string
    paymentTerms: string
    companyUrl: string
    _id?: string
}

const CommercialDetailsForm: FC<CommercialDetailsFormProps> = ({ setIsLoading, setCompletedTabs, setIsActive, completdTab }) => {
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const [editcommercialDetails, setEditCommercialDetails] = useState<editcommercialDetails | null>(null)
    const companyOnbord = sessionStorage.getItem('companyOnbord');
    const [uploadedFile, setuploadedFile] = useState<{ _id: string, url: string, mimetype: string } | null>()

    const handlePrevious = () => {
        setIsActive('basic_details')
    }

    const initialValues = {
        pan: '',
        taxPreference: '',
        currency: '',
        contractPeriod: '',
        contractStartDate: '',
        contractRenewDate: '',
        numberOfAssetsOnboarded: '',
        paymentTerms: '',
        companyUrl: '',
    };

    const handleFileUpload = async (file: File) => {
        try {
            let data: any = await FileUpload(file);
            setuploadedFile(data);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error uploading image.');
            }
        }
    };

    const handleDeleteFile = async () => {
        try {
            const response: responseType = await http({
                url: `/media/deleteMediaPermanent`,
                method: 'delete',
                data: { _id: uploadedFile?._id },
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setuploadedFile(null)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleteing image');
            }
        }
    }

    const fetchCommercialDetails = async () => {
        try {
            const response: responseType = await http({
                url: `/commercialDetails/getCommercialDetailsForEdit`,
                method: 'get',
                data: { 'company_id': companyOnbord }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditCommercialDetails(response?.data?.data);
                setuploadedFile(response?.data?.data?.contractCopy)
                setIsLoading(false)
                initialValues.pan = response?.data?.data.pan;
                initialValues.taxPreference = response?.data?.data.taxPreference;
                initialValues.currency = response?.data?.data.currency;
                initialValues.contractPeriod = response?.data?.data.contractPeriod;
                initialValues.contractRenewDate = new Date(response?.data?.data?.contractRenewDate).toISOString().split('T')[0]
                initialValues.contractStartDate = new Date(response?.data?.data?.contractStartDate).toISOString().split('T')[0]
                initialValues.numberOfAssetsOnboarded = response?.data?.data?.numberOfAssetsOnboarded;
                initialValues.paymentTerms = response?.data?.data?.paymentTerms;
                initialValues.companyUrl = response?.data?.data?.companyUrl;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching commercial details');
            }
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (completdTab?.includes('commercial_details')) {
            fetchCommercialDetails();
        }
        // eslint-disable-next-line 
    }, []);

    const validationSchema = Yup.object().shape({
        pan: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'Invalid PAN number').required('PAN number is required'),
        taxPreference: Yup.string().required('Tax preference is required'),
        currency: Yup.string().required('Currency is required'),
        contractPeriod: Yup.number().required('Contract Period is required'),
        contractRenewDate: Yup.string().required('Contract renew date is required'),
        contractStartDate: Yup.string().required('Contract start date is required'),
        numberOfAssetsOnboarded: Yup.string().required('Number of assets onboarded is required'),
        paymentTerms: Yup.string().required('Payment terms is required'),
        companyUrl: Yup.string().required('Company Url is required'),
    });

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        values['contractCopy'] = uploadedFile?._id ?? null;
        values['company'] = companyOnbord;
        if (editcommercialDetails !== null) {
            values['_id'] = editcommercialDetails._id;
            try {
                const response: responseType = await http({
                    url: `/commercialDetails/editCommercialDetails`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        setCompletedTabs('commercial_details')
                        setIsActive('billing_address')
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error editing commercial details.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/commercialDetails/addCommercialDetails`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        setCompletedTabs('commercial_details')
                        setIsActive('billing_address')
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding commercial details.');
                }
            }
        }
    };
    return (
        <div className='w-full relative'>
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue }) => (
                        <Form className='flex flex-col gap-4 p-3'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                <InputText name='companyUrl' id='companyUrl' label='Company Url' />
                                <InputText name='pan' id='pan' label='PAN' />
                                <InputSelect name='taxPreference' id='taxPreference' label='Tax Preference' Array={taxPreferences} />
                                <InputSelect name='currency' id='currency' label='Currency' Array={currencyes} />
                                <InputSelect name='contractPeriod' id='contractPeriod' label='Contract Period' Array={contractPeriods} />
                                <InputDate name='contractStartDate' id='contractStartDate' label='Contract start date' />
                                <InputDate name='contractRenewDate' id='contractRenewDate' label='Contract renew date' />
                                <InputSelect name='paymentTerms' id='paymentTerms' label='Payment Terms' Array={paymentTermses} />
                                <InputNumber name='numberOfAssetsOnboarded' id='numberOfAssetsOnboarded' label='Number of assets onboarded' />
                            </div>
                            <div className='w-full relative'>
                                {uploadedFile != null ?
                                    <div className="w-full flex items-center justify-start gap-5">
                                        <p className='truncate w-40'>{uploadedFile?.url}</p>
                                        <p className={uploadedFile?.url ? 'text-green-500' : 'text-gray-700'}>{uploadedFile?.url ? 'File Uploaded' : 'Uploade File'}</p>
                                        <span onClick={handleDeleteFile} className='w-5 h-5 flex justify-center items-center cursor-pointer rounded-full bg-gray-200 text-gray-700 text-sm border border-black'><RxCross2 /></span>
                                    </div>
                                    :
                                    <ImageUploadInput disabled={uploadedFile != null ? true : false} onImageUpload={handleFileUpload} lable='contract copy' />
                                }
                            </div>
                            <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                                <CancelButton onClick={handlePrevious} label='Previous' />
                                {buttonLoader ? <ButtonLoader /> : <SubmitButton label='Save & Next' />}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default CommercialDetailsForm;
