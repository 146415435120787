import { useState, type FC, Dispatch, SetStateAction, useEffect } from 'react';
import PieChart from '../../../Component/Common/Charts/PieChart';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';

interface PieChartData1Props {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const PieChartData1: FC<PieChartData1Props> = ({ setLoading }) => {
    const [companyAssetsCountsForGraph, setCompanyAssetsCountsForGraph] = useState<any[]>([])
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const fetchCompanyAssetsCountForGraph = async () => {
        try {
            const response: responseType = await http({
                url: `/companyAsset/assetCountForGraph`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompanyAssetsCountsForGraph(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset count');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code === 'superadmin') {
            fetchCompanyAssetsCountForGraph();
        }
        // eslint-disable-next-line 
    }, [])
    return (
        <div className='w-full shadow-lg h-[440px] flex flex-col bg-white rounded-lg p-2 font-sans'>
            <h1 className='w-full self-start text-sm border-b'>Company's assets</h1>
            <div className="w-full h-[400px] flex items-center justify-center">
                {(companyAssetsCountsForGraph && companyAssetsCountsForGraph.length !== 0) ? <PieChart data={companyAssetsCountsForGraph} title='Assets' /> : <span className='text-sm flex items-center justify-center'>No company's assets found.</span>}
            </div>
        </div>
    );
}

export default PieChartData1;
