import { useState, type FC, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataContext } from '../../Context/DataProvider';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import Loader from '../../Component/Common/Loader/Loader';
import DataNotFoundPage from '../../Component/Common/DataNotFoundPage';

interface CompanyAssetProps { }
interface companyAsset {
    count: number
    assetType: {
        _id: string
        name: string
    }
}

const CompanyAsset: FC<CompanyAssetProps> = () => {
    const [companyAsset, setCompanyAsset] = useState<companyAsset[] | null>(null)
    const { isRender } = useContext(DataContext);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    const { company } = useParams()
    const navigate = useNavigate()

    const fetchCompanyAsset = async () => {
        try {
            const response: responseType = await http({
                url: `/companyAsset/getCompanyAsset`,
                method: 'get',
                data: { company: userDetails?.company?._id ? userDetails?.company?._id : company }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
                setCompanyAsset(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company assets');
            }
        }
    };

    useEffect(() => {
        fetchCompanyAsset();
        // eslint-disable-next-line 
    }, [isRender]);
    return (
        <div className="w-full h-full overflow-y-auto scrollbar-thin relative">
            <Loader loader={isLoading} />
            {companyAsset?.length === 0
                ?
                <DataNotFoundPage />
                :
                <div className='w-full gap-2 grid grid-cols-1 text-skin-base lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 px-1'>
                    {companyAsset?.map((asset: companyAsset) => (
                        <div key={asset?.assetType?._id} onClick={() => navigate(`/home/companyAsset/${userDetails?.company !== null ? userDetails?.company?._id : company}/${asset?.assetType?._id}`)} className="h-60 bg-skin-fill-card rounded-xl shadow-xl relative cursor-pointer">
                            <span className='rounded-md bg-skin-fill-company-card-bg px-1.5 absolute bottom-4 left-3'>{asset?.assetType?.name}</span>
                            <span className=' absolute bottom-3 right-3 px-3 py-2 rounded-md bg-skin-fill-company-card-bg'>{asset?.count}</span>
                        </div>
                    ))}</div>}
        </div>
    );
}

export default CompanyAsset;
