import { useContext, useEffect, useState, type FC } from 'react';
import Loader from '../../Component/Common/Loader/Loader';
import { IactionPopAction, responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { useNavigate, useParams } from 'react-router-dom';
import { DataContext } from '../../Context/DataProvider';
import { toast } from 'react-toastify';
import ActionPop from '../../Component/Common/ActionPop/ActionPop';
import DeleteModal from '../../Component/Common/DeleteModal';
import { MdOutlineMonitorHeart } from "react-icons/md";
import PopUp from '../../Component/Common/PoUp/PopUp';

interface CompanyAssetDetailsNewProps { }

const CompanyAssetDetailsNew: FC<CompanyAssetDetailsNewProps> = () => {
    const [companyAssetDetails, setcompanyAssetDetails] = useState<any[] | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    const { company, assetType } = useParams();
    const [Id, setId] = useState<string>();
    const { isRender, setIsRender, setAssetForLog, isVisible } = useContext(DataContext);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [openPopUp, setOpenPopUp] = useState(false)
    const [active, setActive] = useState<string>('');
    const rowColors = ['#FFEFB9', '#CFFFE5', '#FFC7CE', '#C9F0FF'];
    const navigate = useNavigate()

    const handleEdit = () => {
        navigate(`/home/editCompanyAsset/${Id}`)
    }

    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/companyAsset/deleteCompanyAssetDetails`,
                method: 'delete',
                data: { '_id': Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
                navigate(-1)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting company admin.');
            }
        }
    }

    const DeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }

    const addAssetLog = (assetType: any) => {
        setAssetForLog(assetType)
        setOpenPopUp(true)
        setActive('monitorinLogForm')
    }

    const handleAddMonitoring = () => {
        navigate(`/home/logprogress/${Id}`)
    }

    const action: IactionPopAction[] = []
    isVisible('companyasset-update') && action.push({ id: 1, name: 'Edit', click: handleEdit });
    isVisible('companyasset-delete') && action.push({ id: 2, name: 'Delete', click: DeleteModalOpen });
    isVisible('assetmonitoring-read') && action.push({ id: 3, name: 'Go To Log Progress', click: handleAddMonitoring });

    const fetchcompanyAssetDetails = async () => {
        try {
            const response: responseType = await http({
                url: `/companyAsset/getCompanyAssetDetails`,
                method: 'get',
                data: {
                    company: userDetails?.company?._id ? userDetails?.company?._id : company,
                    assetType: assetType,
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setcompanyAssetDetails(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company asset details');
            }
        }
    };

    useEffect(() => {
        fetchcompanyAssetDetails();
        // eslint-disable-next-line 
    }, [isRender]);
    return (
        <div className="w-full h-full overflow-y-auto scrollbar-thin relative">
            <Loader loader={isLoading} />
            {companyAssetDetails?.length === 0 ? (
                <div className="w-full h-full flex items-center justify-center">
                    <h1 className='text-3xl text-skin-base'>Data Not Found</h1>
                </div>
            ) : (
                <div className='w-full overflow-x-auto bg-white rounded-xl border  shadow-xl shadow-[#cfffe5] p-4'>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 rounded-tl-md rounded-tr-md border- ">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Asset Nameby Company</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Asset Type</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Data Logging Frequency</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Compressor Location</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Application Of Air</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Asset Name by Company</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Type Of Compressor </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">technology</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Number of Stagese</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Air Cooled Or water Cooled </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Capacity Control</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Make</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Model</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Year Of Manufacturing</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Motor kW</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Working Pressure Barg</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Flow CFM</th>
                                {isVisible('companyasset-update') || isVisible('companyasset-delete') || isVisible('assetmonitoring-read') ?
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Actions</th>
                                    : null}
                                {userDetails?.role?.code !== 'superadmin' &&
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Monitoring</th>
                                }
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {companyAssetDetails?.map((assetType, index) => (
                                <tr key={assetType._id} style={{ backgroundColor: rowColors[index % rowColors.length] }} className='bg-red-600'>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.assetNamebyCompany}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.assetType.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.dataLoggingFrequency}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.compressorLocation}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.applicationOfAir}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.assetNamebyCompany}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.typeOfCompressor}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.technology}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.numberofStagese}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.cooled}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.capacityControl}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.make}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.model}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.yearOfManufacturing}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.motorkW}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.workingPressureBarg}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">{assetType.flowCFM}</td>
                                    {isVisible('companyasset-update') || isVisible('companyasset-delete') || isVisible('assetmonitoring-read') ?
                                        <td className="px-6 py-4 whitespace-nowrap hover:bg-[#ffc3af]">
                                            <span onClick={() => setId(assetType?._id)}>
                                                <ActionPop action={action} icon={'FiMoreVertical'} />
                                            </span>
                                        </td>
                                        :
                                        null}
                                    {userDetails?.role?.code !== 'superadmin' &&
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:bg-[#ffc3af]">
                                            <span title='Add Monitoring' onClick={() => addAssetLog(assetType)} className='text-xl cursor-pointer'><MdOutlineMonitorHeart /></span>
                                        </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <PopUp openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} active={active} />
            <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='Company Asset Details' />
        </div>
    );
}

export default CompanyAssetDetailsNew;
