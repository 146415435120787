import { useState, type FC, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { responseType } from '../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../Services/http/http';
import { toast } from 'react-toastify';
import MyCarousel from '../../Component/Common/Carousel/Carousel';
import Loader from '../../Component/Common/Loader/Loader';
import InputTextArea from '../../Component/FormControl/InputTextArea';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import CancelButton from '../../Component/FormControl/CancelButton';
import ButtonLoader from '../../Component/Common/Loader/ButtonLoader';
import SubmitButton from '../../Component/FormControl/SubmitButton';
import { DataContext } from '../../Context/DataProvider';
import { useNavigate } from 'react-router-dom';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { IoIosArrowForward } from 'react-icons/io';
import Multiselect from 'multiselect-react-dropdown';
// import DataNotFoundPage from '../../Component/Common/DataNotFoundPage';

interface NotificationDetailProps { }

const NotificationDetail: FC<NotificationDetailProps> = () => {
    const _id = useParams()
    const [problemSolutionData, setProblemSolutionData] = useState<any[]>()
    const [rangeOpen, setRangeOpen] = useState<number[]>([]);
    const [selectedItem, setSelectedItem] = useState<any[]>([]);
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const { notificationId } = useParams();
    const [notification, setNotification] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const loggedInUser = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const navigate = useNavigate()

    const handleOpen = (index: number) => {
        if (rangeOpen.includes(index)) {
            setRangeOpen([])
        } else {
            setRangeOpen([index])
        }
    }

    const initialValues = {
        remark: '',
        action_taken: '',
    }

    const fetchNotificationDetail = async () => {
        try {
            const response: responseType = await http({
                url: `/notification/getNotificationDetail`,
                method: 'get',
                data: { _id: notificationId }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setNotification(response?.data?.data);
                setProblemSolutionData(response?.data?.data?.log?.companyAsset?.troubleshoots)
                initialValues.remark = response?.data?.data?.remark ?? '';
                initialValues.action_taken = response?.data?.data?.action_taken?.toString() ?? '';
                setSelectedItem(response?.data?.data?.log?.companyAsset?.asset?.troubleshoots?.filter((item: any) =>
                    response?.data?.data?.actions?.includes(item?._id)
                ) as any[])
                setTimeout(() => {
                    setIsLoading(false)
                }, 2000);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching notification detail.');
            }
        }
    }
    const markNotificationAsSeen = async () => {
        try {
            const response: responseType = await http({
                url: `/notification/markNotificationAsSeen`,
                method: 'put',
                data: { _id: notificationId }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                console.log(response.data.message)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching mark notification');
            }
        }
    }
    useEffect(() => {
        fetchNotificationDetail();
        setTimeout(() => {
            setIsLoading(false)
        }, 5000);
        setTimeout(async () => {
            if (notification?.seen === false) {
                markNotificationAsSeen();
            }
        }, 2000);
        // eslint-disable-next-line
    }, [notificationId])

    const validationSchema = Yup.object().shape({
        remark: Yup.string().required('Remark is required'),
        action_taken: Yup.string().required('required'),
    });
    const handleSubmit = async (values: any) => {
        setButtonLoader(true)
        values['_id'] = _id.notificationId;
        values['actions'] = selectedItem?.map((item: any) => item?._id);
        values['action'] = values?.action_taken === 'true' ? true : values?.action_taken === 'false' && false;
        try {
            const response: responseType = await http({
                url: `/notification/takeAction`,
                method: 'put',
                data: values
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message);
                setTimeout(() => {
                    setButtonLoader(false)
                    navigate(-1)
                }, 2000);
            } else {
                setButtonLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            setButtonLoader(false)
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error Adding notification.');
            }
        }
    }
    const handleRemove = (e: any) => {
        setSelectedItem(e)
    }
    const handleChange = (e: any) => {
        setSelectedItem(e)
    }
    return (
        <div className="w-full h-full flex flex-col xl:flex-row relative overflow-y-auto scrollbar-none">
            <Loader loader={isLoading} />
            <div className='p-2 w-full xl:w-1/2 h-min xl:h-full'>
                <div className={`w-full flex items-center ${notification?.fallen_range === 'less-min' ? ' border-red-600' : notification?.fallen_range === 'min-max' ? ' border-orange-600' : notification?.fallen_range === 'max-alarm' ? 'border-yellow-400' : notification?.fallen_range === 'alarm-tripping' ? 'border-yellow-200' : notification?.fallen_range === 'tripping-more' && 'border-green-600'} border-l-4 rounded-md border p-1 mb-3`}>
                    <div className='w-full px-4'>
                        <p className='font-medium text-gray-700'>Dear, {loggedInUser?.name}</p>
                        <p className='text-sm text-gray-700'>{notification?.content}</p>
                    </div>
                </div>
                <div className="max-w-sm bg-skin-fill-card h-64 border  flex flex-col justify-between  rounded-xl relative ">
                    <div className="">
                        <MyCarousel items={notification?.log?.companyAsset?.images} />
                    </div>
                    <div className="text-skin-base flex group flex-col gap-4 p-3">
                        <span>{notification?.log?.companyAsset?.assetNamebyCompany}</span>
                        {notification?.log?.companyAsset?.profile &&
                            <div className="cursor-pointer hidden group-hover:block text-skin-company-taible-text group-hover:text-skin-company-mainText group-hover:overflow-y-visible group-hover:absolute group-hover:w-fit group-hover:bg-gray-100 group-hover:mb-0 group-hover:h-fit group-hover:px-2 px-2 py-2 group-hover:py-2 group-hover:rounded-md group-hover:z-40 mb-2">
                                {Object.entries(notification?.log?.companyAsset?.profile)?.map(([key, value], index): any => (
                                    <p key={index} className='truncate px-1'>
                                        <strong>{key[0]?.toUpperCase() + key?.slice(1)} :</strong> <span>{typeof (value) === 'object' ? JSON.parse(JSON.stringify(value)).join(' , ') : value}</span>
                                    </p>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div className="p-1 rounded-md mt-2">
                        <h1 className='font-medium flex items-center gap-1 w-full pb-1'>
                            <span className='w-full border-b border-gray-700'></span>
                            <span>Ranges</span>
                            <span className='w-full border-b border-gray-700'></span>
                        </h1>
                        {notification?.log?.companyAsset?.monitoring?.filter((mtng: any) => mtng?.monitoringType === notification?.log?.monitoringType?._id)[0]?.ranges &&
                            <div className='flex gap-2 flex-col'>
                                {notification?.log?.companyAsset?.monitoring?.map((monitoringData: any, index: number) => (
                                    <div key={index}>
                                        {monitoringData?.ranges && (
                                            <div className="flex gap-2 rounded-md shadow text-gray-700 text-sm bg-opacity-70 px-1 py-0.5">
                                                <div className="flex gap-1">
                                                    <p>{monitoringData?.ranges?.alarm}</p>
                                                    <span>-</span>
                                                    <p>{monitoringData?.ranges?.maximum}</p>
                                                    <span>-</span>
                                                    <p>{monitoringData?.ranges?.minimum}</p>
                                                    <span>-</span>
                                                    <p>{monitoringData?.ranges?.tripping}</p>
                                                </div>
                                                <p>{notification?.log?.monitoringType?.unit?.name ?? 'Unit name not provided'}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div className='w-full h-min mt-2 flex gap-2 flex-col '>
                        <h1 className='font-medium flex items-center gap-1 w-full pb-1'>
                            <span className='w-full border-b border-gray-700'></span>
                            <span>Suggestions</span>
                            <span className='w-full border-b border-gray-700'></span>
                        </h1>
                        {problemSolutionData?.map((ProblemSolution: any, index: number) => (
                            <div key={index} className='flex items-center pl-1'>
                                <Accordion open={rangeOpen.includes(index)}>
                                    <AccordionHeader onClick={() => handleOpen(index)} className='flex w-full justify-start py-2 bg-skin-fill-company-table-bg text-skin-company-mainText font-medium'>
                                        <IoIosArrowForward className={`transition-all duration-200 ${rangeOpen.includes(index) ? 'rotate-90' : 'rotate-0'}`} />
                                        <p className='text-sm ps-1'>{ProblemSolution?.problem} ?</p>
                                    </AccordionHeader>
                                    <AccordionBody className='py-0'>
                                        <div className='w-full  bg-skin-fill-company-table-bg h-auto flex items-center px-4 py-1 shadow-sm group'>
                                            <div className='px-5 w-full text-skin-company-taible-text'>
                                                <div className='empty' dangerouslySetInnerHTML={{ __html: ProblemSolution?.solution }} />
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='w-full xl:w-1/2 h-min mt-3 xl:mt-0 xl:h-full'>
                <div className='w-full h-72'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <Form className='w-full flex flex-col gap-4 p-3'>
                            <div>
                                <p>Action Taken <span className="text-red-500">*</span> </p>
                                <div className='w-full flex items-center gap-8'>
                                    <div className='flex gap-2'>
                                        <Field
                                            disabled={notification?.action_taken !== null}
                                            type="radio"
                                            id='action1'
                                            name='action_taken'
                                            value={"true"}
                                        />
                                        <label htmlFor="action1">Yes</label>
                                    </div>
                                    <div className='flex gap-2'>
                                        <Field
                                            disabled={notification?.action_taken !== null}
                                            type="radio"
                                            id='action2'
                                            name='action_taken'
                                            value={"false"}
                                        />
                                        <label htmlFor="action2">No</label>
                                    </div>
                                    <span className="text-red-500 text-sm"><ErrorMessage name='actions' /></span>
                                </div>
                            </div>
                            <InputTextArea disabled={notification?.action_taken !== null} name='remark' label='Remark' />
                            <div className='mt-14'>
                                <p>Actions</p>
                                <Multiselect
                                    disable={notification?.action_taken !== null}
                                    displayValue="problem"
                                    onKeyPressFn={function noRefCheck() { }}
                                    onRemove={handleRemove}
                                    onSearch={function noRefCheck() { }}
                                    onSelect={handleChange}
                                    options={problemSolutionData}
                                    selectedValues={selectedItem}
                                    placeholder="Select Troubleshoot"
                                />
                            </div>
                            <div className="w-full float-right bg-skin-fill-outlet flex gap-2  justify-end">
                                <CancelButton onClick={() => navigate(-1)} />
                                {notification?.action_taken === null && (buttonLoader ? <ButtonLoader /> : <SubmitButton />)}
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default NotificationDetail;
