import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

interface AccessDeniedProps { }

const AccessDenied: React.FC<AccessDeniedProps> = () => {
    const controls = useAnimation();

    useEffect(() => {
        controls.start({ opacity: 1, scale: 1, fontWeight: 'bold', fontSize: '5rem' });
        // eslint-disable-next-line 
    }, [controls]);

    return (
        <div className='w-full h-full absolute top-0 left-0 overflow-hidden'>
            <div className="w-full h-full flex py-6 bg-[#FEF9C3] justify-center items-center">
                <div className="w-[80%] min-h-[550px] bg-white flex flex-col items-center gap-5 py-[25px]">
                    <div className='w-full flex flex-col items-center gap-6 px-10 mt-8'>
                        <h1 className='text-6xl font-semibold text-[#6FBCA2]'>401</h1>
                        <div className='w-full h-[6px] rounded-full bg-[#6FBCA2]'></div>
                    </div>
                    <motion.div
                        initial={{ opacity: 1, scale: 1 }}
                        animate={{ opacity: 1, scale: 3, translateY: 100, transition: { ease: "easeOut" } }}
                        className='text-[#6B4D3A] text-3xl sm:text-5xl font-extralight transition-all duration-500 ease-in-out truncate'>
                        Access Denied
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default AccessDenied;