import { useState, type FC, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import Loader from '../../../Component/Common/Loader/Loader';
import { Form, Formik } from 'formik';
import InputText from '../../../Component/FormControl/InputText';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import { DataContext } from '../../../Context/DataProvider';
import InputSelect from '../../../Component/FormControl/InputSelect';
import { unitType } from '../../../Component/FormControl/unitType';

interface UnitFormProps { }
interface IUnit {
    name: String
    // code: String
    _id?: any
    type: string
}

interface editUnit {
    code: string
    name: string
    monitoringType: {
        name: string
        _id: string
    }
    _id: string
}

const UnitForm: FC<UnitFormProps> = () => {
    const [editUnit, setEditUnit] = useState<editUnit | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { id } = useParams();
    const navigate = useNavigate()
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const initialValues = {
        name: '',
        type: ''
        // code: ''
    };

    const fetchUnit = async () => {
        try {
            const response: responseType = await http({
                url: `/unit/getUnitForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditUnit(response?.data?.data);
                initialValues.name = response?.data?.data?.name;
                initialValues.type = response?.data?.data?.type;
                // initialValues.code = response?.data?.data.code;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching unit');
            }
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        if (id !== undefined) {
            fetchUnit();
        }
        // eslint-disable-next-line 
    }, []);


    const validationSchema = Yup.object().shape({
        // code: Yup.string().required('Code is required'),
        name: Yup.string().required('Name is required'),
        type: Yup.string().required('Type is required'),
    });

    const handleSubmit = async (values: IUnit, { resetForm }: any) => {
        setButtonLoader(true)
        if (editUnit !== null) {
            values['_id'] = editUnit._id;
            try {
                const response: responseType = await http({
                    url: `/unit/editUnit`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/unit');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error editing unit.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/unit/addUnit`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/unit');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding unit.');
                }
            }
        }
    };

    return (<>
        <div className='w-full h-full relative'>
            <div className="w-full px-2 sticky top-0 z-30 bg-skin-fill-formheader">
                <p className='py-1 font-medium text-skin-base text-lg'>{editUnit !== null ? 'Edit' : 'Add'} Unit</p>
                <hr />
            </div>
            <Loader loader={isLoading} />
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4 p-3'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            <InputText name='name' id='name' autoFocus={true} label='Name' />
                            <InputSelect name='type' id='type' label='Type' Array={unitType} />
                            {/* <InputText name='code' id='code' disabled={editUnit !== null ? true : false} label='Code' /> */}
                        </div>
                        <div className="w-full float-right flex gap-2 justify-end bg-skin-fill-outlet ">
                            <CancelButton onClick={() => navigate('/home/configuration/unit')} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton label={editUnit !== null ? 'Edit Unit' : 'Add Unit'} />}
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    </>
    );
}

export default UnitForm;
