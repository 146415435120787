import { useState, type FC, Dispatch, SetStateAction, useEffect } from 'react';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import { RiArrowRightDownLine, RiArrowRightUpLine } from 'react-icons/ri';

interface CompaniesCountProps {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const CompaniesCount: FC<CompaniesCountProps> = ({ setLoading }) => {
    const [companiesCount, setCompaniesCount] = useState<any>()
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const fetchCompaniesCount = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getCompanyCount`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompaniesCount(response?.data?.data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company count');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code === 'superadmin') {
            fetchCompaniesCount();
        }
        // eslint-disable-next-line 
    }, [])
    return (
        <div className="w-full h-auto cursor-default bg-white text-gray-700 hover:bg-blue-950 hover:text-white flex flex-col justify-between rounded-xl p-2 px-3 shadow-lg">
            <p className="text-2xl font-semibold">Company</p>
            <div className="pt-2 pb-2.5 flex items-baseline gap-3">
                <div className="text-6xl font-bold flex items-center">
                    {companiesCount?.count}
                </div>
                <div className={`${companiesCount?.percentageChange < 0 ? 'text-red-500' : companiesCount?.percentageChange >= 0 && 'text-green-500'} flex items-center`}>
                    {companiesCount?.percentageChange !== null && companiesCount?.percentageChange < 0 ? companiesCount?.percentageChange?.toString()?.slice(1) : companiesCount?.percentageChange >= 0 ? companiesCount?.percentageChange : '0'}%
                    {companiesCount?.percentageChange !== null && companiesCount?.percentageChange < 0 ? <RiArrowRightDownLine className="text-[20px]" /> : <RiArrowRightUpLine className="text-[20px]" />}
                </div>
            </div>
        </div>
    );
}

export default CompaniesCount;
