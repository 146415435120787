import { useState, type FC, useContext, useEffect } from 'react';
import Loader from '../../../Component/Common/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import ActionPop from '../../../Component/Common/ActionPop/ActionPop';
import { DataContext } from '../../../Context/DataProvider';
import { toast } from 'react-toastify';
import { IactionPopAction, responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import DeleteModal from '../../../Component/Common/DeleteModal';
import Pagination from '../../../Component/Common/Pagination';
import Counter from '../../../Component/Common/Counter';
import SearchFilter from '../../../Component/Common/SearchFilter';
import logo from '../../../Assets/logoText.jpeg';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';
import { Link } from 'react-router-dom';

interface CompanyProps { }
interface companyData {
    name: string
    country: string
    state: string
    users: number
    _id: string
    logo: { _id: string, url: string, mimetype: string }
    gstCertificate: { _id: string, url: string, mimetype: string }
    assets: number
    gstNumber: string
    companyUrl: string
}

const Company: FC<CompanyProps> = () => {
    const [companyData, setCompanyData] = useState<companyData[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [Id, setId] = useState<string>('');
    const navigate = useNavigate();
    const { isRender, setIsRender, selectedLimit, isVisible, dateFilter } = useContext(DataContext)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [skip, setSkip] = useState(0)
    const [counts, setCounts] = useState<number>(0)
    const [searchFilter, setSearchFilter] = useState<string>('')

    useEffect(() => {
        sessionStorage.removeItem('completdTab')
        sessionStorage.removeItem('activeTab')
        sessionStorage.removeItem('companyOnbord')
    }, []);

    const handleEdit = () => {
        sessionStorage.setItem('companyOnbord', Id);
        sessionStorage.setItem('completdTab', JSON.stringify(['basic_details', 'billing_address', 'commercial_details', 'plant', 'segment', 'user', 'asset']));
        sessionStorage.setItem('activeTab', 'basic_details')
        navigate(`/home/configuration/editCompany/${Id}`)
    }

    const DeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }
    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/company/deleteCompany`,
                method: 'delete',
                data: { '_id': Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting company.');
            }
        }
    }
    const action: IactionPopAction[] = []
    isVisible('company-update') && action.push({ id: 1, name: 'Edit', click: handleEdit });
    isVisible('company-delete') && action.push({ id: 2, name: 'Delete', click: DeleteModalOpen });
    const fetchCompanies = async () => {
        try {
            let data: any = {
                search: searchFilter,
                dateFilter: dateFilter
            }
            if (searchFilter === '') {
                data.skip = skip;
                data.limit = selectedLimit;
            }
            const response: responseType = await http({
                url: `/company/getCompany`,
                method: 'get',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCompanyData(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching company.');
            }
        }
    };

    const getCompanCount = async () => {
        try {
            const response: responseType = await http({
                url: `/company/getCompanyCountForPagination`,
                method: 'get',
                data: {
                    search: searchFilter,
                    dateFilter: dateFilter
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCounts(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error FetchingCount.');
            }
        }
    }
    useEffect(() => {
        getCompanCount();
        // eslint-disable-next-line 
    }, [searchFilter, isRender, dateFilter]);
    useEffect(() => {
        fetchCompanies();

        // eslint-disable-next-line 
    }, [isRender, skip, searchFilter, selectedLimit, dateFilter]);
    useEffect(() => {
        setIsLoading(true)
        // eslint-disable-next-line 
    }, [skip, selectedLimit]);
    return (
        <div className='h-full w-full relative  rounded-2xl'>
            <Loader loader={isLoading} />
            <div className='flex items-center justify-between sm:pb-4 pb-2 '>
                <Counter skip={skip} counts={counts} limit={selectedLimit} />
                <SearchFilter setSearch={setSearchFilter} />
            </div>
            {counts === 0 ?
                <DataNotFoundPage />
                :
                <div className="flex sm:h-[90%] h-[92%]  flex-col w-full">
                    <div className="w-full h-auto overflow-auto scrollbar-thin">
                        <table className="w-full mb-2 text-left">
                            <thead className=" px-6   bg-skin-fill-table-head text-skin-base-table-head-text z-10  sticky top-0">
                                <tr>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        S.No.
                                    </th>
                                    <th scope="col" className=" px-4 py-3 font-medium truncate">
                                        Logo
                                    </th>
                                    <th scope="col" className="sticky left-0 bg-skin-fill-table-head px-4 py-3 font-medium truncate">
                                        Company
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Company Url
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Assets
                                    </th>
                                    <th scope="col" className="px-4 py-3 font-medium truncate">
                                        Users
                                    </th>
                                    {isVisible('configuration-update') || isVisible('configuration-delete') ?
                                        <th scope="col" className="py-3 font-medium truncate">
                                            Actions
                                        </th>
                                        : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {companyData?.map((company, index) =>
                                    <tr key={company._id} className=" bg-skin-fill-table  hover:bg-skin-fill-hover hover:text-skin-base-table-hover hover:bg-opacity-10 text-skin-base-table-body-text   border-b border-skin-table-color group">
                                        <td className="px-4 py-[8px] ">
                                            {searchFilter ? (index + 1) : (skip + (index + 1))}
                                        </td>
                                        <td className="px-4 py-[8px]  truncate">
                                            <img src={company?.logo ? company?.logo?.url : logo} className='w-7 h-7 rounded-full' alt="" />
                                        </td>
                                        <td onClick={() => navigate(`/home/configuration/companyDetails/${company._id}`)} className="sticky left-0 group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px] cursor-pointer hover:text-blue-400 hover:underline truncate">
                                            {company?.name}
                                        </td>
                                        <td className=" group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px]  truncate">
                                            {
                                                company?.companyUrl === 'Not provided' ?
                                                    <p>Not provided</p>
                                                    :
                                                    <Link className='cursor-pointer text-blue-500' to={company?.companyUrl} target="_blank">{company?.companyUrl}</Link>
                                            }
                                        </td>
                                        <td className="px-4 py-[8px]  truncate">
                                            {company?.assets}
                                        </td>
                                        <td className="px-4 py-[8px]  truncate">
                                            {company?.users}
                                        </td>
                                        {isVisible('company-update') || isVisible('company-delete') ?
                                            <td className="px-4 py-[8px] " >
                                                <span onClick={() => setId(company?._id)}><ActionPop action={action} icon={'FiMoreVertical'} />
                                                </span>
                                            </td>
                                            : null
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {(counts > selectedLimit && searchFilter === '') && <Pagination limit={selectedLimit} setSkip={setSkip} itemsCount={counts} />}
                </div>
            }
            <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='Company' />
        </div>
    );
}

export default Company;
