import { Formik, Form } from 'formik';
import { type FC, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import * as Yup from 'yup';
import http from '../../../Services/http/http';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import { toast } from 'react-toastify';
import Loader from '../../../Component/Common/Loader/Loader';
import InputText from '../../../Component/FormControl/InputText';
import CancelButton from '../../../Component/FormControl/CancelButton';
import SubmitButton from '../../../Component/FormControl/SubmitButton';
import { useTranslation } from 'react-i18next';
import ButtonLoader from '../../../Component/Common/Loader/ButtonLoader';
import { DataContext } from '../../../Context/DataProvider';
import InputTextArea from '../../../Component/FormControl/InputTextArea';
import InputSelect from '../../../Component/FormControl/InputSelect';

interface ProblemSolutionFormProps { }

const ProblemSolutionForm: FC<ProblemSolutionFormProps> = () => {
    const { buttonLoader, setButtonLoader } = useContext(DataContext)
    const [editProblemSolution, setEditProblemSolution] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [assettypes, setAssetTypes] = useState<any[]>([])
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const initialValues = {
        assetType: '',
        problem: '',
        solution: ''
    };

    const fetchTroubleshootingTipForEdit = async () => {
        try {
            const response: responseType = await http({
                url: `/troubleshootingTip/getTroubleshootingTipForEdit`,
                method: 'get',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setEditProblemSolution(response?.data?.data);
                initialValues.assetType = response?.data?.data.assetType;
                initialValues.problem = response?.data?.data.problem;
                initialValues.solution = response?.data?.data.solution;
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching problem & solution.');
            }
        }
    };
    const fetchAssetTypes = async () => {
        try {
            const response: responseType = await http({
                url: `/assetType/getAssetTypeForDD`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAssetTypes(response?.data?.data);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset types.');
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        fetchAssetTypes();
        if (id !== undefined) {
            fetchTroubleshootingTipForEdit();
        }
        // eslint-disable-next-line 
    }, []);

    const validationSchema = Yup.object().shape({
        assetType: Yup.string().required('asset type is required'),
        solution: Yup.string().required('solution is required'),
        problem: Yup.string().required('problem is required')
    });

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setButtonLoader(true)
        if (editProblemSolution !== null) {
            values['_id'] = editProblemSolution._id;
            try {
                const response: responseType = await http({
                    url: `/troubleshootingTip/editTroubleshootingTip`,
                    method: 'put',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/problemsolution');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error updating problem & solution.');
                }
            }
        } else {
            try {
                const response: responseType = await http({
                    url: `/troubleshootingTip/addTroubleshootingTip`,
                    method: 'post',
                    data: values
                });
                if (response?.data?.code === 'SUCCESS_200') {
                    toast.success(response?.data?.message);
                    setTimeout(() => {
                        setButtonLoader(false)
                        navigate('/home/configuration/problemsolution');
                        resetForm();
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error: any) {
                setButtonLoader(false)
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error?.response?.data?.message);
                } else {
                    toast.error('Error adding problem & solution.');
                }
            }
        }
    };
    return (
        <div className='w-full h-full relative'>
            <div className="w-full px-2 sticky top-0 z-30 bg-skin-fill-formheader">
                <p className='py-1 font-medium text-skin-base text-lg'>{editProblemSolution == null ? t('Addproblemsolution') : t('EditProblemSolution')}</p>
                <hr />
            </div>
            <Loader loader={isLoading} />
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='flex flex-col gap-4 p-3'>
                        <div className="grid grid-cols-1 gap-2">
                            <InputSelect name='assetType' id='assetType' label='Asset Type' Array={assettypes} />
                            <InputText name='problem' id='problem' autoFocus={true} label='Problem' />
                            <InputTextArea name='solution' label='Solution' />
                        </div>
                        <div className="w-full float-right flex gap-2 mt-11 justify-end bg-skin-fill-outlet ">
                            <CancelButton onClick={() => navigate('/home/configuration/problemsolution')} />
                            {buttonLoader ? <ButtonLoader /> : <SubmitButton label={editProblemSolution == null ? 'Add' : 'Update'} />}
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default ProblemSolutionForm;
