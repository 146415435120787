export const dataLoggingFrequencys = [
    { name: 'Hourly', _id: 1 },
    { name: '4 Hours', _id: 4 },
    { name: '8 Hours', _id: 8 },
    { name: '12 Hours', _id: 12 },
    { name: '24 Hours', _id: 24 }
]

export const applicationOfAirs = [
    { name: 'Nitrogen', _id: 'Nitrogen' },
    { name: 'Pnematic', _id: 'Pnematic' },
    { name: 'Process', _id: 'Process' }
]

export const typeOfCompressors = [
    { name: 'Oil Free', _id: 'Oil Free' },
    { name: 'Lubricated', _id: 'Lubricated' }
]

export const technologys = [
    { name: 'Screw', _id: 'Screw' },
    { name: 'Reciprocating', _id: 'Reciprocating' },
    { name: 'Centrifugal', _id: 'Centrifugal' },
    { name: 'Scroll', _id: 'Scroll' },
    { name: 'Tooth', _id: 'Tooth' },
    { name: 'Booster', _id: 'Booster' }
]

export const numberofStageses = [
    { name: 'Single Stage', _id: 'Single Stage' },
    { name: 'Two Stage', _id: 'Two Stage' },
    { name: 'Three Stage', _id: 'Three Stage' }
]

export const cooleds = [
    { name: 'Air Cooled', _id: 'Air Cooled' },
    { name: 'Water Cooled', _id: 'Water Cooled' }
]

export const capacityControls = [
    { name: 'Fixed Speed', _id: 'Fixed Speed' },
    { name: 'VSD', _id: 'VSD' }
]


