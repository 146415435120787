import { useState, type FC, Dispatch, SetStateAction, useEffect } from 'react';
import BarChart from '../../../Component/Common/Charts/BarChart';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';

interface BarGraphDataProps {
    setLoading: Dispatch<SetStateAction<boolean>>
}

const BarGraphData: FC<BarGraphDataProps> = ({ setLoading }) => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') ?? '')
    const [barGraphData1, setBarGraphData1] = useState<any>()
    const fetchCompanyLogsCountForGraph = async () => {
        try {
            const response: responseType = await http({
                url: `/monitoringLog/getMonitoringLogCountsForGraph`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                const data = await response?.data?.data;
                setBarGraphData1(data);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching monitoring log count');
            }
        }
    }
    useEffect(() => {
        if (userDetails.role.code === 'superadmin') {
            fetchCompanyLogsCountForGraph();
        }
        // eslint-disable-next-line 
    }, [])
    return (
        <div className='w-full flex flex-col items-center shadow-lg h-[440px] bg-white rounded-lg p-2 font-sans'>
            <h1 className='w-full self-start text-sm border-b'>Logs count</h1>
            <div className="w-full h-[400px] flex items-center justify-center">
                {(barGraphData1 && barGraphData1?.datasets?.length !== 0) ? <BarChart data={barGraphData1} /> : <span className='text-sm flex items-center justify-center'>No logs found.</span>}
            </div>
        </div>
    );
}

export default BarGraphData;
