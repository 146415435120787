import { useState, type FC, useEffect, useContext } from 'react';
import Card from '../../../Component/Common/Card/Card';
import { IAsset, IactionPopAction, responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import MyCarousel from '../../../Component/Common/Carousel/Carousel';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../../Context/DataProvider';
import Loader from '../../../Component/Common/Loader/Loader';
import ActionPop from '../../../Component/Common/ActionPop/ActionPop';
import DeleteModal from '../../../Component/Common/DeleteModal';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';

interface AssetProps { }

const Asset: FC<AssetProps> = () => {
    const [AssetData, setAssetData] = useState<IAsset[] | null>(null)
    const { isRender, setIsRender, isVisible } = useContext(DataContext);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const navigate = useNavigate();
    const [assetId, setAssetId] = useState<any>()
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

    const fetchAssets = async () => {
        try {
            const response: responseType = await http({
                url: `/asset/getAsset`,
                method: 'get',
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAssetData(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset');
            }
        }
    };
    const DeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }
    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/asset/deleteAsset`,
                method: 'delete',
                data: { _id: assetId }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting asset');
            }
        }
    }

    const handleEdit = () => {
        navigate(`/home/configuration/editasset/${assetId}`)
    }

    const action: IactionPopAction[] = []
    isVisible('asset-update') && action.push({ id: 1, name: 'Edit', click: handleEdit });
    isVisible('asset-delete') && action.push({ id: 2, name: 'Delete', click: DeleteModalOpen });

    useEffect(() => {
        fetchAssets();
        // eslint-disable-next-line 
    }, [isRender]);
    return (
        <div className="w-full h-full overflow-y-auto scrollbar-thin relative">
            <Loader loader={isLoading} />
            {AssetData?.length === 0
                ?
                <DataNotFoundPage />
                :
                <div className='w-full gap-2 grid grid-cols-1 lg:grid-cols-3 text-skin-base md:grid-cols-2 xl:grid-cols-4'>
                    {AssetData?.map((asset: any) => (
                        <div key={asset._id} className="h-60 border rounded-xl relative bg-skin-fill-card shadow-xl">
                            <Card title={asset?.name} body={<MyCarousel items={asset?.images} />} />
                            <span className='rounded-md bg-skin-fill-company-card-bg text-sm px-1.5 absolute bottom-3 left-3'>{asset?.assetType?.name}</span>
                            <span className=' absolute bottom-3 right-3 '> {isVisible('asset-update') || isVisible('asset-delete') ?
                                <span className="text-lg cursor-pointer" onClick={() => setAssetId(asset?._id)}>
                                    <ActionPop action={action} icon={'GrMoreVertical'} />
                                </span>
                                : null}
                            </span>
                        </div>
                    ))}</div>}
            <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='Asset' />
        </div>
    );
}

export default Asset;
