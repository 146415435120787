import type { FC } from 'react';
import GojsDiagram from '../Charts/GoJsDiagram';

interface DiaGramPopUpProps {
    openPopUp: boolean
    setOpenPopUp: React.Dispatch<React.SetStateAction<boolean>>
}

const DiaGramPopUp: FC<DiaGramPopUpProps> = ({ openPopUp, setOpenPopUp }) => {
    const childClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation(); // Prevent the event from propagating to the parent
    }
    return (
        <div onClick={() => setOpenPopUp(false)} className={`${openPopUp ? 'fixed left-0 top-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-[1000]' : 'hidden'}`}>
            <div onClick={(event) => childClick(event)} className='sm:w-[75%] sm:mx-0 mx-5 w-full h-[500px] bg-white rounded-lg px-3 pt-4 shadow-lg  max-h-[95%] overflow-y-scroll scrollbar-thin'>
                {openPopUp && <GojsDiagram />}
            </div>
        </div>
    );
}

export default DiaGramPopUp;
