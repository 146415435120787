import { useEffect, type FC, useContext, useState } from 'react';
import http from '../../../Services/http/http';
import { toast } from 'react-toastify';
import { DataContext } from '../../../Context/DataProvider';
import { IactionPopAction, responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import Loader from '../../../Component/Common/Loader/Loader';
import ActionPop from '../../../Component/Common/ActionPop/ActionPop';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../../Component/Common/DeleteModal';
import Pagination from '../../../Component/Common/Pagination';
import Counter from '../../../Component/Common/Counter';
import SearchFilter from '../../../Component/Common/SearchFilter';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';

interface AssetTypeProps { }
interface assetType {
    code: string
    created_at: Date
    created_by: string
    modified_at?: Date
    name: string
    status?: string
    _id?: string
}

const AssetType: FC<AssetTypeProps> = () => {
    const [assetType, setAssetType] = useState<assetType[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { isRender, setIsRender, selectedLimit, isVisible, dateFilter } = useContext(DataContext)
    const [Id, setId] = useState<string>();
    const navigate = useNavigate()
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [skip, setSkip] = useState(0)
    const [counts, setCounts] = useState<number>(0)
    const [searchFilter, setSearchFilter] = useState<string>('')

    const handleEdit = () => {
        navigate(`/home/configuration/editassettype/${Id}`)
    }

    const DeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }
    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/assetType/deleteAssetType`,
                method: 'delete',
                data: { '_id': Id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting asset type.');
            }
        }
    }
    const action: IactionPopAction[] = []
    isVisible('assettype-update') && action.push({ id: 1, name: 'Edit', click: handleEdit });
    isVisible('assettype-delete') && action.push({ id: 2, name: 'Delete', click: DeleteModalOpen });

    const fetchAssetType = async () => {
        try {
            let data: any = {
                search: searchFilter,
                dateFilter: dateFilter
            }
            if (searchFilter === '') {
                data.skip = skip;
                data.limit = selectedLimit;
            }
            const response: responseType = await http({
                url: `/assetType/getAssetTypes`,
                method: 'get',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setAssetType(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching asset type.');
            }
        }
    };
    const getAssetCount = async () => {
        try {
            const response: responseType = await http({
                url: `/assetType/getAssetTypeCount`,
                method: 'get',
                data: {
                    search: searchFilter,
                    dateFilter: dateFilter
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCounts(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error FetchingCount.');
            }
        }
    }
    useEffect(() => {
        getAssetCount();
        // eslint-disable-next-line 
    }, [searchFilter, isRender, dateFilter]);

    useEffect(() => {
        fetchAssetType();
        // eslint-disable-next-line 
    }, [isRender, skip, searchFilter, selectedLimit, dateFilter]);
    useEffect(() => {
        setIsLoading(true)
        // eslint-disable-next-line 
    }, [skip, selectedLimit]);
    useEffect(() => {
        setSkip(0)
        // eslint-disable-next-line 
    }, [selectedLimit]);
    return (
        <>
            <div className='h-full w-full relative'>
                <Loader loader={isLoading} />
                <div className='flex items-center justify-between sm:pb-4 pb-2 '>
                    <Counter skip={skip} counts={counts} limit={selectedLimit} />
                    <SearchFilter setSearch={setSearchFilter} />
                </div>
                {counts === 0 ?
                    <DataNotFoundPage />
                    :
                    <div className="flex sm:h-[90%] h-[92%]  flex-col w-full">
                        <div className="w-full h-auto overflow-auto scrollbar-thin">
                            <table className="w-full mb-2 text-left ">
                                <thead className="  border-l-white px-6  bg-skin-fill-table-head text-skin-base-table-head-text z-10  sticky top-0">
                                    <tr>
                                        <th scope="col" className="px-4 py-3 font-medium truncate">
                                            S.No.
                                        </th>
                                        <th scope="col" className="sticky left-0 bg-skin-fill-table-head px-4 py-3 font-medium truncate">
                                            Name
                                        </th>
                                        {isVisible('assettype-update') || isVisible('assettype-delete') ?
                                            <th scope="col" className="py-3 font-medium truncate">
                                                Actions
                                            </th>
                                            : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {assetType?.map((companyAdmin, index) =>
                                        <tr key={companyAdmin._id} className="bg-skin-fill-table hover:bg-skin-fill-hover hover:text-skin-base-table-hover hover:bg-opacity-10 text-skin-base-table-body-text border-b border-skin-table-color group">
                                            <td className="px-4 py-[8px]">
                                                {searchFilter ? (index + 1) : (skip + (index + 1))}
                                            </td>
                                            <td className="sticky left-0 group group-hover:bg-skin-fill-hover bg-skin-fill-table px-4 py-[8px] truncate">
                                                {companyAdmin?.name}
                                            </td>
                                            {isVisible('configuration-update') || isVisible('configuration-delete') ?
                                                <td className="px-4 py-[8px]" >
                                                    <span onClick={() => setId(companyAdmin?._id)}><ActionPop action={action} icon={'FiMoreVertical'} />
                                                    </span>
                                                </td>
                                                : null
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {(counts > selectedLimit && searchFilter === '') && <Pagination limit={selectedLimit} setSkip={setSkip} itemsCount={counts} />}
                    </div>
                }
                <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='Asset Type' />
            </div>
        </>
    );
}

export default AssetType;
