import { useState, type FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Counter from '../../../Component/Common/Counter';
import DeleteModal from '../../../Component/Common/DeleteModal';
import Loader from '../../../Component/Common/Loader/Loader';
import SearchFilter from '../../../Component/Common/SearchFilter';
import { responseType } from '../../../Component/Common/TypesAndInterfaces/TypesAndInterfaces';
import { DataContext } from '../../../Context/DataProvider';
import http from '../../../Services/http/http';
import Pagination from '../../../Component/Common/Pagination';
import { VscEdit } from 'react-icons/vsc'
import { RiDeleteBin6Line } from 'react-icons/ri'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { IoIosArrowForward } from 'react-icons/io';
import DataNotFoundPage from '../../../Component/Common/DataNotFoundPage';

interface ProblemSolutionProps { }

const ProblemSolution: FC<ProblemSolutionProps> = () => {
    const [problemSolutionData, setProblemSolutionData] = useState<any[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [id, setId] = useState<string>();
    const navigate = useNavigate();
    const { isRender, setIsRender, selectedLimit, isVisible } = useContext(DataContext)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [skip, setSkip] = useState(0)
    const [rangeOpen, setRangeOpen] = useState<number[]>([0]);
    const [counts, setCounts] = useState<number>(0)
    const [searchFilter, setSearchFilter] = useState<string>('')

    const handleEdit = (id: string) => {
        navigate(`/home/configuration/editproblemsolution/${id}`)
    }

    const DeleteModalOpen = (id: string) => {
        setId(id)
        setOpenDeleteModal(true)
    }
    const handleDelete = async () => {
        try {
            const response: responseType = await http({
                url: `/troubleshootingTip/deleteTroubleshootingTip`,
                method: 'delete',
                data: { '_id': id }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                toast.success(response?.data?.message)
                setIsRender(!isRender)
                setOpenDeleteModal(false)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error deleting problem & solution.');
            }
        }
    }
    const handleOpen = (index: number) => {
        if (rangeOpen.includes(index)) {
            setRangeOpen([])
        } else {
            setRangeOpen([index])
        }
    }
    const fetchProblemSolution = async () => {
        try {
            let data: any = {
                search: searchFilter
            }
            if (searchFilter === '') {
                data.skip = skip;
                data.limit = selectedLimit;
            }
            const response: responseType = await http({
                url: `/troubleshootingTip/getTroubleshootingTip`,
                method: 'get',
                data
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setProblemSolutionData(response?.data?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching problem & solution.');
            }
        }
    };

    const getProblemSolution = async () => {
        try {
            const response: responseType = await http({
                url: `/troubleshootingTip/getTroubleshootingTipCount`,
                method: 'get',
                data: {
                    search: searchFilter
                }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setCounts(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error('Error fetching count.');
            }
        }
    }
    useEffect(() => {
        getProblemSolution();
        // eslint-disable-next-line 
    }, [searchFilter, isRender]);
    useEffect(() => {
        fetchProblemSolution();
        // eslint-disable-next-line 
    }, [isRender, skip, searchFilter, selectedLimit]);
    useEffect(() => {
        setIsLoading(true);
        // eslint-disable-next-line 
    }, [skip, selectedLimit]);
    useEffect(() => {
        setSkip(0)
        // eslint-disable-next-line 
    }, [selectedLimit]);
    return (
        <div className='h-full w-full relative  rounded-2xl'>
            <Loader loader={isLoading} />
            <div className='flex items-center justify-between sm:pb-4 pb-2 '>
                <Counter skip={skip} counts={counts} limit={selectedLimit} />
                <SearchFilter setSearch={setSearchFilter} />
            </div>
            {counts === 0 ?
                <DataNotFoundPage />
                :
                <div className="flex sm:h-[90%] h-[92%]  flex-col w-full">
                    <div className='w-full h-auto overflow-auto flex flex-col gap-2 scrollbar-thin mb-2'>
                        {problemSolutionData?.map((ProblemSolution: any, index: number) => (
                            <div className='group' key={index}>
                                <Accordion open={rangeOpen.includes(index)}>
                                    <AccordionHeader onClick={() => handleOpen(index)} className='flex w-full justify-start py-2 bg-skin-fill-company-table-bg text-skin-company-mainText font-medium'>
                                        <div className='flex justify-start items-center w-[88%] sm:w-[90%]'>
                                            <IoIosArrowForward className={`transition-all duration-200 ${rangeOpen.includes(index) ? 'rotate-90' : 'rotate-0'}`} />
                                            <p className='text-sm ps-1'>{ProblemSolution?.problem} ?</p>
                                        </div>
                                        {isVisible('problemsolution-update') || isVisible('problemsolution-delete') ?
                                            <div className='sm:px-4 group-hover:block w-[12%] sm:w-[10%] hidden text-skin-company-taible-text bg-skin-fill-company-table-bg'>
                                                <div className='flex items-center justify-end gap-2 sm:gap-3'>
                                                    <span onClick={() => handleEdit(ProblemSolution?._id)} className="text-lg cursor-pointer">
                                                        <VscEdit />
                                                    </span>
                                                    <span onClick={() => DeleteModalOpen(ProblemSolution?._id)} className="text-lg cursor-pointer text-red-600">
                                                        <RiDeleteBin6Line />
                                                    </span>
                                                </div>
                                            </div>
                                            : null}
                                    </AccordionHeader>
                                    <AccordionBody className='py-1'>
                                        <div className='w-full py-2 bg-skin-fill-company-table-bg h-auto flex justify-between items-center px-4 shadow-sm'>
                                            <div className='empty' dangerouslySetInnerHTML={{ __html: ProblemSolution?.solution }} />
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            </div>
                        ))}
                    </div>
                    {(counts > selectedLimit && searchFilter === '') && <Pagination limit={selectedLimit} setSkip={setSkip} itemsCount={counts} />}
                </div>
            }
            <DeleteModal click={handleDelete} open={openDeleteModal} setOpen={setOpenDeleteModal} title='Problem & Solution' />
        </div>

    );
}

export default ProblemSolution;
