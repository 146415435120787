import { ErrorMessage, Field } from 'formik';
import { useEffect, useState, type FC } from 'react';

interface InputYeatProps {
    id: string;
    name: string;
    disabled?: boolean;
    ref?: HTMLButtonElement | null;
    autoFocus?: boolean;
    label: string;
    required?: boolean;
    isCorner?: boolean;
}

const InputYeat: FC<InputYeatProps> = ({ name, disabled = false, ref, id, autoFocus, label, required = true, isCorner = false }) => {
    const [years, setYears] = useState<number[]>([]);

    useEffect(() => {
        getYearsArray()
    }, [])

    const getYearsArray = () => {
        const yearsArray: number[] = [];
        for (let year = 1900; year <= new Date().getFullYear(); year++) {
            yearsArray.push(year);
        }
        setYears(yearsArray);
    };

    return (
        <div className="relative z-0 w-full group">
            <Field
                id={id}
                name={name}
                disabled={disabled}
                as="select"
                className={`block p-2 w-full text-sm rounded text-gray-500 bg-transparent ${isCorner ? 'rounded-l-none' : ''} border-[1.5px] border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#6D6DA4] peer`}
            >
                <option value="">Select {label}</option>
                {years?.map((year: any) => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </Field>
            <label htmlFor={id} className='peer-focus:font-medium absolute text-skin-lable peer-focus:text-gray-800 font-normal text-sm duration-300 transform -translate-y-4 scale-75 top-2 left-2 bg-skin-fill-outlet z-10 origin-[0] peer-focus:left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[18px]'>{label} {required === true ? <span className={`text-red-500`}>*</span> : null}</label>
            <span className="text-red-500 text-sm"><ErrorMessage name={name} /></span>
        </div>
    );
}

export default InputYeat;
