import { FC, useContext, useEffect, useState } from 'react';
import TopNav from '../TopNav/TopNav'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { RiDashboard3Line, RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri'
import { CgProfile } from 'react-icons/cg'
import { IoLogoPlaystation, IoMdLogOut, IoMdNotificationsOutline } from 'react-icons/io'
import { LuUser } from 'react-icons/lu'
import { DataContext } from '../../../Context/DataProvider';
import { TbDeviceImacHeart, TbRulerMeasure } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { BsArrowBarLeft, BsBuildings } from 'react-icons/bs';
import { FaBars, FaWatchmanMonitoring } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import noImage from '../../../Assets/noimageavailable.jpg'
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { MdOutlineSyncProblem } from 'react-icons/md';
import { LiaToolsSolid } from 'react-icons/lia';
import { motion } from "framer-motion";
import { SiTaichilang } from 'react-icons/si';
import { responseType } from '../TypesAndInterfaces/TypesAndInterfaces';
import http from '../../../Services/http/http';
import logo from '../../../Assets/logo.png'

interface LeftNavProps {
    sidebarWidth: boolean
    setsidebarWidth: (value: boolean) => void
}

const LeftNav: FC<LeftNavProps> = ({ sidebarWidth, setsidebarWidth }: LeftNavProps) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [logoutButton, setLogoutButton] = useState(false);
    const [ntfcsCount, setNtfcsCount] = useState(0);
    const { pathname } = useLocation();
    const { isVisible, themes, notificationUpdate } = useContext(DataContext);
    const { t } = useTranslation('title');
    const navigate = useNavigate()
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
    const [showChild, setShowChild] = useState<boolean>(false)

    const sidebarCollapse = () => {
        setsidebarWidth(!sidebarWidth)
        setLogoutButton(false)
    }
    const handleLogout = async () => {
        setLogoutButton(false)
        try {
            toast.success('User Logged Out')
            setTimeout(() => {
                sessionStorage.clear();
                navigate('/');
            }, 2000);
        } catch (error: any | unknown) {
            toast.error((error as any)?.response?.data?.message);
        }
    }
    const getNotificationCount = async () => {
        try {
            const response: responseType = await http({
                url: `/notification/getNotificationCount`,
                method: 'get',
                data: { company: userDetails?.company?._id ? userDetails?.company?._id : null }
            });
            if (response?.data?.code === 'SUCCESS_200') {
                setNtfcsCount(response?.data?.data)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error: any) {
            console.log(error)
            if (error.message) {
                toast.error(error.message);
            } else {
                toast.error('Error fetching notification count.');
            }
        }
    }
    useEffect(() => {
        if (pathname.split('/')[2] === 'configuration') {
            setShowChild(true)
        } else {
            setShowChild(false)
        }
        // eslint-disable-next-line
    }, [pathname])
    useEffect(() => {
        getNotificationCount();
        // eslint-disable-next-line
    }, [notificationUpdate])

    const handleProfileImageClick = () => {
        if (sidebarWidth === true) {
            setsidebarWidth(!sidebarWidth)
            setLogoutButton(true)
        }
    }

    const animationVariant = {
        initial: { opacity: 0, y: -20 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
    };
    return (
        <>
            <TopNav {...{ sidebarOpen, setSidebarOpen, sidebarWidth }} />
            <aside className={`${sidebarWidth === true ? 'w-14' : 'w-60 '} ${sidebarOpen === true ? 'translate-x-0' : '-translate-x-full'} fixed top-0 left-0 z-50 duration-1000 h-screen sm:translate-x-0 hidden sm:block transition-all bg-white border-r border-gray-200`} >
                <div className={`${themes} h-full overflow-y-auto bg-skin-fill scrollbar-none`}>
                    <div className="w-full h-16 flex items-center bg-skin-fill justify-center">
                        <div className={`rounded-lg px-2 fixed top-0 pt-6 h-[7%] flex justify-between  bg-skin-fill items-center z-40 w-full`}>
                            {/* large screen */}
                            <div onClick={sidebarCollapse} className="absolute text-skin-base hidden sm:flex left-2 gap-0.5 items-baseline cursor-pointer">
                                <img src={logo} alt="logo" className='w-9 min-w-9 h-9 min-h-9 rounded-full' />
                            </div>
                            {/* small screen */}
                            <div onClick={sidebarCollapse} className="absolute text-skin-base sm:hidden flex left-2 gap-0.5 items-baseline cursor-pointer">
                                <h1 className='text-xl font-semibold pl-2'><FaBars /></h1>
                            </div>
                            <p className={`${sidebarWidth ? 'pl-14' : 'pl-10'} transition-all duration-1000 truncate text-skin-base text-xl`}>{t('Utility Manager')}</p>
                            {/* large screen */}
                            <div onClick={sidebarCollapse} className={`absolute -right-[7px] top-0 hidden bg-skin-fill-navController-bg hover:bg-skin-fill-navController-bg-hover transition-all duration-1000 cursor-pointer h-16 sm:flex items-center justify-center text-skin-navController-text z-50`}>
                                <BsArrowBarLeft className={`${sidebarWidth ? 'rotate-180' : 'rotate-0'} transition-all duration-1000`} />
                            </div>
                            {/* small screen */}
                            <div onClick={() => setSidebarOpen(!sidebarOpen)} className={`-right-[7px] top-0 bg-skin-fill-navController-bg hover:bg-skin-fill-navController-bg-hover absolute sm:hidden transition-all duration-1000 text-skin-navController-text cursor-pointer h-16 bg-gray-200 flex items-center justify-center z-50`}>
                                <BsArrowBarLeft className={`${sidebarOpen === false ? 'rotate-180' : '-rotate-0'} transition-all duration-1000`} />
                            </div>
                        </div>
                    </div>
                    <div className="h-[90%] flex flex-col justify-between">
                        <ul className="pb-[60px] flex flex-col mt-4 lg:mt-10 gap-1">
                            <li className='relative px-2' title='Dashboard'>
                                <NavLink to='dashboard' className={({ isActive }) => ` ${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex items-center h-9 text-sm  font-light`}>
                                    <RiDashboard3Line className='text-[20px] absolute' /><span className={`${sidebarWidth === true ? 'pl-9' : 'pl-6'} truncate transition-all`}>{t('Dashboard')}</span>
                                </NavLink>
                            </li>
                            {isVisible('companyasset-read') &&
                                <li className='relative px-2' title='Company Assets'>
                                    <NavLink to={userDetails?.role?.code === 'superadmin' ? 'company/A' : 'companyAsset'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex items-center  h-9 text-sm  font-light`}>
                                        <TbDeviceImacHeart className='text-[20px] absolute' />
                                        <span className={`${sidebarWidth === true ? 'pl-9' : 'pl-6'} truncate transition-all`}>{userDetails?.role?.code === 'superadmin' ? t('CompanyAsset') : t('Asset')}</span>
                                    </NavLink>
                                </li>
                            }
                            {isVisible('configuration-read') &&
                                <>
                                    <li className='relative px-2' title='Configuration'>
                                        <NavLink to='configuration' className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex items-center  h-9 text-sm  font-light`}>
                                            <FiSettings className='text-[20px] absolute' />
                                            <span className={`${sidebarWidth === true ? 'pl-9' : 'pl-6'} truncate transition-all`}>{t('Configuration')}</span>
                                        </NavLink>
                                    </li>
                                    {showChild &&
                                        <motion.ul
                                            variants={animationVariant}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            className='flex flex-col gap-1 ps-5'>
                                            {isVisible('role-read') &&
                                                <motion.li
                                                    title='Roles'
                                                    variants={animationVariant}
                                                    className='relative px-2' >
                                                    <NavLink to={'/home/configuration/rolesTable'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex gap-1 items-center  h-9 text-sm  font-light `}>
                                                        <AiOutlineUsergroupAdd className='text-lg' />
                                                        <span className={`truncate transition-all`}>{t('Roles')}</span>
                                                    </NavLink>
                                                </motion.li>}
                                            {isVisible('company-read') &&
                                                <motion.li
                                                    title='Company'
                                                    variants={animationVariant}
                                                    className='relative px-2' >
                                                    <NavLink to={'/home/configuration/company'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex gap-1 items-center  h-9 text-sm  font-light `}>
                                                        <BsBuildings className='text-lg' />
                                                        <span className={`truncate transition-all`}>{t('Company')}</span>
                                                    </NavLink>
                                                </motion.li>}
                                            {isVisible('assettype-read') &&
                                                <motion.li
                                                    title='Asset Type'
                                                    variants={animationVariant}
                                                    className='relative px-2' >
                                                    <NavLink to={'/home/configuration/assettype'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex gap-1 items-center  h-9 text-sm  font-light `}>
                                                        <LiaToolsSolid className='text-2xl' />
                                                        <span className={`truncate transition-all`}>{t('Asset Type')}</span>
                                                    </NavLink>
                                                </motion.li>}
                                            {isVisible('monitoringtype-read') &&
                                                <motion.li
                                                    title='Monitoring Type'
                                                    variants={animationVariant}
                                                    className='relative px-2' >
                                                    <NavLink to={'/home/configuration/monitoringtype'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex gap-1 items-center  h-9 text-sm  font-light `}>
                                                        <FaWatchmanMonitoring className='text-2xl' />
                                                        <span className={`truncate transition-all`}>{t('Monitoring Type')}</span>
                                                    </NavLink>
                                                </motion.li>}
                                            {isVisible('unit-read') &&
                                                <motion.li
                                                    title='Unit'
                                                    variants={animationVariant}
                                                    className='relative px-2' >
                                                    <NavLink to={'/home/configuration/unit'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex gap-1 items-center  h-9 text-sm  font-light `}>
                                                        <TbRulerMeasure className='text-2xl' />
                                                        <span className={`truncate transition-all`}>{t('Unit')}</span>
                                                    </NavLink>
                                                </motion.li>}
                                            {isVisible('country-read') &&
                                                <motion.li
                                                    title='Country'
                                                    variants={animationVariant}
                                                    className='relative px-2' >
                                                    <NavLink to={'/home/configuration/country'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex gap-1 items-center  h-9 text-sm  font-light `}>
                                                        <SiTaichilang className='text-2xl' />
                                                        <span className={`truncate transition-all`}>{t('Country')}</span>
                                                    </NavLink>
                                                </motion.li>}
                                            {isVisible('state-read') &&
                                                <motion.li
                                                    title='State'
                                                    variants={animationVariant}
                                                    className='relative px-2' >
                                                    <NavLink to={'/home/configuration/state'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex gap-1 items-center  h-9 text-sm  font-light `}>
                                                        <IoLogoPlaystation className='text-2xl' />
                                                        <span className={`truncate transition-all`}>{t('State')}</span>
                                                    </NavLink>
                                                </motion.li>}
                                            {isVisible('problemsolution-read') &&
                                                <motion.li
                                                    title='Troubleshooting'
                                                    variants={animationVariant}
                                                    className='relative px-2' >
                                                    <NavLink to={'/home/configuration/problemsolution'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex gap-1 items-center  h-9 text-sm  font-light `}>
                                                        <MdOutlineSyncProblem className='text-2xl' />
                                                        <span className={`truncate transition-all`}>{t('Troubleshooting')}</span>
                                                    </NavLink>
                                                </motion.li>}
                                        </motion.ul>}
                                </>
                            }
                            <li className='relative px-2' title='Notifications'>
                                <NavLink to='notifications' className={({ isActive }) => ` ${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex items-center h-9 text-sm  font-light`}>
                                    <IoMdNotificationsOutline className='text-[20px] absolute' /><span className={`${sidebarWidth === true ? 'pl-9' : 'pl-6'} truncate transition-all`}>{t('Notifications')}</span>
                                    <span className={`${sidebarWidth === true ? 'w-4 h-4 right-[13px] top-1 text-xs' : 'w-5 h-5 right-4 text-xs'} transition-all duration-1000 absolute bg-red-600  rounded-full flex items-center justify-center text-white`}>{ntfcsCount}</span>
                                </NavLink>
                            </li>
                            {isVisible('user-read') &&
                                <li className='relative px-2' title='Users'>
                                    <NavLink to={userDetails?.role?.code === 'superadmin' ? 'company/U' : 'user'} className={({ isActive }) => `${isActive ? 'text-skin-active-nav bg-skin-fill-nav-bg' : 'hover:text-skin-base-nav-text-hover  text-skin-base-nav-text hover:bg-skin-fill-nav-hover'} rounded-lg px-2 flex items-center h-9 text-sm  font-light`}>
                                        <LuUser className='text-[20px] absolute' />
                                        <span className={`${sidebarWidth === true ? 'pl-9' : 'pl-6'} truncate transition-all`}>{t('User')}</span>
                                    </NavLink>
                                </li>
                            }
                        </ul>
                        <div className={`${logoutButton === true ? 'bottom-0 pb-8' : 'translate-y-[62px] bottom-0'} text-skin-base duration-500 w-full bg-skin-fill fixed px-2 transition-all`}>
                            <div className={`${sidebarWidth === true ? 'w-10' : 'w-[220px]'} border-t transition-all duration-1000`} />
                            <div onClick={() => setLogoutButton(!logoutButton)} className={`flex h-[50px] cursor-pointer items-center relative py-1`}>
                                <div className="relative">
                                    {userDetails?.profileImage !== null ?
                                        <img onClick={handleProfileImageClick} src={userDetails.profileImage?.url ?? noImage} alt="mImg" className={`w-9 h-9 min-w-[36px] min-h-[36px] rounded-full`} />
                                        :
                                        <div onClick={handleProfileImageClick} className={`w-9 h-9 min-w-[36px] min-h-[36px] rounded-full font-semibold text-xl flex items-center justify-center bg-skin-fill-profile-icon border`}>{userDetails?.name?.charAt(0)?.toUpperCase()}</div>
                                    }
                                </div>
                                <div className={`${sidebarWidth === true ? 'ml-6' : 'pl-2'} truncate transition-all flex items-center justify-between w-[80%]`}>
                                    <div className="w-[55%]">
                                        <h1 className='text-sm truncate font-medium'>{userDetails?.name}</h1>
                                        <p className='text-[10px] font-medium'>{userDetails?.role?.name?.charAt(0)?.toUpperCase() + userDetails?.role?.name?.slice(1)}</p>
                                    </div>
                                    <div className="flex items-center justify-center gap-2">
                                        <RiArrowDownSLine className='text-[20px] cursor-pointer' />
                                    </div>
                                </div>
                            </div>
                            <div className={`${logoutButton === true ? 'mt-2' : 'mt-10'} w-full transition-all py-1 duration-1000 gap-1 flex flex-col items-center`}>
                                <div onClick={() => navigate('editprofile')} className='w-full h-6 cursor-pointer bg-skin-fill-profile drop-shadow-md border flex items-center pl-2 justify-between rounded-sm'>
                                    <div className="flex items-center gap-1 text-sm">
                                        <CgProfile />
                                        <span>{t('Edit Profile')}</span>
                                    </div>
                                    <RiArrowRightSLine className='text-[20px] cursor-pointer' />
                                </div>
                                <div onClick={handleLogout} className='w-full h-6 cursor-pointer bg-skin-fill-profile drop-shadow-md border flex items-center px-2 rounded-sm'>
                                    <div className="flex items-center gap-1 text-sm">
                                        <IoMdLogOut />
                                        {t('Log Out')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside >
        </>
    )
}

export default LeftNav;
