import type { FC } from 'react';

interface LoaderProps {
    loader: boolean
}

const Loader: FC<LoaderProps> = ({ loader }) => {
    return (
        <>
            {loader === true && <div className="w-full h-full flex items-center justify-center bg-skin-fill-loader-bg inset-0 fixed top-16 sm:top-0 sm:absolute z-50">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-skin-loader-border" />
            </div>}
        </>
    )
}

export default Loader;
