import type { FC } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface AreaChartProps { data: any }

const AreaChart: FC<AreaChartProps> = ({ data }) => {
    const options = {
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
        },
    };
    return (
        <Line options={options} data={data} />
    );
}

export default AreaChart;